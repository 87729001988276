export enum UserActionTypes {
  FETCH_USERS = 'FETCH_USERS',
  FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS',
  FETCH_USERS_ERROR = 'FETCH_USERS_ERROR',
  SELECT_LANGUAGE = 'SELECT_LANGUAGE',
  SET_CITY = 'SET_CITY',
  SET_LOGIN = 'SET_LOGIN',
  GAZDA_HOURS = 'GAZDA_HOURS',
  GAZDA_PREV_ORDER = 'GAZDA_PREV_ORDER',
  SET_ORDER_FILTER = 'SET_ORDER_FILTER',
}
export interface IUserState {
  users: any;
  filter: {
    price: string;
    diapason: string;
    orderBy: string;
    sorts: string;
    page: string;
    category: string;
    city: string;
    search: string;
    allStr: string;
  };
  user: {
    city: string;
    name: string;
    login: string;
  };
  loading: boolean;
  order: {
    gazdaHours: number;
    prevOrder: {
      isMaster: boolean;
      order: {
        category: string | number;
        service: string | number;
        section: string | number;
      };
      secondStep: [];
      secondStepClient: [];
    };
  };
  error: null | string;
}

export interface ISelect {
  value: string;
  label: string;
}
export interface IFetchUsersAction {
  type: UserActionTypes.FETCH_USERS;
}

export interface IFetchUsersSuccessAction {
  type: UserActionTypes.FETCH_USERS_SUCCESS;
  payload: any[];
}

export interface IFetchUsersErrorAction {
  type: UserActionTypes.FETCH_USERS_ERROR;
  payload: string;
}

export interface IUserAction {
  type: string;
  payload?: any;
}

export interface ISelectLanguage {
  type: UserActionTypes.SELECT_LANGUAGE;
  payload: string;
}
export interface ISetCity {
  type: UserActionTypes.SET_CITY;
  payload: string;
}
export interface ISetLogin {
  type: UserActionTypes.SET_LOGIN;
  payload: string;
}
export interface IGazdaHours {
  type: UserActionTypes.GAZDA_HOURS;
  payload: number;
}
export interface IGazdaPrevOrder {
  type: UserActionTypes.GAZDA_PREV_ORDER;
  payload: any;
}
export interface ISetOrderFilter {
  type: UserActionTypes.SET_ORDER_FILTER;
  payload: { key: string; str: string };
}

export type UserAction =
  | IFetchUsersAction
  | IFetchUsersSuccessAction
  | IFetchUsersErrorAction
  | ISelectLanguage
  | ISetCity
  | ISetLogin
  | IGazdaHours
  | IGazdaPrevOrder
  | ISetOrderFilter;
