import React, { useState, useEffect } from 'react';
import './FooterStyle.scss';
import Logo from '../../assets/imgs/logo_footer.svg';
import { iconsImg } from '../../assets/imgs/icons';
import FooterLinksMobile from './footerLinksMobile/FooterLinksMobile';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setVisible } from '../../helpers';

const Footer = () => {
  const { t } = useTranslation();

  const [visibleUrl, setVisibleUrl] = useState('');
  const location = useLocation();

  useEffect(() => {
    setVisible(location.pathname, setVisibleUrl);
  }, [location.pathname]);

  if (visibleUrl === 'main') {
    return (
      <footer>
        <div className="main_footer">
          <div className="wrapperLogo">
            <img src={Logo} alt="logo" />
          </div>
          <FooterLinksMobile />
          <div id="desk" className="col">
            <p>{t('footer.our_services')}</p>
            <Link to="/gazda">{t('gazda')}</Link>
            <Link to="/engineering">{t('engineering')}</Link>
            <Link to="/cleaning">{t('cleaning')}</Link>
            <Link to="/landscaping">{t('landscaping')}</Link>
            <Link to="/houses">{t('houses')}</Link>
            {/* <Link to="/actions">{t('actions')}</Link> */}
          </div>
          <div id="desk" className="col desk">
            <p>{t('footer.about')}</p>
            <Link to="/about">{t('footer.about_the_company')}</Link>
            {/* <Link to="/vacancies">{t('footer.vacancies')}</Link> */}
            <Link to="/service-pay">{t('footer.payment')}</Link>
            {/* <Link to="">{t('footer.invitation_to_tender')}</Link> */}
          </div>
          <div id="desk" className="col desk">
            <p>{t('footer.contacts')}</p>
            <label>+380 73 9 836 837</label>
            <label>+380 96 9 836 837</label>
            <label>Stephub.ua@gmail.com</label>
            <label>місто Київ, вул.Сімʼї Хохлових,будинок 8, 04119</label>
          </div>
          <div className="info_mobile">
            <div className="col">
              <p>{t('footer.our_services')}</p>
              <Link to="/gazda">{t('gazda')}</Link>
              <Link to="/engineering">{t('engineering')}</Link>
              <Link to="/cleaning">{t('cleaning')}</Link>
              <Link to="/landscaping">{t('landscaping')}</Link>
              <Link to="/houses">{t('houses')}</Link>
              {/* <Link to="/actions">{t('actions')}</Link> */}
            </div>
            <div className="col">
              <p>{t('footer.about')}</p>
              <Link to="/about">{t('footer.about_the_company')}</Link>
              {/* <Link to="">{t('footer.vacancies')}</Link> */}
              <Link to="/terms">{t('footer.payment')}</Link>
              <Link to="">{t('footer.invitation_to_tender')}</Link>
            </div>
            <div className="col">
              <p>Контакти</p>
              <label className="test">+380 73 9 836 837</label>
              <label>+380 96 9 836 837</label>
              <label>Stephub.ua@gmail.com</label>
              <label>місто Київ, вул.Сімʼї Хохлових,будинок 8, 04119</label>
            </div>
          </div>
          <div className="box_img_mobile">
            <Link to="">
              <img src={iconsImg.Facebook} alt="Facebook" />
            </Link>
            <Link to="">
              <img src={iconsImg.Instagram} alt="Instagram" />
            </Link>
            <Link to="">
              <img src={iconsImg.Telegram} alt="Telegram" />
            </Link>
            <Link to="">
              <img src={iconsImg.Whatsapp} alt="Whatsapp" />
            </Link>
          </div>
        </div>
        <div className="sub_footer">
          <div className="separation" />
          <div className="box_text">
            <label className="dots">©Step - 2022</label>
            <a href="/terms">
              <label className="dots">{t('footer.license_agreement')}</label>
            </a>
            <a href="/privacy-policy">
              <label>{t('footer.legal_documents')}</label>
            </a>
          </div>
          <div className="box_img">
            <Link to="">
              <img src={iconsImg.Facebook} alt="Facebook" />
            </Link>
            <Link to="">
              {' '}
              <img src={iconsImg.Instagram} alt="Instagram" />
            </Link>
            <Link to="">
              {' '}
              <img src={iconsImg.Telegram} alt="Telegram" />
            </Link>
            <Link to="">
              {' '}
              <img src={iconsImg.Whatsapp} alt="Whatsapp" />
            </Link>
          </div>
        </div>
      </footer>
    );
  } else {
    return <></>;
  }
};

export default Footer;
