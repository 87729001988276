import React, { useState } from 'react';
import './MainComponentStyle.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BreadСrumbs from '../../breadСrumbs/BreadСrumbs';
import { gazdaImgs } from '../../../assets/imgs/gazda';

interface IProps {
  price: number;
  openModal: any;
}

const MainComponent = ({ price, openModal }: IProps) => {
  const { t } = useTranslation();

  const arrayLinks = [
    { name: t('main'), link: '/' },
    { name: t('gazda'), link: '/gazda' },
  ];

  return (
    <div className="gazda_main_component">
      <BreadСrumbs arrayLinks={arrayLinks} />
      <div className="container_info">
        <div className="info">
          <h3> {t('gazda')}</h3>
          <span>{t('gazda_page.main_component.sub_title')}</span>
          <p>
            {t('order_price')} {price}
            {t('gazda_page.main_component.price.sub_2')}
          </p>
          <button onClick={openModal}>{t('order')}</button>
        </div>
      </div>
      {/* <div className="box_img"> */}
      {window.innerWidth > 1024 && <img id="gazdaMainImg" src={gazdaImgs.Main} alt="main" />}
      {/* </div> */}
    </div>
  );
};

export default MainComponent;
