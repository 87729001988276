import React, { useEffect } from 'react';
import Slider from 'react-slick';
import { sliderImg } from '../../../assets/imgs/slider';
import './MainSlider.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { sliderMobileImg } from '../../../assets/imgs/slider/main_mobile_slider';

interface ISlid {
  link: string;
  text: {
    key: string;
    title: string;
    subTitle: string;
  };
  img: string;
}

const MainSlider = () => {
  const { t } = useTranslation();

  function SampleNextArrow(props: any) {
    const { onClick } = props;
    return (
      <div
        id="nextArrow"
        onClick={() => {
          onClick();
        }}
      >
        <img src={sliderImg.ArrowRight} alt="next" />
      </div>
    );
  }
  function SamplePrevArrow(props: any) {
    const { onClick } = props;
    return (
      <div
        id="prevArrow"
        onClick={() => {
          onClick();
        }}
      >
        <img src={sliderImg.ArrowLeft} alt="prev" />
      </div>
    );
  }
  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const slids: ISlid[] = [
    {
      link: 'gazda',
      text: {
        key: t('maim_page.slider.gazda.key'),
        title: t('maim_page.slider.gazda.title'),
        subTitle: t('maim_page.slider.gazda.sub_title'),
      },
      img: window.innerWidth > 768 ? sliderImg.Gazda : sliderMobileImg.Gazda,
    },
    {
      link: 'engineering',
      text: {
        key: t('maim_page.slider.engineering.key'),
        title: t('maim_page.slider.engineering.title'),
        subTitle: t('maim_page.slider.engineering.sub_title'),
      },
      img: window.innerWidth > 768 ? sliderImg.Engineering : sliderMobileImg.Engineering,
    },
    {
      link: 'cleaning',
      text: {
        key: t('maim_page.slider.cleaning.key'),
        title: t('maim_page.slider.cleaning.title'),
        subTitle: t('maim_page.slider.cleaning.sub_title'),
      },
      img: window.innerWidth > 768 ? sliderImg.Cleaning : sliderMobileImg.Cleaning,
    },
    {
      link: 'houses',
      text: {
        key: t('maim_page.slider.houses.key'),
        title: t('maim_page.slider.houses.title'),
        subTitle: t('maim_page.slider.houses.sub_title'),
      },
      img: window.innerWidth > 768 ? sliderImg.Houses : sliderMobileImg.Houses,
    },

    {
      link: 'landscaping',
      text: {
        key: t('maim_page.slider.landscaping.key'),
        title: t('maim_page.slider.landscaping.title'),
        subTitle: t('maim_page.slider.landscaping.sub_title'),
      },
      img: window.innerWidth > 768 ? sliderImg.Landscaping : sliderMobileImg.Landscaping,
    },
  ];
  function getSlid(item: ISlid) {
    return (
      <div className={`box_slid ${item.link}`}>
        <div className={`info_slid `}>
          <Link to={`/${item.link}`}>{item.text.key}</Link>
          <h3>{item.text.title}</h3>
          <p>{item.text.subTitle}</p>
          <Link to={`/${item.link}`} className="bt">
            <button>{t('order')}</button>
          </Link>
        </div>
        <img src={item.img} alt={item.text.key} />
      </div>
    );
  }

  return (
    <div className="box_slider">
      <div className="wrapper_slider">
        <Slider {...settings}>
          {slids.map((item) => {
            return <div key={item.text.key}> {getSlid(item)}</div>;
          })}
        </Slider>
      </div>
    </div>
  );
};

export default MainSlider;
