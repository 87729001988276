import React from 'react';
import { Link } from 'react-router-dom';
import BreadСrumbs from '../../components/breadСrumbs/BreadСrumbs';
import { useTranslation } from 'react-i18next';
import { engineeringImgs } from '../../assets/imgs/engineering';
import { Ilinks } from '../../types';
import { iconsImg } from '../../assets/imgs/icons';
import './EngineeringStyle.scss';

const Engineering = () => {
  const { t } = useTranslation();

  const arrayLinks = [
    { name: t('main'), link: '/' },
    { name: t('engineering'), link: '/engineering' },
  ];

  const links: Ilinks[] = [
    {
      url: 'plumbing',
      img: engineeringImgs.Plumbing,
      text: t('engineering_page.plumbing'),
    },
    {
      url: 'electricity',
      img: engineeringImgs.Electricity,
      text: t('engineering_page.electricity'),
    },
    {
      url: 'repair',
      img: engineeringImgs.Repair,
      text: t('engineering_page.repair'),
    },
    {
      url: 'furniture',
      img: engineeringImgs.Furniture,
      text: t('engineering_page.furniture'),
    },
    {
      url: 'ventilation',
      img: engineeringImgs.Ventilation,
      text: t('engineering_page.ventilation'),
    },
  ];

  function getLink({ img, text, url }: Ilinks, index: number) {
    return (
      <Link key={`${url}_${index}`} to={url} className="wrapper_link">
        <img className="main_img" src={img} alt={url} />
        <img id="arrowLink" src={iconsImg.Link} alt="link" />
        <p>{text}</p>
      </Link>
    );
  }
  return (
    <div className="engineering_container pd_l_r">
      <BreadСrumbs arrayLinks={arrayLinks} isBlack={true} />
      <p className="title">{t('engineering')}</p>
      <div className="box_links">
        {links.map((item, index) => {
          return getLink(item, index);
        })}
      </div>
    </div>
  );
};

export default Engineering;
