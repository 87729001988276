import Basket from './basket.png';
import Search from './search.svg';
import Lang from './lang.svg';
import ArrowUp from './arrow_up.png';
import ArrowDown from './arrow_down.png';
import ArrowUpSvg from './arrow_up.svg';
import ArrowDownHeaderSvg from './arrow_down_header.svg';
import ArrowDownSvg from './arrow_down.svg';
import Menu from './menu.svg';
import CloseGold from './close_gold.svg';
import Close from './close.svg';
import User from './user.svg';
import Location from './location.png';
import Facebook from './facebook.svg';
import Whatsapp from './whatsapp.svg';
import Instagram from './instagram.svg';
import Telegram from './telegram.svg';
import ArrowFooter from './arrow_footer.svg';
import Link from './link.png';
import ArrowLink from './arrow_link.png';
import LangSelect from './select_lang.png';
import Check from './check.png';
import ShowPassword from './show_password.svg';
import NotShowPassword from './not_show_password.svg';
import Error from './error.svg';
import ArrowLeftBlack from './arrow_left_black.svg';
import CheckResetPassword from './check_reset_password.svg';
import UserRadioSelect from './user_radio_select.svg';
import UserRadio from './user_radio.svg';
import SettingRadio from './setting_radio.svg';
import SettingRadioSelect from './setting_radio_select.svg';
import Radio from './radio.svg';
import RadioSelect from './radio_select.svg';
import BreadArrow from './bread_arrow.svg';
import Phone from './phone.svg';
import File from './file_upload.svg';
import BasketSvg from './basket.svg';
import BreadArrowBlack from './bread_arrow_balack.svg';
import Avatar from './avatar.jpg';
import Calendar from './calendar.svg';
import LocationGreen from './location_green.svg';
import BadSmail from './bad_smail.svg';
import Clock from './clock.svg';
import Urgent from './urgent.svg';
import Id from './id.svg';
import LocationGray from './location_gray.svg';
import UserGray from './user_gray.svg';
import Card from './card.svg';
import Info from './info.svg';
import ArrowWhiteLong from './arrow_white_long.svg';
import Plus from './plus.svg';
import Filter from './filter.svg';
import ArrowLeftLong from './arrow_left_long.svg';

export const iconsImg = {
  CloseGold,
  ArrowLeftLong,
  Filter,
  Plus,
  ArrowWhiteLong,
  Info,
  Card,
  UserGray,
  LocationGray,
  Id,
  Urgent,
  Clock,
  BadSmail,
  LocationGreen,
  Calendar,
  Avatar,
  File,
  BasketSvg,
  Radio,
  RadioSelect,
  UserRadio,
  UserRadioSelect,
  SettingRadio,
  SettingRadioSelect,
  Basket,
  Search,
  Lang,
  ArrowUp,
  ArrowDown,
  Menu,
  Close,
  User,
  Location,
  Facebook,
  Whatsapp,
  Instagram,
  Telegram,
  ArrowFooter,
  Link,
  ArrowLink,
  LangSelect,
  Check,
  ArrowDownSvg,
  ArrowUpSvg,
  ArrowDownHeaderSvg,
  ShowPassword,
  Error,
  ArrowLeftBlack,
  CheckResetPassword,
  BreadArrow,
  BreadArrowBlack,
  Phone,
  NotShowPassword,
};
