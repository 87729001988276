import React, { useState } from 'react';
import CustomModal from '../customModal/CustomModal';
import { useTranslation } from 'react-i18next';
import { iconsImg } from '../../../assets/imgs/icons';
import { IOrderAcount } from '../../../types';
import ViewImg from '../../customs/viewImg/ViewImg';
import { getDateTime } from '../../../helpers';
import ButtomCustom from '../../customs/buttomCustom/ButtonCustom';
import { orderCancellations } from '../../../api';

interface IProps {
  handleModal: any;
  isOpen: boolean;
  card: IOrderAcount | undefined;
  ubdateList: any;
}
const CancellationsOrderModal = ({ handleModal, isOpen, card, ubdateList }: IProps) => {
  const [isFinished, setFinished] = useState(false);
  const [erroReques, setErroReques] = useState('');
  const { t } = useTranslation();
  return (
    <CustomModal
      handleModal={() => {
        handleModal();
        setErroReques('');
      }}
      isOpen={isOpen}
    >
      <div className="modal_personal_account">
        <div
          className="bread"
          onClick={() => {
            handleModal();
            setFinished(false);
            setErroReques('');
          }}
        >
          <img src={iconsImg.ArrowFooter} alt="ArrowLeftBlack" /> <span>{t('back')}</span>
        </div>
        {!isFinished ? (
          <div className="form">
            <p>
              {' '}
              {card?.orderServices.map((item: any, index) => {
                return (
                  <span key={`${item.title}_${index}`}>
                    {item.title}
                    <br />
                  </span>
                );
              })}
            </p>
            <div className="in_progress">
              <img src={iconsImg.Clock} alt="clock" />
              Замовлено
              {getDateTime(card?.inProgress)}
            </div>
            <div className="info">
              <div className="sub_info">
                <img src={iconsImg.Id} alt="Id" />
                <span>
                  {t('gazda_page.info_component.order.title')}
                  {` №`}
                  {card?.numberOrder}
                </span>
              </div>
              <div className="separator" />
              <div className="sub_info">
                <img src={iconsImg.Calendar} alt="Calendar" />
                {card?.start && (
                  <span>
                    {t('personal_account_page.modal.start')} -{getDateTime(card?.start)}
                  </span>
                )}
              </div>
              <div className="separator" />
              <div className="sub_info as">
                <img src={iconsImg.LocationGray} alt="Location" />
                <>
                  <span>
                    <b>{card?.address.city}</b>
                    <br />
                    <span className="c_gray">
                      {card?.address.street}
                      <br />
                      {card?.address.hous}
                    </span>
                  </span>
                </>
              </div>
              <div className="separator" />
              <div className="sub_info">
                <img src={iconsImg.UserGray} alt="User" />
                <>
                  <span>
                    {card?.client.fullName}
                    <br />
                    <span className="c_gray">{card?.client.phone}</span>
                  </span>
                </>
              </div>
              <div className="separator" />
              <div className="sub_info">
                <img src={iconsImg.Card} alt="Card" />
                <div className="price">
                  <span>{t('price')}</span>
                  <span>
                    {card?.price}
                    {t('gazda_page.main_component.price.sub_2')}
                  </span>
                </div>
              </div>
              <ButtomCustom
                dirty={true}
                isValid={true}
                text="Відмінити замовлення"
                onClick={async () => {
                  try {
                    await orderCancellations(card!.numberOrder).then(() => {
                      ubdateList();
                      setFinished(!isFinished);
                    });
                  } catch (e: any) {
                    setErroReques(e.message);
                  }
                }}
              />
              {erroReques && <span className="error">{erroReques}</span>}
            </div>
          </div>
        ) : (
          <div className="finished">
            <img src={iconsImg.CheckResetPassword} alt="Check" />
            <p>{t('personal_account_page.modal.finished')}</p>
            <button
              onClick={() => {
                handleModal();
                setErroReques('');
                setFinished(false);
              }}
            >
              {t('personal_account_page.modal.back')}
            </button>
          </div>
        )}
      </div>
    </CustomModal>
  );
};

export default CancellationsOrderModal;
