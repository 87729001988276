import React, { useState, useEffect } from 'react';
import './HeaderStyle.scss';
import { iconsImg } from '../../assets/imgs/icons';
import Logo from '../../assets/imgs/logo.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SelectCityModal from '../modals/selectCity/SelectCityModal';
import { useTypedSelector } from '../../hooks/useTypedSelector';

interface iSelectHeader {
  link: string;
  name: string;
}

interface IDataSelect {
  title: string;
  isShow: boolean;
  handleShow: any;
  links: iSelectHeader[];
}
interface IProps {
  changeLanguage: any;
}

const HeaderDesktop = ({ changeLanguage }: IProps) => {
  const [isEngineering, setEngineering] = useState(false);
  const [isCleaning, setCleaning] = useState(false);
  const [isLandscaping, setLandscaping] = useState(false);
  const [isHouses, setHouses] = useState(false);
  const [isLangModal, setLangModal] = useState(false);
  const [isSelectModalCity, setSelectModalCity] = useState(false);
  const [lang, setLang] = useState<string>('');
  const [isSign, setSign] = useState<boolean>(false);
  const [openTab, setTab] = useState<string>('');
  const { t } = useTranslation();
  // redux
  const { user } = useTypedSelector((state) => state.user);

  useEffect(() => {
    setLang(localStorage.getItem('lang')!);
  });
  const arrayDataSelects: IDataSelect[] = [
    // {
    //   isShow: isEngineering,
    //   handleShow: setEngineering,
    //   title: t('engineering'),
    //   links: [
    //     { link: '/plumbing', name: t('header.plumbing_equipment') },
    //     { link: '/electricity', name: t('header.electricity') },
    //     { link: '/repair', name: t('header.Repair') },
    //   ],
    // },
    {
      isShow: isCleaning,
      handleShow: setCleaning,
      title: t('cleaning'),
      links: [
        { link: '/apartments', name: t('header.apartments') },
        { link: '/offices', name: t('header.offices') },
        { link: '/after_repair', name: t('header.after_repair') },
      ],
    },
    // {
    //   isShow: isLandscaping,
    //   handleShow: setLandscaping,
    //   title: t('landscaping'),
    //   links: [
    //     { link: '/landscaping', name: t('header.landscaping') },
    //     { link: '/lawn', name: t('header.lawn') },
    //     { link: '/flowers', name: t('header.flowers') },
    //     { link: '/facade', name: t('header.facade') },
    //   ],
    // },
    // {
    //   isShow: isHouses,
    //   handleShow: setHouses,
    //   title: t('houses'),
    //   links: [
    //     { link: '/rooms', name: t('header.rooms') },
    //     { link: '/territory', name: t('header.territory') },
    //   ],
    // },
  ];
  function closeAll(title: string) {
    switch (title) {
      case 'Інженерія': {
        setCleaning(false);
        setLandscaping(false);
        setHouses(false);
        break;
      }
      case 'Клінінг': {
        setEngineering(false);
        setLandscaping(false);
        setHouses(false);
        break;
      }
      case 'Озеленення': {
        setEngineering(false);
        setCleaning(false);
        setHouses(false);
        break;
      }
      case 'Будинки': {
        setEngineering(false);
        setCleaning(false);
        setLandscaping(false);
        break;
      }
    }
  }
  function getHandleLinks(isShow: boolean, onClick: any, links: iSelectHeader[], title: string) {
    return (
      <div
        className="title"
        onClick={() => {
          closeAll(title);
          onClick(!isShow);
        }}
        key={title}
      >
        <p className={isShow ? 'selectColor' : ''}>{title}</p>{' '}
        <img src={!isShow ? iconsImg.ArrowUp : iconsImg.ArrowDown} alt="arrow" />
        {isShow && (
          <>
            {isShow && (
              <div
                className="modal_select"
                onClick={() => {
                  closeAll(title);
                  onClick(!isShow);
                }}
              />
            )}
            <div className="subLinks">
              {links.map((item) => {
                return (
                  <Link to={item.link} key={`${item.link}`}>
                    {item.name}
                  </Link>
                );
              })}
            </div>
          </>
        )}
      </div>
    );
  }

  function signIn() {
    return (
      <div className="sign_in">
        {!user.login && (
          <Link to={'/authorization'}>
            {' '}
            <button> {t('sign_in')}</button>
          </Link>
        )}
        {user.login && (
          <Link to={'/personal-account'}>
            <img src={iconsImg.User} alt="sign" />
          </Link>
        )}
        {openTab === 'sign' && (
          <div className="subLinksSign">
            <Link
              to="/authorization"
              onClick={() => {
                setTab('');
              }}
            >
              {t('authorization')}
            </Link>
            <Link
              to="/authorization"
              onClick={() => {
                setTab('');
              }}
            >
              {t('autorization2')}
            </Link>
            <Link
              to="/authorization"
              onClick={() => {
                setTab('');
              }}
            >
              {t('autorization3')}
            </Link>
          </div>
        )}
      </div>
    );
  }
  return (
    <>
      <header className="header">
        <Link to="/">
          <img src={Logo} alt="logo" />
        </Link>
        <div className="wrapperLinks">
          {/* {arrayDataSelects.map((item) => {
            return getHandleLinks(item.isShow, item.handleShow, item.links, item.title);
          })} */}
          {/* <Link className="monoLink" to="/gazda">
            {t('gazda')}
          </Link> */}
          {/* <Link className="monoLink" to="/actions">
            {t('actions')}
          </Link> */}
        </div>
        <div className="infoHeader">
          <label
            onClick={() => {
              setSelectModalCity(!isSelectModalCity);
            }}
          >
            {user.city ? user.city : ''}
          </label>
          {/* <div className="lang">
            <img
              src={iconsImg.Lang}
              alt="Lang"
              onClick={() => {
                if (openTab === 'lang') return setTab('');
                setTab('lang');
                // setLangModal(!isLangModal);
              }}
            />
            {openTab === 'lang' && (
              <div
                className="selectLang"
                onClick={() => {
                  setLangModal(!isLangModal);
                }}
              >
                <div
                  className="box_selecet_lang"
                  onClick={() => {
                    setLang('ua');
                    localStorage.setItem('lang', 'ua');
                    changeLanguage('ua');
                    setTab('');
                  }}
                >
                  <img
                    style={{ visibility: lang === 'ua' ? 'visible' : 'hidden' }}
                    src={iconsImg.Check}
                    alt="Check"
                  />
                  <p style={{ color: lang !== 'ua' ? '#333333' : '#9F9978' }}>
                    {t('header.lang.ua')}
                  </p>
                </div>
                <div
                  className="box_selecet_lang"
                  onClick={() => {
                    setLang('en');
                    localStorage.setItem('lang', 'en');
                    changeLanguage('en');
                    setTab('');
                  }}
                >
                  <img
                    style={{ visibility: lang === 'en' ? 'visible' : 'hidden' }}
                    src={iconsImg.Check}
                    alt="Check"
                  />
                  <p style={{ color: lang !== 'en' ? '#333333' : '#9F9978' }}>
                    {t('header.lang.en')}
                  </p>
                </div>
              </div>
            )}
          </div> */}
          {signIn()}
          {/* <img src={iconsImg.Basket} alt="Basket" /> */}
        </div>
      </header>
      <SelectCityModal
        isOpen={isSelectModalCity}
        handleModal={() => setSelectModalCity(!isSelectModalCity)}
      />
    </>
  );
};

export default HeaderDesktop;
