import Plumbing from './plumbing.jpg';
import AfterRepair from './after_repair.jpg';
import Electrician from './electrician.jpg';
import Facade from './facade.jpg';
import Flowers from './flowers.jpg';
import Apartments from './apartments.jpg';
import Landscaping from './landscaping.jpg';
import Lawn from './lawn.jpg';
import Offices from './offices.jpg';
import Repair from './repair.jpg';
import Rooms from './rooms.jpg';
import Territory from './territory.jpg';
import Furniture from './furniture.png';
import Ventilation from './ventilation.png';

export const subServicesImg = {
  Plumbing,
  Rooms,
  AfterRepair,
  Electrician,
  Facade,
  Flowers,
  Apartments,
  Landscaping,
  Lawn,
  Offices,
  Repair,
  Territory,
  Ventilation,
  Furniture,
};
