import React from 'react';
import './MostPopularStyle.scss';
import { useTranslation } from 'react-i18next';
import { gazdaImgs } from '../../../assets/imgs/gazda';
import parse from 'html-react-parser';
import { gazdaMobileImgs } from '../../../assets/imgs/gazda/mobile';

interface ICard {
  text: string;
  img: string;
}
interface IProps {
  openModal: any;
}
const MostPopular = ({ openModal }: IProps) => {
  const { t } = useTranslation();

  const arrayCards: ICard[] = [
    {
      text: t('gazda_page.most_popular.imgs.plumbing_equipment'),
      img: gazdaImgs.PlumbingEquipment,
    },
    {
      text: t('gazda_page.most_popular.imgs.electricity'),
      img: gazdaImgs.Electricity,
    },
    {
      text: t('gazda_page.most_popular.imgs.installation_doors'),
      img: gazdaImgs.InstallationDoors,
    },
    {
      text: t('gazda_page.most_popular.imgs.installation_furniture'),
      img: gazdaImgs.InstallationFurniture,
    },
    {
      text: t('gazda_page.most_popular.imgs.installation_cornices'),
      img: gazdaImgs.InstallationCornices,
    },
    {
      text: t('gazda_page.most_popular.imgs.connecting_appliances'),
      img: gazdaImgs.ConnectingAppliances,
    },

    {
      text: t('gazda_page.most_popular.imgs.cosmetic_premises'),
      img: gazdaImgs.CosmeticPremises,
    },
  ];
  const arrayCardsMobile: ICard[] = [
    {
      text: t('gazda_page.most_popular.imgs.plumbing_equipment'),
      img: gazdaMobileImgs.PlumbingEquipment,
    },
    {
      text: t('gazda_page.most_popular.imgs.electricity'),
      img: gazdaMobileImgs.Electricity,
    },
    {
      text: t('gazda_page.most_popular.imgs.installation_doors'),
      img: gazdaMobileImgs.InstallationDoors,
    },
    {
      text: t('gazda_page.most_popular.imgs.installation_furniture'),
      img: gazdaMobileImgs.InstallationFurniture,
    },
    {
      text: t('gazda_page.most_popular.imgs.installation_cornices'),
      img: gazdaMobileImgs.InstallationCornices,
    },
    {
      text: t('gazda_page.most_popular.imgs.connecting_appliances'),
      img: gazdaMobileImgs.ConnectingAppliances,
    },

    {
      text: t('gazda_page.most_popular.imgs.cosmetic_premises'),
      img: gazdaMobileImgs.CosmeticPremises,
    },
  ];

  function getCard({ img, text }: ICard, index: number) {
    return (
      <div key={`${index}_card`} className="card">
        <img src={img} alt="card" />
        <span>{text}</span>
      </div>
    );
  }
  return (
    <div className="gazda_most_popular_component">
      <p className="title">{t('gazda_page.most_popular.title')}</p>
      <p>{parse(t('gazda_page.most_popular.sub_title'))}</p>
      <div className="wrapper_cards">
        {window.innerWidth > 768
          ? arrayCards.map((item, index) => {
              return getCard(item, index);
            })
          : arrayCardsMobile.map((item, index) => {
              return getCard(item, index);
            })}
      </div>
      <div className="box_button">
        <button onClick={openModal}>{t('order')}</button>
      </div>
    </div>
  );
};

export default MostPopular;
