import React, { useState } from 'react';
import './SelectCustomStyle.scss';
import { ISelect } from '../../../types/users';
import { iconsImg } from '../../../assets/imgs/icons';

interface IProps {
  currencies: ISelect[];
  selectLabel: string;
  selectItem: string;
  setSelectItem: any;
  isAll?: boolean;
}
const SelectCustom = ({ currencies, selectLabel, selectItem, setSelectItem, isAll }: IProps) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="select_custom">
      <div
        className="select"
        onClick={() => {
          setOpen(!isOpen);
        }}
      >
        {selectItem ? selectItem : 'Какой-то текст!'}
        {isOpen && (
          <div className="option_box">
            {currencies.map((item: ISelect) => (
              <div
                key={item.value}
                className="option"
                onClick={(e) => {
                  setSelectItem(!isAll ? item.label : item);
                }}
              >
                <img
                  src={iconsImg.Check}
                  alt="Check"
                  style={{ visibility: selectItem === item.value ? 'visible' : 'hidden' }}
                />
                <label className={selectItem === item.value ? 'isSelect' : ''}>{item.label}</label>{' '}
              </div>
            ))}
          </div>
        )}
      </div>
      <label>{selectLabel}</label>
      <img
        className={!isOpen ? 'rotate arrow' : 'arrow'}
        src={iconsImg.ArrowDownSvg}
        alt="ArrowDown"
      />
    </div>
  );
};

export default SelectCustom;
