import React, { useEffect, useState } from 'react';
import './FirtsStepStyle.scss';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import { iconsImg } from '../../../assets/imgs/icons';
import ButtomCustom from '../../customs/buttomCustom/ButtonCustom';
import MaskedInput from 'react-text-mask';
import CustomSelect from '../../customs/customSelectLib/CustomSelectLib';
import DragDrop from '../../customs/dragDrop/DragDrop';
import { RegistrationWorkerSchema } from '../../../assets/Schemes';
import { Link } from 'react-router-dom';
import { IRegistartion } from '../../../types';
import { phoneNumberMask } from '../../../helpers';
import { getInput } from '../../form';
import { getCities, getListJobsApi } from '../../../api';
import Resizer from 'react-image-file-resizer';

const cityesOptionst = [
  {
    value: 'kiev',
    label: 'Киев',
  },
  {
    value: 'Kharkiv',
    label: 'Харьков',
  },
  {
    value: 'Lviv',
    label: 'Львов',
  },
];

interface IProps {
  registratin: IRegistartion;
  setRegistration: any;
  setStep: any;
}

const FirstStep = ({
  registratin: {
    avatar,
    email,
    fieldActivity,
    firstName,
    lastName,
    password,
    phone,
    replayPassword,
    surname,
    city,
  },
  setStep,
  setRegistration,
}: IProps) => {
  const { t } = useTranslation();
  const [isShowPassword, handlShowPassword] = useState(false);
  const [isShowPasswordTwo, handlShowPasswordTwo] = useState(false);
  const [jobsArea, setJobsArea] = useState();
  const [cities, setСities] = useState(cityesOptionst);

  useEffect(() => {
    getListJobsApi().then(({ data }) => {
      setJobsArea(data);
    });
    getCities().then(({ data }) => {
      setСities(data);
    });
  }, []);

  return (
    <div className="first_step">
      <p>{t('registration_worker.title')}</p>
      <span>{t('registration_worker.sub_title')}</span>
      <div className="form_box">
        <Formik
          initialValues={{
            email,
            phone,
            lastName,
            firstName,
            surname,
            password,
            replayPassword,
            fieldActivity,
            avatar,
            city,
          }}
          validationSchema={RegistrationWorkerSchema}
          onSubmit={async (values: IRegistartion) => {
            // debugger;
            // await new Promise((resolve) => setTimeout(resolve, 500));
            // alert(JSON.stringify(values, null, 2));
            setRegistration({ ...values });
            setStep();
          }}
        >
          {({ errors, touched, isValid, dirty, values, setFieldValue }) => (
            <Form>
              {/* last_name  */}
              {getInput({
                values,
                errors,
                touched,
                name: 'lastName',
                text: t('placeholder.last_name'),
              })}
              {/* firs_name  */}
              {getInput({
                values,
                errors,
                touched,
                name: 'firstName',
                text: t('placeholder.firs_name'),
              })}
              {/* last_name  */}
              {getInput({
                values,
                errors,
                touched,
                name: 'surname',
                text: t('placeholder.surname'),
              })}
              {/* email  */}
              {getInput({
                values,
                errors,
                touched,
                name: 'email',
                text: t('placeholder.email'),
              })}
              {/* phone */}
              <div className={!values.phone ? 'box_input' : 'box_input pd'}>
                <Field
                  name="phone"
                  render={({ field }: any) => (
                    <MaskedInput
                      {...field}
                      mask={phoneNumberMask}
                      id="phone"
                      placeholder={values.phone ? t('placeholder.phone') : '+380(__) ___-____'}
                      type="text"
                      className={errors.phone && touched.phone ? 'error_input' : ''}
                      onChange={(e: any) => {
                        setFieldValue('phone', e.currentTarget.value);
                      }}
                      onFocus={(e: any) => {
                        !values.phone && setFieldValue('phone', '+380(');
                      }}
                    />
                  )}
                />
                {values.phone && <span className="placeholder"> {t('placeholder.phone')}</span>}
                {/* {errors.phone && touched.phone ? (
                  <div className="error">
                    {' '}
                    <img src={iconsImg.Error} alt="error" />
                    {errors.phone}
                  </div>
                ) : null} */}
              </div>
              <div
                className={
                  !values.city ? 'box_input box_select ones' : 'box_input  box_select pd ones'
                }
              >
                {values.city && <span className="name_input">Мiсто</span>}
                <Field
                  className="custom-select"
                  name="city"
                  options={cities}
                  component={CustomSelect}
                  placeholder={'Мiсто'}
                />
                {/* {errors.city && touched.city ? (
                  <div className="error">
                    <img src={iconsImg.Error} alt="error" />
                    {errors.city}
                  </div>
                ) : null} */}
              </div>
              {/* select  */}
              <div
                className={
                  values.fieldActivity.length === 0
                    ? 'box_input box_select'
                    : 'box_input  box_select pd'
                }
              >
                {values.fieldActivity.length !== 0 && (
                  <span className="name_input">{t('placeholder.our_activity')}</span>
                )}
                <Field
                  className="custom-select"
                  name="fieldActivity"
                  options={jobsArea}
                  component={CustomSelect}
                  placeholder={t('placeholder.our_activity')}
                  isMulti={true}
                />
                {/* {errors.fieldActivity && touched.fieldActivity ? (
                  <div className="error">
                    {' '}
                    <img src={iconsImg.Error} alt="error" />
                    {errors.phone}
                  </div>
                ) : null} */}
              </div>
              {/* password */}
              <div className={!values.password ? 'box_input' : 'box_input pd'}>
                <Field
                  name="password"
                  type={isShowPassword ? 'text' : 'password'}
                  className={errors.password && touched.password ? 'error_input' : ''}
                  placeholder={t('placeholder.password')}
                />
                <img
                  src={!isShowPassword ? iconsImg.ShowPassword : iconsImg.NotShowPassword}
                  alt="show_password"
                  onClick={() => handlShowPassword(!isShowPassword)}
                />
                {values.password && (
                  <span className="placeholder"> {t('placeholder.password')}</span>
                )}
                {/* {errors.password && touched.password ? (
                  <div className="error">
                    {' '}
                    <img src={iconsImg.Error} alt="error" />
                    {errors.password}
                  </div>
                ) : null} */}
              </div>
              {/* password two */}
              <div className={!values.replayPassword ? 'box_input' : 'box_input pd'}>
                <Field
                  name="replayPassword"
                  type={isShowPasswordTwo ? 'text' : 'password'}
                  className={errors.replayPassword && touched.replayPassword ? 'error_input' : ''}
                  placeholder={t('placeholder.replay_password')}
                />
                <img
                  src={!isShowPasswordTwo ? iconsImg.ShowPassword : iconsImg.NotShowPassword}
                  alt="show_password"
                  onClick={() => handlShowPasswordTwo(!isShowPasswordTwo)}
                />
                {values.replayPassword && (
                  <span className="placeholder"> {t('placeholder.replay_password')}</span>
                )}
                {/* {errors.replayPassword && touched.replayPassword ? (
                  <div className="error">
                    {' '}
                    <img src={iconsImg.Error} alt="error" />
                    {errors.replayPassword}
                  </div>
                ) : null} */}
              </div>
              {/* upload Files  */}
              <div className={values.avatar.length > 0 ? 'drop mb' : 'drop'}>
                <span className="name_input">{t('placeholder.avatar')}</span>
                <Field
                  name="avatar"
                  component={DragDrop}
                  values={values.avatar}
                  placeholder={t('placeholder.our_activity')}
                />
              </div>
              <div className="links">
                <Link to="/registration-choice">
                  <img src={iconsImg.ArrowLeftBlack} alt="" /> {t('back')}
                </Link>
                <ButtomCustom isValid={isValid} dirty={dirty} text={t('next')} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default FirstStep;
