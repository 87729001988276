import React from 'react';
import './QuestionStyle.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { iconsImg } from '../../../assets/imgs/icons';

const Question = () => {
  const { t } = useTranslation();

  return (
    <div className="question">
      <h3>{t('maim_page.questiona.title')}</h3>
      <label>{t('maim_page.questiona.sub_title')}</label>
      <div className="wrapper_links">
        <Link to="/engineering">
          {' '}
          <div className="box_link">
            {t('engineering')}
            <img src={iconsImg.ArrowLink} alt="arrow" />
          </div>
        </Link>
        <Link to="/cleaning">
          {' '}
          <div className="box_link">
            {t('cleaning')}
            <img src={iconsImg.ArrowLink} alt="arrow" />
          </div>
        </Link>
        <Link to="/landscaping">
          {' '}
          <div className="box_link">
            {t('landscaping')}
            <img src={iconsImg.ArrowLink} alt="arrow" />
          </div>
        </Link>
        <Link to="/houses">
          {' '}
          <div className="box_link">
            {t('houses')}
            <img src={iconsImg.ArrowLink} alt="arrow" />
          </div>
        </Link>
        <Link to="/gazda">
          {' '}
          <div className="box_link">
            {t('gazda')}
            <img src={iconsImg.ArrowLink} alt="arrow" />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Question;
