import Facade from './facade.png';
import Flowers from './flowers.png';
import Landscaping from './landscaping.png';
import Lawn from './lawn.png';

export const landscapingImgs = {
  Facade,
  Flowers,
  Landscaping,
  Lawn,
};
