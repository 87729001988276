import axios from 'axios';
import { IClient, IFixPrice, IWorker } from '../types';

const apiUrl = 'https://api.stephub.com.ua';
const config = localStorage.getItem('token')
  ? {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }
  : {};
// axios.defaults.headers.common = { Authorization: `Bearer ${localStorage.getItem('token')}` };
export async function getPrice(): Promise<any> {
  try {
    return await axios.get(`${apiUrl}/api/fix-price/list`);
  } catch (error) {
    // alert(error);
  }
}

export async function loginApi(data: { username: string; password: string }): Promise<any> {
  try {
    return await axios.post(`${apiUrl}/api/login_check`, data);
  } catch (error) {
    // alert(error);
  }
}

export async function registationClientApi(data: IClient): Promise<any> {
  try {
    return await axios.post(`${apiUrl}/api/registration/user`, data);
  } catch (error) {
    // alert(error);
  }
}

export async function registationWorkerApi(data: any): Promise<any> {
  console.log('data', data);
  return await axios.post(`${apiUrl}/api/registration/professional`, data);
}

export async function getListJobsApi(): Promise<any> {
  try {
    return await axios.get(`${apiUrl}/api/job-area/list`);
  } catch (error: any) {
    console.log(error);
  }
}

export async function orderGazda(data: any): Promise<any> {
  try {
    return await axios.post(`${apiUrl}/api/order/create`, data, config);
  } catch (error) {
    // alert(error);
  }
}

export async function getCities(): Promise<any> {
  try {
    return await axios.get(`${apiUrl}/api/city/list`);
  } catch (error) {
    // alert(error);
  }
}
export async function getCategory(): Promise<any> {
  try {
    return await axios.get(`${apiUrl}/api/category/list`);
  } catch (error) {
    // alert(error);
  }
}

export async function sendTokenApi(token: string): Promise<any> {
  try {
    return await axios.post(`${apiUrl}/api/user/confirm-email`, { token: token });
  } catch (error) {
    // alert(error);
  }
}

export async function getListOrdersApi(search = 'price=200&orderBy=date&sorts=asc'): Promise<any> {
  try {
    return await axios.get(`${apiUrl}/api/order/list/accept-jobs?${search}`, config);
  } catch (error: any) {
    if (error.response.status === 401) {
      location.replace('authorization');
      sessionStorage.setItem('last_url', location.pathname);
    }
    console.log(error);
  }
}

export async function getListOrdersClientApi(search = 'status=active'): Promise<any> {
  try {
    return await axios.get(`${apiUrl}/api/order/list/user-order?${search}`, config);
  } catch (error: any) {
    if (error.response.status === 401) {
      location.replace('authorization');
      sessionStorage.setItem('last_url', location.pathname);
    }
    console.log(error);
  }
}

export async function getListOrdersAllApi(
  search = 'price=200&orderBy=date&sorts=asc'
): Promise<any> {
  try {
    return await axios.get(`${apiUrl}/api/order/list/jobs?${search}`, config);
  } catch (error: any) {
    if (error.response.status === 401) {
      window.location.replace('authorization');
      sessionStorage.setItem('last_url', location.pathname);
    }
    if (error.response.status === 403) {
      window.location.replace('/');
      sessionStorage.setItem('last_url', location.pathname);
    }
    console.log(error);
  }
}

export async function getCategoriesApi(id: number | string): Promise<any> {
  try {
    return await axios.get(`${apiUrl}/api/service/list?category=${id}`);
  } catch (error) {
    console.log(error);
  }
}

export async function getOrderPayApi(id: number | string): Promise<any> {
  try {
    return await axios.get(`${apiUrl}/api/order/accept/${id}`, config);
  } catch (error) {
    console.log(error);
  }
}

export async function getUserInfo(): Promise<any> {
  try {
    return await axios.get(`${apiUrl}/api/user/`, config);
  } catch (error: any) {
    // if (error.response.status === 401) {
    //   window.location.replace('authorization');
    //   sessionStorage.setItem('last_url', location.pathname);
    // }
    console.log(error);
  }
}

export async function updateUserInfo(data: any): Promise<any> {
  return await axios.post(`${apiUrl}/api/user/professional/update`, data, config);
}

export async function updateUserClientInfo(data: any): Promise<any> {
  try {
    return await axios.post(`${apiUrl}/api/user/update`, data, config);
  } catch (error: any) {
    if (error.response.status === 401) {
      window.location.replace('authorization');
      sessionStorage.setItem('last_url', location.pathname);
    }
    console.log(error);
  }
}

export async function orderFinish({ id, files }: any): Promise<any> {
  try {
    return await axios.post(`${apiUrl}/api/order/finish/${id}`, { files: [...files] }, config);
  } catch (error: any) {
    if (error.response.status === 401) {
      window.location.replace('authorization');
      sessionStorage.setItem('last_url', location.pathname);
    }
    console.log(error);
  }
}
export async function orderCancellations(id: number): Promise<any> {
  return await axios.get(`${apiUrl}/api/order/list/reject-order/${id}`, config);
}

export async function preloadFilesToBackEnd(
  file: FormData
): Promise<{ path: string; name: string }> {
  return await axios.post(`${apiUrl}/api/registration/file`, file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export async function getSectionsApi(url: string): Promise<any> {
  return await axios.get(`${apiUrl}/api/category/list?slug=${url}`, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export async function sendResetApi(email: string): Promise<any> {
  return await axios.post(`${apiUrl}/api/user/reset-password`, {
    email: email,
  });
}

export async function getResetEmailApi(token: string): Promise<any> {
  return await axios.post(`${apiUrl}/api/user/reset-password-information`, {
    token: token,
  });
}

export async function getResetConfirmApi(token: string, password: string): Promise<any> {
  return await axios.post(`${apiUrl}/api/user/reset-password-confirm`, {
    token: token,
    password: password,
  });
}
