import React, { useEffect, useState } from 'react';
import './ListOrderStyle.scss';
import { useTranslation } from 'react-i18next';
import { getCategory, getCities, getListOrdersAllApi, getListOrdersApi } from '../../../api';
import { ICategory, ICities, IFilter, IOrderWorker } from '../../../types';
import { iconsImg } from '../../../assets/imgs/icons';
import SelectCustom from '../../customs/selectCustom/SelectCustom';
import Pagination from '@mui/material/Pagination';
import FilterMobile from '../filterMobile/FilterMobile';
import PayModal from '../../modals/payModal/PayModal';
import { useAction } from '../../../hooks/useAction';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { getAllStrFilter } from '../../../helpers';
import ButtomCustom from '../../customs/buttomCustom/ButtonCustom';
import { Link } from 'react-router-dom';

interface IProps extends IFilter {
  listOrder: any;
  allData: any;
  setListOrder: any;
  total: any;
  setTotal: any;
  page: any;
  setPage: any;
  pageCount: any;
  setPageCount: any;
  getList: any;
}

const ListOrder = (props: IProps) => {
  const [search, setSearch] = useState<string>('');
  const [select, handleSelect] = useState<string>('');
  const [selectCard, handleSelectCard] = useState<string>();
  const [isOpenPayModal, handleOpenPayModal] = useState(false);
  const [isOpenFilter, handleOpenFilter] = useState<boolean>(false);

  const { t } = useTranslation();
  const { setOrderFilter } = useAction();
  const { filter } = useTypedSelector((state) => state.user);

  const handleChange = (e: any, p: any) => {
    getListOrdersAllApi(getAllStrFilter({ ...filter, page: e.target.innerText })).then(
      ({ data }) => {
        props.setTotal(data?.totalItemCount);
        props.setPage(data?.currentPageNumber);
        props.setPageCount(Math.ceil(data.totalItemCount / data.itemNumberPerPage));
        props.setListOrder(data.orders);
      }
    );
    props.setPage(p);
  };
  useEffect(() => {
    props.setPage(props.allData?.currentPageNumber);
    props.setPageCount(Math.ceil(props.allData?.totalItemCount / props.allData?.itemNumberPerPage));
  }, [props]);
  useEffect(() => {
    handleSelect(currencies[0].label);
  }, []);
  const currencies = [
    {
      value: 'for_expensive',
      label: 'Від дешевих до дорогих',
    },
    {
      value: 'expensive',
      label: 'Від дорогих до дешевих',
    },
    {
      value: 'new',
      label: 'Від нових',
    },
    {
      value: 'for_new',
      label: 'До нових',
    },
  ];
  // sort
  function handleSort(item: any) {
    handleSelect(item.label);
    switch (item.value) {
      case 'for_expensive': {
        getListOrdersAllApi(getAllStrFilter({ ...filter, sorts: 'asc', orderBy: 'price' })).then(
          ({ data }) => {
            props.setListOrder(data.orders);
          }
        );
        break;
      }
      case 'expensive': {
        getListOrdersAllApi(getAllStrFilter({ ...filter, sorts: 'desc', orderBy: 'price' })).then(
          ({ data }) => {
            props.setListOrder(data.orders);
          }
        );
        break;
      }
      case 'new': {
        getListOrdersAllApi(getAllStrFilter({ ...filter, sorts: 'asc', orderBy: 'date' })).then(
          ({ data }) => {
            props.setListOrder(data.orders);
          }
        );

        break;
      }
      case 'for_new': {
        getListOrdersAllApi(getAllStrFilter({ ...filter, sorts: 'desc', orderBy: 'date' })).then(
          ({ data }) => {
            props.setListOrder(data.orders);
          }
        );
        break;
      }
    }
  }
  function getOrder(item: any, ind: number) {
    return (
      <div
        className="order_item"
        key={`order_${ind}`}
        onClick={() => {
          handleSelectCard({ ...item });
          handleOpenPayModal(!isOpenPayModal);
        }}
      >
        <div className="sepparetion" />
        <div className="box_info">
          <p>{item.category && item.category.title}</p>
          <div className="box_local">
            <>
              <span>
                {' '}
                <img src={iconsImg.Calendar} alt="calendar" />
                {'Виконати: '} {('0' + new Date(item.appointment).getDate()).slice(-2)}.
                {('0' + (new Date(item.appointment).getMonth() + 1)).slice(-2)}.
                {new Date(item.appointment).getFullYear()}
                {'  на  '}
                {new Date(item.appointment).getHours()}:{new Date(item.appointment).getMinutes()}
              </span>
            </>
            <>
              <span>
                <img src={iconsImg.LocationGray} alt="LocationGray" />
                {item.city.title}, {item.address}
              </span>
            </>
            <>
              <span>
                <img src={iconsImg.Clock} alt="Clock" />
                {('0' + new Date(item.appointment).getDate()).slice(-2)}.
                {('0' + (new Date(item.appointment).getMonth() + 1)).slice(-2)}.
                {new Date(item.appointment).getFullYear()}
              </span>
            </>
          </div>
          <div className="box_price">
            <span className="price">
              {Math.ceil(item.amount * 0.9)} {t('gazda_page.main_component.price.sub_2')}
            </span>
            {item.isUrgent && (
              <span className="urgent">
                <img src={iconsImg.Urgent} alt="Urgent" /> Термінове замовлення
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
  const emptyOrders = () => {
    return (
      <div className="empty">
        <img src={iconsImg.BadSmail} alt="BadSmail" />
        <p>{t('personal_account_page.order.empty.title')}</p>
        <Link to={'/'}>
          <ButtomCustom isValid={true} dirty={true} text={'Перейти до головної'} />
        </Link>
      </div>
    );
  };
  const handleChang = (e: any) => {
    setSearch(e.target.value);
    setOrderFilter('search', e.target.value);
    const allStr = getAllStrFilter({ ...filter, search: e.target.value });
    getListOrdersAllApi(allStr).then(({ data }) => {
      props.setTotal(data.totalItemCount);
      props.setPage(data.currentPageNumber);
      props.setPageCount(Math.ceil(data.totalItemCount / data.itemNumberPerPage));
      props.setListOrder(data.orders);
    });
    return;
  };

  return (
    <>
      <div className="wrappen_orders">
        <div className="list_order_container">
          <div className="box_filter">
            <div className="input_custom">
              <div className="label_box">
                <img src={iconsImg.Search} alt="search" />
              </div>
              <input type="text" onChange={handleChang} placeholder={t('sort.search')!} />
            </div>
            <div
              className="filter"
              onClick={(e) => {
                handleOpenFilter(!isOpenFilter);
                e.preventDefault();
              }}
            >
              <img src={iconsImg.Filter} alt="filter" />
              <span>{t('orders_page.filter.title')}</span>
            </div>
          </div>
          {props.listOrder.length !== 0 ? (
            <>
              <div className="box_select">
                <span className="order_t">{props.total} замовлень</span>
                <div className="wrapper_select">
                  <span>{t('sort.title')}</span>
                  <SelectCustom
                    currencies={currencies}
                    setSelectItem={handleSort}
                    selectLabel={' '}
                    selectItem={select}
                    isAll={true}
                  />
                </div>
              </div>
              <div className="wrapper_orders">
                {props.listOrder.map((item: any, ind: number) => {
                  return getOrder(item, ind);
                })}
              </div>
            </>
          ) : (
            emptyOrders()
          )}
        </div>
        <Pagination
          count={props.pageCount ? props.pageCount : 1}
          size="large"
          page={props.page ? props.page : 1}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </div>
      <FilterMobile
        isOpen={isOpenFilter}
        handleOpen={() => handleOpenFilter(!isOpenFilter)}
        {...props}
      />
      <PayModal
        handleModal={() => handleOpenPayModal(!isOpenPayModal)}
        isOpen={isOpenPayModal}
        card={selectCard}
        getList={props.getList}
      />
    </>
  );
};
export default ListOrder;
