import React, { useEffect, useState } from 'react';
import { getPrice, getUserInfo } from '../../api';
import BreadСrumbsGazda from '../../components/gazdaOrder/breadСrumbs/BreadСrumbsGazda';
import FirstStep from '../../components/gazdaOrder/firstStep/FirstStep';
import SecondStep from '../../components/gazdaOrder/secondStep/SecondStep';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { IFixPrice, IOrderGazda } from '../../types';
import './GazdaOrderStyle.scss';
import GazdaModal from '../../components/modals/gazdaModal/GazdaModal';

const defaultDataOrder: IOrderGazda = {
  hours: 2,
  docs: [],
  comment: '',
  email: '',
  firstName: '',
  lastName: '',
  secondName: '',
  phone: '',
  typePayment: 'pay_type_card',
  contact: 'contact_type_telegram',
  address: {
    city: '',
    street: '',
    boothNumber: '',
    entrance: '',
    floor: '',
    flat: '',
  },
};

const GazdaOrderPage = () => {
  const [orderGazda, setOrderGazda] = useState<IOrderGazda>(defaultDataOrder);
  const { prevOrder, gazdaHours } = useTypedSelector((state) => state.user.order);
  const [hours, setHours] = useState(gazdaHours);
  const [price, setPrice] = useState(0);
  const [step, setStep] = useState(0);
  const [isPay, handlePay] = useState(true);
  useEffect(() => {
    getPrice().then(({ data }) => {
      setPrice(data.filter((item: IFixPrice) => item.alias === 'gazda')[0].price);
    });
    localStorage.getItem('token')
      ? getUserInfo().then(({ data }) => {
          handlePay(data.isPay);
        })
      : handlePay(true);
  }, []);
  return (
    <div className="gazda_order_page">
      <BreadСrumbsGazda step={step} />
      {step === 0 ? (
        <FirstStep
          step={step}
          nextStep={() => {
            setStep(step + 1);
            window.scrollTo(0, 0);
          }}
          hours={hours}
          setHours={setHours}
          defaultHorus={gazdaHours}
          setOrderGazda={setOrderGazda}
          orderGazda={orderGazda}
          price={price}
          prevOrder={prevOrder}
        />
      ) : (
        <SecondStep orderGazda={orderGazda} price={price} prevOrder={prevOrder} isPay={isPay} />
      )}
    </div>
  );
};

export default GazdaOrderPage;
