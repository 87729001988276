import React, { useState, useEffect } from 'react';
import './PersonalAccountStyle.scss';
import { useTranslation } from 'react-i18next';
import BreadСrumbs from '../../components/breadСrumbs/BreadСrumbs';
import ContollPanel from '../../components/personalAccount/controllPanel/ContollPanel';
import { getUserInfo } from '../../api';
import { IUpdateForm } from '../../types';
import { base64toFile } from '../../helpers';
import InfoBlock from '../../components/personalAccount/worker/infoBlock/InfoBlock';
import MyOrder from '../../components/personalAccount/worker/myOrder/MyOrder';
import InfoBlockClient from '../../components/personalAccount/client/infoBlock/InfoBlockClient';
import MyOrderClient from '../../components/personalAccount/client/myOrder/MyOrderClient';
import Load from '../../components/modals/load/Load';

const defaultRegistartion: IUpdateForm = {
  email: '',
  phone: '',
  lastName: '',
  firstName: ' ',
  surname: '',
  password: '',
  replayPassword: '',
  fieldActivity: [],
  avatar: {},
  documents: [],
};

const PersonalAccount = () => {
  const { t } = useTranslation();
  const [selectName, handleSelect] = useState<string>('order');
  const [isProfessional, handlProfessional] = useState(false);
  const [registration, setRegistration] = useState<IUpdateForm>();
  const [isLoading, handlLoading] = useState(true);

  const arrayLinks = [
    { name: t('main'), link: '/' },
    { name: t('personal_account_page.title'), link: '/personal-account' },
  ];

  const getListsApi = () => {
    getUserInfo()
      .then(({ data }) => {
        handlProfessional(data.isProfessional);
        const infoUser: any = {
          ...defaultRegistartion,
          avatar: data.avatarBase64,
          email: data.email,
          phone: data.phone,
          lastName: data.lastName,
          firstName: data.firstName,
          surname: data.secondName,
          password: '',
          replayPassword: '',
          fieldActivity: data.jobAreas.map((item: any) => {
            return {
              label: item.title,
              value: item.id,
            };
          }),
          documents: [],
        };
        // data.documents.forEach((element: any) => {
        //   infoUser.documents.push(base64toFile(element.path, element.originalName));
        // });

        setRegistration(infoUser);
        handlLoading(false);
      })
      .finally(() => {
        handlLoading(false);
      });
  };
  useEffect(() => {
    handlLoading(true);
    getListsApi();
  }, []);
  return (
    <div className="personal_account">
      <BreadСrumbs arrayLinks={arrayLinks} isBlack={true} />
      <h3>{t('personal_account_page.title')}</h3>
      <div className="box_info">
        <ContollPanel
          selectName={selectName}
          handleSelect={handleSelect}
          isProfessional={isProfessional}
        />
        {selectName === 'user' && isProfessional && !isLoading && (
          <InfoBlock infoUser={registration} handleLoading={handlLoading} isLoading={isLoading} />
        )}
        {selectName === 'user' && !isProfessional && !isLoading && (
          <InfoBlockClient
            infoUser={registration}
            handleLoading={handlLoading}
            isLoading={isLoading}
          />
        )}

        {selectName === 'order' && isProfessional && !isLoading && <MyOrder />}
        {selectName === 'order' && !isProfessional && !isLoading && <MyOrderClient />}
      </div>
      <Load isLoading={isLoading} />
    </div>
  );
};

export default PersonalAccount;
