import React, { useEffect } from 'react';
import './ConfirmEmailStyle.scss';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { sendTokenApi } from '../../api';
import { iconsImg } from '../../assets/imgs/icons';
import { Link } from 'react-router-dom';
import ButtomCustom from '../../components/customs/buttomCustom/ButtonCustom';

const ConfirmEmail = () => {
  const { t } = useTranslation();

  useEffect(() => {
    sendTokenApi(window.location.search.split('token=')[1]);
  }, []);
  //
  return (
    <div className="confirm_container">
      <div className="done_registration">
        <img src={iconsImg.CheckResetPassword} alt="" />
        <p>Ваша пошта підтверджена</p>
        <span>
          Очікуйте перевірку документів адміністрацією, для подальшого користування сервісом
        </span>

        <div className="links">
          <Link to="/" className="box_back">
            <ButtomCustom
              isValid={true}
              dirty={true}
              text={t('registration_worker.done_registration.back_to_main')}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};
export default ConfirmEmail;
