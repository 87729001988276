import React from 'react';
import './StepComponentStyle.scss';
import { useTranslation } from 'react-i18next';
import { aboutPageImgs } from '../../../assets/imgs/about_page';

interface Ilinks {
  url: string;
  img: string;
  text: string;
}

const StepComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="step_container">
      <div className="box_logo">
        <img src={aboutPageImgs.Logo} alt="Logo" />
      </div>
      <div className="box_text">
        <div className="item">
          <span className="title">S</span>
          <span>
            Сервісне обслуговування приміщень, індивідуальні та комплексні послуги щодо їх
            експлуатації
          </span>
        </div>
        <div className="item">
          <span className="title">T</span>
          <span>Технічна експертиза будівель різного призначення з гарантією високої якості</span>
        </div>
        <div className="item">
          <span className="title">E</span>
          <span>Ефективні рішення для економії вашого часу й витрат</span>
        </div>
        <div className="item">
          <span className="title">P</span>
          <span>Простота у взаємодії з клієнтом та прозорість розрахунку вартості послуг</span>
        </div>
      </div>
    </div>
  );
};

export default StepComponent;
