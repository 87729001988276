import React, { useEffect, useState } from 'react';
// import './FilterStyle.scss';
import { useTranslation } from 'react-i18next';
import { getCategory, getCities, getListOrdersAllApi, getListOrdersApi } from '../../../api';
import { ICategory, ICities, IFilter } from '../../../types';
import { iconsImg } from '../../../assets/imgs/icons';
import CustomModal from '../../modals/customModal/CustomModal';
import ButtonCustom from '../../customs/buttomCustom/ButtonCustom';
import SelectCustom from '../../customs/selectCustom/SelectCustom';
import { getAllStrFilter } from '../../../helpers';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useAction } from '../../../hooks/useAction';
interface IProps extends IFilter {
  isOpen: boolean;
  handleOpen: any;
  setListOrder: any;
  setTotal: any;
  setPageCount: any;
  setPage: any;
}

const FilterMobile = ({
  isOpen,
  handleOpen,
  categoryList,
  cityList,
  setListOrder,
  setTotal,
  setPageCount,
  setPage,
}: IProps) => {
  const [strCategory, setStrCategory] = useState<string>('');
  const [strApiCity, setStrApiCity] = useState<string>('');
  const [strApiPrice, setStrApiPrice] = useState<string>('');
  const [strApiRange, setStrApiRange] = useState<string>('');
  const [sendApiCategory, setSendApiCategory] = useState<any>([]);
  const [sendApiCity, setSendApiCity] = useState<any>([]);
  const [sendApiPrice, setSendApiPrice] = useState<any>([]);
  const [sendApiRange, setSendApiRange] = useState<any>([]);
  const [select, handleSelect] = useState<string>('');
  const { setOrderFilter } = useAction();
  const { filter } = useTypedSelector((state) => state.user);
  const [allStr, setAllStr] = useState<any>([]);
  const { t } = useTranslation();

  useEffect(() => {
    handleSelect(currencies[0].label);
  }, []);

  function handleSort(item: any) {
    handleSelect(item.label);
    switch (item.value) {
      case 'for_expensive': {
        setAllStr(getAllStrFilter({ ...filter, sorts: 'asc', orderBy: 'price' }));
        break;
      }
      case 'expensive': {
        setAllStr(getAllStrFilter({ ...filter, sorts: 'desc', orderBy: 'price' }));
        break;
      }
      case 'new': {
        setAllStr(getAllStrFilter({ ...filter, sorts: 'asc', orderBy: 'date' }));
        break;
      }
      case 'for_new': {
        setAllStr(getAllStrFilter({ ...filter, sorts: 'desc', orderBy: 'date' }));
        break;
      }
    }
  }
  const timeRange: ICities[] = [
    { label: t('orders_page.filter.time_range.all'), value: '' },
    { label: t('orders_page.filter.time_range.today'), value: 'today' },
    { label: t('orders_page.filter.time_range.coming_week'), value: 'week' },
    { label: t('orders_page.filter.time_range.month'), value: 'month' },
    { label: t('orders_page.filter.time_range.two_month'), value: 'two_month' },
  ];
  const priceArray: ICities[] = [
    { label: 'від 200 грн', value: '200' },
    { label: 'від 500 грн', value: '500' },
    { label: 'від 1000 грн', value: '1000' },
    { label: 'від 2000 грн', value: '2000' },
  ];
  const currencies = [
    {
      value: 'for_expensive',
      label: 'Від дешевих до дорогих',
    },
    {
      value: 'expensive',
      label: 'Від дорогих до дешевих',
    },
    {
      value: 'new',
      label: 'Від нових',
    },
    {
      value: 'for_new',
      label: 'До нових',
    },
  ];
  const handleChange = (
    event: any,
    id: number | string,
    array: any[],
    setStr: any,
    key: string
  ) => {
    let str = '';
    if (event.target.checked) {
      array.push(id);
      array.forEach((item, index) => {
        index === 0 ? (str = item) : (str = `${str},${item}`);
      });
    } else {
      array.filter((item, idx) => {
        item === id && array.splice(idx, 1);
      });
      array.forEach((item, index) => {
        index === 0 ? (str = item) : (str = `${str},${item}`);
      });
    }
    setOrderFilter(key, str);
    setAllStr(getAllStrFilter({ ...filter, [key]: str }));
    setStr(str);
  };
  function clean() {
    setStrCategory('');
    setStrApiCity('');
    setStrApiPrice('');
    setStrApiRange('');
    const inputs = document.getElementsByTagName('input');
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].type == 'checkbox') {
        inputs[i].checked = false;
      }
    }
  }
  function getFilter(name: string, id: number | string, array: string[], setStr: any, key: string) {
    return (
      <label className="container_checkbox" key={id}>
        <input
          type="checkbox"
          onClick={(e) => {
            handleChange(e, id, array, setStr, key);
            e.stopPropagation();
          }}
        />
        {name}
        <span className="checkmark"></span>
      </label>
    );
  }

  function getBoxFilter(array: ICities[], title: string, arrayApi: any, setStr: any, key: string) {
    return (
      <div className="box_filter">
        <div className="handle">
          <span>{title}</span>
        </div>
        {array.map((item) => {
          return getFilter(item.label, item.value, arrayApi, setStr, key);
        })}
      </div>
    );
  }
  return (
    <CustomModal isOpen={isOpen} onClose={handleOpen} style={{ zIndex: '99999' }}>
      <div className="filter_container">
        <div className="close_box">
          <img src={iconsImg.Close} alt="close" onClick={handleOpen} />
        </div>
        <p className="title">{t('orders_page.filter.title')}</p>
        <div className="box_select">
          <div className="wrapper_select">
            <span>{t('sort.title')}</span>
            <SelectCustom
              currencies={currencies}
              setSelectItem={handleSort}
              selectLabel={' '}
              selectItem={select}
              isAll={true}
            />
          </div>
        </div>
        <div className="sepparetion" />
        <div className="wrapper_filters">
          <div className="box_filter">
            <div className="handle">
              <span>{t('orders_page.filter.sphere')}</span>
            </div>
            {categoryList.map((item) => {
              return getFilter(item.title, item.id, sendApiCategory, setStrCategory, 'category');
            })}
          </div>
          {getBoxFilter(
            timeRange,
            t('orders_page.filter.time_range.title'),
            sendApiRange,
            setStrApiRange,
            'diapason'
          )}
        </div>
        <div className="sepparetion not_mob" />
        <div className="wrapper_filters m_mob">
          {getBoxFilter(cityList, t('orders_page.filter.city'), sendApiCity, setStrApiCity, 'city')}
          {getBoxFilter(
            priceArray,
            t('orders_page.filter.price'),
            sendApiPrice,
            setStrApiPrice,
            'price'
          )}
        </div>
        <div className="sepparetion not_mob last" />
        <div className="box_button">
          <div
            className="clean"
            onClick={() => {
              clean();
            }}
          >
            <ButtonCustom dirty={true} isValid={true} text={t('clean')} />
          </div>
          <ButtonCustom
            dirty={true}
            isValid={strCategory || strApiCity || strApiPrice || strApiRange ? true : false}
            text={t('apply')}
            onClick={() => {
              getListOrdersAllApi(allStr).then(({ data }) => {
                setListOrder(data.orders);
                setTotal(data.totalItemCount);
                setPageCount(Math.ceil(data.totalItemCount / data.itemNumberPerPage));
                setPage(data.currentPageNumber);
                handleOpen();
                clean();
              });
            }}
          />
        </div>
      </div>
    </CustomModal>
  );
};
export default FilterMobile;
