import React, { useEffect, useState } from 'react';
import { getPrice } from '../../api';
import CallComponent from '../../components/gazda/callComponent/CallComponent';
import ChooseUs from '../../components/gazda/chooseUs/ChooseUs';
import InfoComponent from '../../components/gazda/infoComponent/InfoComponent';
import MainComponent from '../../components/gazda/mainComponent/MainComponent';
import MostPopular from '../../components/gazda/mostPopular/MostPopular';
import OrderGazda from '../../components/gazda/orderGazda/OrderGazda';
import OurProcess from '../../components/gazda/ourProcess/OurProcess';
import GazdaModal from '../../components/modals/gazdaModal/GazdaModal';
import { IFixPrice } from '../../types';
import './GazdaPageStyle.scss';

const GazdaPage = () => {
  const [isModal, setModal] = useState(false);
  const [isOrder, setOrder] = useState(false);
  const [price, setPrice] = useState(0);
  useEffect(() => {
    getPrice().then(({ data }) => {
      setPrice(data.filter((item: IFixPrice) => item.alias === 'gazda')[0].price);
    });
  }, []);

  return (
    <div className="gazda_page">
      {isOrder && <OrderGazda count={price} />}
      <MainComponent
        price={price}
        openModal={() => {
          setModal(!isModal);
        }}
      />
      <InfoComponent
        price={price}
        openModal={() => {
          setModal(!isModal);
        }}
      />
      <MostPopular
        openModal={() => {
          setModal(!isModal);
        }}
      />
      <OurProcess />
      <ChooseUs />
      <CallComponent />
      <GazdaModal
        handleModal={() => setModal(!isModal)}
        isOpen={isModal}
        price={price}
        setOrder={() => setOrder(!isOrder)}
      />
    </div>
  );
};

export default GazdaPage;
