import { FieldProps } from 'formik';
import React, { useEffect, useState, useRef } from 'react';
import { iconsImg } from '../../../assets/imgs/icons';
import './DragDrop.scss';

const fileTypes = ['pdf', 'jpeg', 'png', 'heic', 'xlsx', 'xls'];
const fileTypesFinish = ['jpeg', 'png'];
const fileTypesString = '.pdf, .jpeg, .png, .heic, .xlsx, .xls';
const fileTypesStringMono = '.png, .jpg, .jpeg';
interface CustomSelectProps extends FieldProps {
  isMulti?: boolean;
  className?: string;
  placeholder?: string;
  values: File[];
  isFinish?: boolean;
}

function DragDrop({ isMulti = false, form, field, values, isFinish }: CustomSelectProps) {
  const [files, setFile] = useState<any>([]);
  const [error, setError] = useState<any>('');
  const fileInputRef: any = useRef(null);

  useEffect(() => {
    values?.length > 0 && values[0].name && setFile(values);
  }, [values]);
  function parsPushFiles(newFiles: any) {
    const filesArray = [...newFiles];
    filesArray.forEach((item: any) => {
      files.push(item);
    });
    return;
  }

  const handleChange = (file: any) => {
    const filesState = file.target.files;
    if (files.length > 0 && !isMulti) return;
    parsPushFiles(filesState);
    setFile(files);
    form.setFieldValue(field.name, files);
  };
  const deleteFile = (name: any) => {
    const filtered = files.filter(function (ele: File) {
      return ele.name !== name;
    });
    setFile(filtered);
    form.setFieldValue(field.name, filtered);
  };
  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    if (files.length > 0 && !isMulti) return;
    parsPushFiles(e.dataTransfer.files);
    setFile(files);
    form.setFieldValue(field.name, files);
    // Обработка перетащенного файла
  };

  return (
    <>
      <input
        id="file-upload"
        type="file"
        multiple={isMulti}
        onChange={handleChange}
        style={{ display: 'none' }}
        accept={isMulti && !isFinish ? fileTypesString : fileTypesStringMono}
        ref={fileInputRef}
      />
      <div className="box_drop" onDragOver={handleDragOver} onDrop={handleDrop}>
        <label className="drag_drop" htmlFor="file-upload">
          <span className="button"> Add files</span>
          <span className="types">
            Приймає
            {isMulti && !isFinish
              ? fileTypes.map((item) => {
                  return <label key={item}>.{item}</label>;
                })
              : fileTypesFinish.map((item) => {
                  return <label key={item}>.{item}</label>;
                })}
          </span>
        </label>
      </div>
      {/* </FileUploader> */}
      {files.length > 0 && (
        <>
          {files.map((item: File, index: number) => {
            return (
              <div key={`${item.name}_${index}`} className="files">
                <div className="box_name">
                  <img src={iconsImg.File} alt="File" />
                  <span>{item.name}</span>
                </div>
                <img
                  id="delete"
                  src={iconsImg.BasketSvg}
                  alt="Basket"
                  onClick={() => {
                    deleteFile(item.name);
                  }}
                />
              </div>
            );
          })}
        </>
      )}
      {error && <p>{error}</p>}
    </>
  );
}

export default DragDrop;
