import React, { useState, useEffect, useRef } from 'react';
import ButtomCustom from '../../../customs/buttomCustom/ButtonCustom';
import { Field, Form, Formik } from 'formik';
import { IOrderGazdaLast } from '../../../../types';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

interface IProps {
  orderGazda: IOrderGazdaLast;
  allCategoies: any;
  setOrderGazda: any;
  handleStep: any;
  handleTitleSecondStep: any;
}

const CallMasterEdite = ({
  orderGazda: { category, service, section },
  allCategoies,
  setOrderGazda,
  handleStep,
  handleTitleSecondStep,
}: IProps) => {
  const [categores, setСategory] = useState([]);
  const [services, setServices] = useState([]);
  const [sections, setSections] = useState([]);
  const { t } = useTranslation();
  const [isValidation, handleValid] = useState(false);
  const sectionsRef: any = useRef(null);
  const [defaultCategory, setDefaultCategory] = useState<any>();
  const [defaultServices, setDefaultServices] = useState<any>();
  const [defaultSections, setDefaultSections] = useState<any>();

  useEffect(() => {
    const categoriesApi = allCategoies.map((item: any) => {
      if (item.id === category) {
        item.children.forEach((elementServices: any) => {
          if (elementServices.id === service) {
            handleServices(elementServices, { category, service, section }, true);
          }
        });
        handleСategory(item, { category, service, section }, true);
      }
      return { value: item.id, label: item.title, ...item };
    });

    setСategory(categoriesApi);
    categoriesApi.map((option: any) => {
      if (option.value === category) {
        setDefaultCategory({ label: option.label, value: option.value });
      }
    });
  }, []);

  function handleСategory(selectItem: any, values: any, isClick = false) {
    setServices(
      selectItem.children.map((item: any) => {
        if (item.id === service) {
          setDefaultServices({ value: item.id, label: item.title });
        }
        return { value: item.id, label: item.title, ...item };
      })
    );
  }

  function handleServices(selectItem: any, values: any, isClick = false) {
    selectItem &&
      setSections(
        selectItem.children.map((item: any) => {
          if (item.id === section) {
            setDefaultSections({ value: item.id, label: item.title });
          }
          return { value: item.id, label: item.title, ...item };
        })
      );
    selectItem.children;
    !isClick && values.section && sectionsRef.current!.clearValue();
  }

  return (
    <div className="call_master">
      <Formik
        initialValues={{
          category,
          service,
          section,
        }}
        validate={(values) => {
          if (values.category && values.service && values.section) {
            handleValid(true);
          } else handleValid(false);
        }}
        onSubmit={async (values: any) => {
          handleStep();
          setOrderGazda({ ...values });
        }}
      >
        {({ values }) => (
          <Form>
            {/* //category */}
            <div
              className={
                !values.category ? 'box_input box_select ones' : 'box_input  box_select pd ones'
              }
            >
              {values.category && <span className="name_input">Категорія</span>}
              <Field
                className="custom-select"
                name="category"
                render={({ form, field }: any) => (
                  <Select
                    className="custom-select"
                    value={defaultCategory}
                    onChange={(e: any) => {
                      form.setFieldValue('section', '');
                      form.setFieldValue('service', '');
                      form.setFieldValue(field.name, e ? e.value : '');
                      handleСategory(e, form.values);
                      setDefaultCategory(e);
                      setDefaultServices('');
                      setDefaultSections('');
                      setSections([]);
                      handleValid(false);
                    }}
                    options={categores}
                    placeholder={'Категорія'}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#f1f1ee',
                        primary: '#b6b6a1',
                        primary50: '#f1f1ee',
                      },
                    })}
                  />
                )}
              />
            </div>
            {/* //service */}
            <div
              className={
                !values.service ? 'box_input box_select ones' : 'box_input  box_select pd ones'
              }
            >
              {values.service && <span className="name_input">Послуга</span>}
              <Field
                options={services}
                placeholder={'Послуга'}
                name="service"
                render={({ form, field }: any) => (
                  <Select
                    className="custom-select"
                    value={defaultServices}
                    onChange={(e: any) => {
                      handleServices(e, form.values);
                      form.setFieldValue(field.name, e ? e.value : '');
                      form.setFieldValue('section', '');
                      setDefaultServices(e);
                      setDefaultSections('');
                      handleValid(false);
                    }}
                    options={services}
                    placeholder={'Послуга'}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#f1f1ee',
                        primary: '#b6b6a1',
                        primary50: '#f1f1ee',
                      },
                    })}
                  />
                )}
              />
            </div>
            {/* //sections */}
            <div
              className={
                !values.section ? 'box_input box_select ones' : 'box_input  box_select pd ones'
              }
            >
              {values.section && <span className="name_input">Розділ</span>}
              <Field
                name="section"
                render={({ field, form }: any) => (
                  <Select
                    className="custom-select"
                    value={defaultSections}
                    onChange={(e: any) => {
                      form.setFieldValue(field.name, e ? e.value : '');
                      handleTitleSecondStep(e ? e.label : '');
                      setDefaultSections(e);
                    }}
                    options={sections}
                    ref={sectionsRef}
                    placeholder={'Розділ'}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#f1f1ee',
                        primary: '#b6b6a1',
                        primary50: '#f1f1ee',
                      },
                    })}
                  />
                )}
              />
            </div>
            <div className="links">
              <ButtomCustom
                isValid={isValidation}
                dirty={isValidation}
                text={t('modals.select_city.btn')}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CallMasterEdite;
