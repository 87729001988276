import React, { useState, useEffect } from 'react';
import './OurProcessStyle.scss';
import { useTranslation } from 'react-i18next';
import { ourprocessImgs } from '../../../assets/imgs/our_process';

const OurProcess = () => {
  const { t } = useTranslation();
  const [selectImg, setSelectImg] = useState<string>(ourprocessImgs.Step1);
  const [currentSlide, setCurrentSlide] = useState(0);

  function handleStep(index: number | null) {
    switch (index) {
      case 0: {
        setSelectImg(ourprocessImgs.Step1);
        break;
      }
      case 1: {
        setSelectImg(ourprocessImgs.Step2);
        break;
      }
      case 2: {
        setSelectImg(ourprocessImgs.Step3);
        break;
      }
      default: {
        setSelectImg(ourprocessImgs.Step1);
        break;
      }
    }
  }

  useEffect(() => {
    const slideShowInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => {
        handleStep((prevSlide + 1) % 3);
        return (prevSlide + 1) % 3;
      });
    }, 5000);

    return () => {
      clearInterval(slideShowInterval);
    };
  }, []);
  return (
    <div className="our_process">
      <p className="name_page">{t('maim_page.our_process.name_page')}</p>
      <p>{t('maim_page.our_process.title')}</p>
      <div className="box_steps">
        <div className="sepparetion" />
        <div className="steps">
          <div
            className="step"
            onClick={() => {
              setCurrentSlide(0);
              setSelectImg(ourprocessImgs.Step1);
            }}
          >
            <p className={currentSlide === 0 ? 'active' : ''}>
              {t('maim_page.our_process.step_1')}
            </p>
          </div>
          <div
            className="step"
            onClick={() => {
              setCurrentSlide(1);
              setSelectImg(ourprocessImgs.Step2);
            }}
          >
            <p className={currentSlide === 1 ? 'active' : ''}>
              {t('maim_page.our_process.step_2')}
            </p>
          </div>
          <div
            className="step"
            onClick={() => {
              setCurrentSlide(2);
              setSelectImg(ourprocessImgs.Step3);
            }}
          >
            <p className={currentSlide === 2 ? 'active' : ''}>
              {t('maim_page.our_process.step_3')}
            </p>
          </div>
        </div>
        <img src={selectImg} alt="step" />
      </div>
    </div>
  );
};

export default OurProcess;
