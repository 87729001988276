import translationUA from './ua.json';
import translationEN from './en.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
const resources = {
  ua: {
    translation: translationUA,
  },
  en: {
    translation: translationEN,
  },
} as const;

// eslint-disable-next-line import/no-named-as-default-member
i18n.use(initReactI18next).init({
  resources,
  lng: 'ua',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
