import React, { useState } from 'react';
import CustomModal from '../customModal/CustomModal';
import './SelectCityModalStyle.scss';
import { useTranslation } from 'react-i18next';
import SelectCustom from '../../customs/selectCustom/SelectCustom';
import { ISelect } from '../../../types/users';
import { useAction } from '../../../hooks/useAction';
import { iconsImg } from '../../../assets/imgs/icons';

const SelectCityModal = ({ handleModal, isOpen }: any) => {
  const [selectItem, setSelectItem] = useState('');
  const { setCity } = useAction();

  const { t } = useTranslation();
  const currencies: ISelect[] = [
    {
      value: 'Kiev',
      label: 'Kiev label',
    },
    {
      value: 'Vinnytsia',
      label: 'Vinnytsia label',
    },
    {
      value: 'Lviv',
      label: 'Lviv label',
    },
    {
      value: 'Cherkasy',
      label: 'Cherkasy label',
    },
    {
      value: 'Sumy',
      label: 'Sumy label',
    },
  ];
  return (
    <CustomModal handleModal={handleModal} isOpen={isOpen}>
      <div className="modal_select_city">
        <div className="box_close">
          <img className="close" src={iconsImg.Close} alt="close" onClick={() => handleModal()} />
        </div>{' '}
        <p>{t('modals.select_city.title')}</p>
        <label className="subTitle">{t('modals.select_city.sub_title')}</label>
        <SelectCustom
          currencies={currencies}
          selectLabel={t('modals.select_city.select_city')}
          selectItem={selectItem}
          setSelectItem={setSelectItem}
        />
        <button
          disabled={selectItem === ''}
          onClick={() => {
            setCity(selectItem);
            handleModal();
          }}
        >
          {t('modals.select_city.btn')}
        </button>
      </div>
    </CustomModal>
  );
};

export default SelectCityModal;
