import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { iconsImg } from '../../../../assets/imgs/icons';
import { IOrderAcount } from '../../../../types';
import ButtomCustom from '../../../customs/buttomCustom/ButtonCustom';
import CompletedModal from '../../../modals/completedModal/CompletedModal';
import PersonalAccountModal from '../../../modals/personalAccountModal/PersonalAccountModal';
import './MyOrderStyle.scss';
import { getListOrdersApi } from '../../../../api';
import { getDateTime } from '../../../../helpers';
import { Link } from 'react-router-dom';
import { Pagination } from '@mui/material';
import Load from '../../../modals/load/Load';

const MyOrder = () => {
  const { t } = useTranslation();
  const [isCompleted, setCompleted] = useState(false);
  const [isOpen, handleModal] = useState(false);
  const [isOpenCompleted, handleModalCompleted] = useState(false);
  const [ordersProces, setOrdersProces] = useState([]);
  const [selectCard, setSelectCard] = useState<IOrderAcount>();
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [isLoading, handleLoading] = useState<boolean>(false);

  const getList = (isProces: boolean, numberPage?: number) => {
    handleLoading(true);
    // debugger;
    getListOrdersApi(
      `${isProces ? 'status=finish' : ''}${numberPage ? `&page=${numberPage}` : ''}`
    ).then(({ data }) => {
      setTotal(data?.totalItemCount);
      setPage(data?.currentPageNumber);
      setPageCount(Math.ceil(data.totalItemCount / data.itemNumberPerPage));
      setOrdersProces(
        data.orders.map((item: any) => {
          return {
            reportImages: item.reportImages.map(
              (item: string) => `https://api.stephub.com.ua/${item}`
            ),
            orderFiles: item.orderFiles.map(
              (item: any) => `https://api.stephub.com.ua/${item.path}`
            ),
            orderServices: item.orderServices,
            start: item.appointment,
            address: {
              city: item.city.title,
              street: `${item.address},${item.building} `,
              hous: `підїзд ${item.flor}, поверх ${item.section}, квартира ${item.apartment}`,
            },
            price: item.amount,
            inProgress: item.workerAcceptDate,
            numberOrder: item.id,
            client: {
              fullName: `${item.firstName} ${item.lastName}`,
              phone: item.phone,
            },
            comment: item.comment,
          };
        })
      );
      handleLoading(false);
      window.scrollTo(0, 0);
    });
  };
  useEffect(() => {
    getList(isCompleted);
  }, []);
  const handleChange = (e: any, p: any) => {
    // debugger;
    getList(isCompleted, e.target.innerText);
  };
  function getCard(card: IOrderAcount, index: number) {
    return (
      <div
        className="card"
        key={`card_${index}`}
        onClick={() => {
          setSelectCard(card);
          !isCompleted ? handleModal(!isOpen) : handleModalCompleted(!isOpenCompleted);
        }}
      >
        <div className="separator" />
        <p>
          {card.orderServices.map((item: any, index) => {
            return (
              <span key={`${item.title}_${index}`}>
                {item.title}
                <br />
              </span>
            );
          })}
        </p>
        <div className="order_info">
          <div className="box_info">
            <img src={iconsImg.Calendar} alt="calendar" />
            {getDateTime(card?.start)}
          </div>
          <div className="box_info">
            <img src={iconsImg.LocationGreen} alt="location" />
            {card.address.city},{card.address.street},{card.address.hous}
          </div>
        </div>
        <div className="price">
          {Math.ceil(card.price * 0.9)}
          {t('gazda_page.main_component.price.sub_2')}
        </div>
      </div>
    );
  }
  function emptyOrders() {
    return (
      <div className="empty">
        <img src={iconsImg.BadSmail} alt="BadSmail" />
        <p>{t('personal_account_page.order.empty.title')}</p>
        <span>{t('personal_account_page.order.empty.sub_title')}</span>
        <Link to="/order_board">
          <ButtomCustom
            isValid={true}
            dirty={true}
            text={t('personal_account_page.order.empty.bt')}
          />
        </Link>
      </div>
    );
  }
  return (
    <>
      <div className="order">
        <h3>{t('personal_account_page.contoll_panel.orders')}</h3>
        <div className="hadle">
          <div className={!isCompleted ? 'box active' : 'box'}>
            <span
              onClick={() => {
                setCompleted(false);
                getList(false);
              }}
            >
              {t('personal_account_page.order.in_work')}
            </span>
          </div>
          <div className={isCompleted ? 'box active' : 'box'}>
            <span
              onClick={() => {
                setCompleted(true);
                getList(true);
              }}
            >
              {t('personal_account_page.order.сompleted')}
            </span>
          </div>
        </div>
        <div className="orders">
          <div className="separator" />
          {ordersProces?.length > 0
            ? ordersProces.map((card, index) => {
                return getCard(card, index);
              })
            : emptyOrders()}
        </div>
        {ordersProces?.length > 0 && (
          <Pagination
            count={pageCount ? pageCount : 1}
            size="large"
            page={page ? page : 1}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
          />
        )}
      </div>
      <PersonalAccountModal
        handleModal={() => handleModal(!isOpen)}
        isOpen={isOpen}
        card={selectCard}
        getListsApi={getList}
      />
      <CompletedModal
        handleModal={() => handleModalCompleted(!isOpenCompleted)}
        isOpen={isOpenCompleted}
        card={selectCard}
      />
      <Load isLoading={isLoading} />
    </>
  );
};

export default MyOrder;
