import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MainComponentService from '../../../components/subServices/mainComponent/MainComponentService';
import OurProcess from '../../../components/gazda/ourProcess/OurProcess';
import ChooseUs from '../../../components/gazda/chooseUs/ChooseUs';
import CallBackService from '../../../components/subServices/steps/CallBackService';
import OrderGazda from '../../../components/gazda/orderGazda/OrderGazda';
import ServiceModal from '../../../components/modals/serviceModal/ServiceModal';
import { useParams } from 'react-router-dom';
import { getDataService } from '../../../helpers';

interface IDataService {
  title: string;
  description: string;
  parent: string;
  imgService: string;
}

const Plumbing = () => {
  const [isModal, setModal] = useState(false);
  const [isOrder, setOrder] = useState(false);
  const [price, setPrice] = useState(0);
  const [dataServicePage, setDataServicePage] = useState<IDataService>();
  const [serviceItem, setServiceItem] = useState('');
  const { t } = useTranslation();
  const { service } = useParams();

  useEffect(() => {
    service && setDataServicePage(getDataService(service));
    service && setServiceItem(service);
    const test = service ? getDataService(service) : '';
    // debugger;
  }, []);

  const arrayLinks = [
    { name: t('main'), link: '/' },
    { name: t('engineering'), link: '/engineering' },
    { name: t(`engineering_page.${serviceItem}`), link: `/${serviceItem}` },
  ];

  return (
    <div className="engineering_container">
      {isOrder && <OrderGazda count={price} />}
      <MainComponentService
        imgService={dataServicePage?.imgService ? t(dataServicePage?.imgService) : ''}
        subTitle={dataServicePage?.description ? t(dataServicePage?.description) : ''}
        title={dataServicePage?.title ? t(dataServicePage?.title) : ''}
        parent={dataServicePage?.parent ? t(dataServicePage?.parent) : ''}
        arrayLinks={arrayLinks}
        openModal={() => {
          setModal(!isModal);
        }}
      />
      <OurProcess />
      <ChooseUs />
      <CallBackService />
      <ServiceModal
        handleModal={() => setModal(!isModal)}
        isOpen={isModal}
        price={price}
        title={dataServicePage?.title ? t(dataServicePage?.title) : ''}
        setOrder={() => setOrder(!isOrder)}
      />
    </div>
  );
};

export default Plumbing;
