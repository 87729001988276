import React from 'react';
import { Link } from 'react-router-dom';
import AboutComponent from '../../components/about/about/AboutComponent';
import BreadСrumbs from '../../components/breadСrumbs/BreadСrumbs';
import { useTranslation } from 'react-i18next';
import './AboutStyle.scss';
import StepComponent from '../../components/about/step/StepComponent';
import StepComfort from '../../components/about/stepComfort/StepComfort';
import CallBack from '../../components/about/callBack/CallBack';

const About = () => {
  const { t } = useTranslation();

  const arrayLinks = [
    { name: t('main'), link: '/' },
    { name: t('footer.about_the_company'), link: '/about' },
  ];
  return (
    <div className="about_container">
      <BreadСrumbs arrayLinks={arrayLinks} />
      <AboutComponent />
      <StepComponent />
      <StepComfort />
      <CallBack />
    </div>
  );
};

export default About;
