import User from './user.svg';
import UserSelect from './user_select.svg';
import Briefcase from './briefcase.svg';
import BriefcaseSelect from './briefcase_select.svg';
import LogOut from './log_out.svg';
import LogOutSelect from './log_out_select.svg';

export const personalAccountImg = {
  User,
  UserSelect,
  Briefcase,
  BriefcaseSelect,
  LogOut,
  LogOutSelect,
};
