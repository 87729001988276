import People from './people.svg';
import Checked from './checked.svg';
import Files from './files.svg';
import Places from './places.svg';

export const ourAdvantagesImgs = {
  People,
  Places,
  Checked,
  Files,
};
