import Home from './home.jpg';
import People from './people.jpg';
import HomeMobile from './home_mobile.jpg';
import PeopleMobile from './people_mobile.jpg';
import Logo from './logo.svg';

export const aboutPageImgs = {
  Home,
  Logo,
  People,
  HomeMobile,
  PeopleMobile,
};
