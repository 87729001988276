import React, { useState } from 'react';
import './AuthorizationStyle.scss';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { iconsImg } from '../../assets/imgs/icons';
import { Link } from 'react-router-dom';
import ButtomCustom from '../../components/customs/buttomCustom/ButtonCustom';
import WrapperAutorization from '../../components/wrapperAutorization/WrapperAutorization';
import { loginApi } from '../../api';
import { useAction } from '../../hooks/useAction';

const SignupSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string()
    .required('No password provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
});

const AuthorizationForm = () => {
  const { t } = useTranslation();
  const [isShowPassword, handlShowPassword] = useState(false);
  const [changeSign, setSign] = useState('client');
  const { setLogin } = useAction();
  const [errorApi, handleError] = useState('');

  return (
    <WrapperAutorization>
      <div className="box_card">
        <p>{t('authorization.title')}</p>
        <div className="form_box">
          <div className="select_box">
            <div className="wrapper_select">
              <label
                className={changeSign === 'client' ? 'active' : ''}
                onClick={() => setSign('client')}
              >
                {t('authorization.client')}
              </label>
            </div>
            <div className="wrapper_select">
              <label
                className={changeSign !== 'client' ? 'active' : ''}
                onClick={() => setSign('worker')}
              >
                {t('authorization.worker')}
              </label>
            </div>
          </div>
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={SignupSchema}
            onSubmit={(values) => {
              loginApi({ username: values.email, password: values.password })
                .then(({ data }) => {
                  setLogin(data.token);
                  localStorage.setItem('token', data.token);
                  const redirectUrl = sessionStorage.getItem('last_url')
                    ? sessionStorage.getItem('last_url')!
                    : '/';
                  window.location.replace(redirectUrl);
                })
                .catch((error) => {
                  handleError('Невірно введені дані');
                  console.log(error);
                  // debugger;
                });
            }}
          >
            {({ errors, touched, isValid, dirty }) => (
              <Form>
                <div className="box_input">
                  {/* <div>
                    <input type="file" name="file" id="" multiple />
                  </div> */}
                  <Field
                    className={errors.email && touched.email ? 'error_input' : ''}
                    name="email"
                    type="text"
                    placeholder="Email"
                  />
                  {/* {errors.email && touched.email ? (
                    <div className="error">
                      <img src={iconsImg.Error} alt="error" />
                      {errors.email}
                    </div>
                  ) : null} */}
                </div>
                <div className="box_input">
                  <Field
                    name="password"
                    type={isShowPassword ? 'text' : 'password'}
                    className={errors.password && touched.password ? 'error_input' : ''}
                    placeholder="Password"
                    autocomplete="off"
                  />
                  <img
                    src={!isShowPassword ? iconsImg.ShowPassword : iconsImg.NotShowPassword}
                    alt="show_password"
                    onClick={() => handlShowPassword(!isShowPassword)}
                  />

                  {/* {errors.password && touched.password ? (
                    <div className="error">
                      {' '}
                      <img src={iconsImg.Error} alt="error" />
                      {errors.password}
                    </div>
                  ) : null} */}
                </div>
                <Link to="/forgot">{t('authorization.remember')}</Link>
                {errorApi && <span className="error">{errorApi}</span>}

                <label>
                  {t('authorization.is_register')}
                  <Link to="/registration-choice">{t('authorization.registration')}</Link>
                </label>

                <ButtomCustom isValid={isValid} dirty={dirty} text={t('sign_in')} />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </WrapperAutorization>
  );
};
export default AuthorizationForm;
