import React, { useState } from 'react';
import './ForgotStyle.scss';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { iconsImg } from '../../assets/imgs/icons';
import ButtomCustom from '../../components/customs/buttomCustom/ButtonCustom';
import { Link } from 'react-router-dom';
import WrapperAutorization from '../../components/wrapperAutorization/WrapperAutorization';
import { sendResetApi } from '../../api';

const SignupSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

const Forgot = () => {
  const { t } = useTranslation();
  const [isNextStep, setNextStap] = useState(false);
  const [emailState, setEmailState] = useState('');

  function resetPassword() {
    return (
      <div className="forgot_container">
        <div className="box_card wid">
          <p>{t('forgot.reset_password.title')}</p>
          <div className="box_text">
            <label className="sub_title">{t('forgot.reset_password.sub_title.step_1')}</label>
            <label className="sub_title email">{emailState}</label>
            <label className="sub_title">{t('forgot.reset_password.sub_title.step_2')}</label>
          </div>
          <div className="buttom_custom">
            <Link to="/authorization">
              <button type="submit">{t('forgot.back_autorization')}</button>
            </Link>
          </div>
          <div>
            <span
              onClick={() => {
                setNextStap(!isNextStep);
              }}
            >
              {t('forgot.reset_password.resend')}
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <WrapperAutorization>
      {!isNextStep ? (
        <div className="forgot_container">
          <div className="box_card">
            <p>{t('forgot.title')}</p>
            <p className="sub_title">{t('forgot.sub_title')}</p>
            <div className="form_box">
              <Formik
                initialValues={{ email: '' }}
                validationSchema={SignupSchema}
                onSubmit={async (values) => {
                  setNextStap(!isNextStep);
                  sendResetApi(values.email);
                  setEmailState(values.email);
                }}
              >
                {({ errors, touched, isValid, dirty }) => (
                  <Form>
                    <div className="box_input">
                      <Field
                        className={errors.email && touched.email ? 'error_input' : ''}
                        name="email"
                        type="text"
                        placeholder="Email"
                      />
                      {errors.email && touched.email ? (
                        <div className="error">
                          <img src={iconsImg.Error} alt="error" />
                          {errors.email}
                        </div>
                      ) : null}
                    </div>
                    <ButtomCustom isValid={isValid} dirty={dirty} text={t('forgot.next')} />
                    <div id="back_aut">
                      <Link to="/authorization">
                        <img src={iconsImg.ArrowLeftBlack} alt="ArrowLeftBlack" />{' '}
                        {t('forgot.back_autorization')}
                      </Link>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      ) : (
        resetPassword()
      )}
    </WrapperAutorization>
  );
};
export default Forgot;
