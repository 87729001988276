import React from 'react';
import './DoneRegistrationStyle.scss';
import { useTranslation } from 'react-i18next';
import { iconsImg } from '../../../assets/imgs/icons';
import ButtomCustom from '../../customs/buttomCustom/ButtonCustom';
import { IRegistartion } from '../../../types';
import { Link } from 'react-router-dom';

const DoneRegistration = () => {
  const { t } = useTranslation();

  return (
    <div className="done_registration">
      <img src={iconsImg.CheckResetPassword} alt="" />
      <p>{t('registration_worker.done_registration.title')}</p>
      <span>{t('registration_client.done_sub')}</span>

      <div className="links">
        <Link to="/" className="box_back">
          <ButtomCustom
            isValid={true}
            dirty={true}
            text={t('registration_worker.done_registration.back_to_main')}
          />
        </Link>
      </div>
    </div>
  );
};
export default DoneRegistration;
