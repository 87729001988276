import Landscaping from './landscaping.jpg';
import Cleaning from './cleaning.jpg';
import Engineering from './engineering.jpg';
import Houses from './houses.jpg';
import Gazda from './gazda.jpg';
import ArrowLeft from './arrow_left.png';
import ArrowRight from './arrow_right.png';

export const sliderImg = {
  Landscaping,
  Cleaning,
  Engineering,
  Houses,
  Gazda,
  ArrowLeft,
  ArrowRight,
};
