import React, { useState } from 'react';
import './CallComponentStyle.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { iconsImg } from '../../../assets/imgs/icons';

const CallComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="gazda_call_component">
      <p>{t('gazda_page.call_component.title')}</p>
      <div className="box_button">
        <button>
          <img src={iconsImg.Phone} alt="phone" /> +380 73 9 836 837
        </button>
      </div>
    </div>
  );
};

export default CallComponent;
