import React, { useState } from 'react';
import './OrderGazdaStyle.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { iconsImg } from '../../../assets/imgs/icons';

interface IProps {
  count?: number;
}

const OrderGazda = ({ count }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className="order_container">
      <div className="seppartion" />
      <div className="data_order">
        <span className="order_t"> {t('gazda_page.info_component.order.title')} </span>
        <div className="circle" /> <span> 1 {t('gazda_page.info_component.order.service')} </span>
      </div>
      <Link to="/order-gazda" className="kasdecukinas">
        <div className="to_order daskoneoga-ngsecukinas">
          <span> {t('gazda_page.info_component.order.to_order')} </span>
          <img src={iconsImg.ArrowWhiteLong} alt="arrow" />
        </div>
      </Link>
    </div>
  );
};

export default OrderGazda;
