import React, { useState } from 'react';
import './InputCustomStyle.scss';

interface IProps {
  selectLabel: string;
  setItem: any;
  onChange?: any;
  type?: string;
}
const InputCustom = ({ selectLabel, setItem, type = 'number' }: IProps) => {
  return (
    <div className="input_custom">
      <label>{selectLabel}</label>
      <input
        className="select"
        type={type}
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          e.currentTarget.value;
          setItem(e.currentTarget.value);
        }}
      ></input>
    </div>
  );
};

export default InputCustom;
