import React, { useState } from 'react';
import './FooterLinksMobileStyle.scss';
import { iconsImg } from '../../../assets/imgs/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface iSelectHeader {
  link: string;
  name: string;
}

interface IDataSelect {
  title: string;
  isShow: boolean;
  handleShow: any;
  links: iSelectHeader[];
}

const FooterLinksMobile = () => {
  const [isServices, setServices] = useState(false);
  const [isAbout, setAbout] = useState(false);
  const [isContacts, setContacts] = useState(false);
  const { t } = useTranslation();
  return (
    <div className="box_links_fotter">
      <div className="links_mobile">
        <div className="col">
          <div className="title" onClick={() => setServices(!isServices)}>
            <p>{t('footer.our_services')}</p>
            <img className={isServices ? 'rotete' : ''} src={iconsImg.ArrowFooter} alt="arrow" />
          </div>
          {isServices && (
            <div className="links">
              <Link to="/gazda">{t('gazda')}</Link>
              <Link to="/engineering">{t('engineering')}</Link>
              <Link to="/cleaning">{t('cleaning')}</Link>
              <Link to="/landscaping">{t('landscaping')}</Link>
              <Link to="/houses">{t('houses')}</Link>
              {/* <Link to="/actions">{t('actions')}</Link> */}
            </div>
          )}
        </div>
        <div className="separation"></div>
        <div className="col">
          <div className="title" onClick={() => setAbout(!isAbout)}>
            <p>{t('footer.about')}</p>
            <img className={isAbout ? 'rotete' : ''} src={iconsImg.ArrowFooter} alt="arrow" />
          </div>
          {isAbout && (
            <div className="links">
              <Link to="/about">{t('footer.about_the_company')}</Link>
              {/* <Link to="/vacancies">{t('footer.vacancies')}</Link> */}
              <Link to="/service-pay">{t('footer.payment')}</Link>
              {/* <Link to="">{t('footer.invitation_to_tender')}</Link> */}
            </div>
          )}
        </div>
        <div className="separation"></div>
        <div className="col">
          <div className="title" onClick={() => setContacts(!isContacts)}>
            <p>{t('footer.contacts')}</p>
            <img className={isContacts ? 'rotete' : ''} src={iconsImg.ArrowFooter} alt="arrow" />
          </div>
          {isContacts && (
            <div className="links">
              <label>
                <a href="tel:+380739836837">+380 73 9 836 837</a>
              </label>
              <label>
                <a href="tel:+380969836837">+380 96 9 836 837</a>
              </label>
              <label>Stephub.ua@gmail.com</label>
              <label>місто Київ, вул.Сімʼї Хохлових,будинок 8, 04119</label>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FooterLinksMobile;
