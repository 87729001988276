import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { iconsImg } from '../../../../assets/imgs/icons';
import { IOrderAcount } from '../../../../types';
import ButtomCustom from '../../../customs/buttomCustom/ButtonCustom';
import './MyOrderStyle.scss';
import { getListOrdersClientApi } from '../../../../api';
import { getDateTime } from '../../../../helpers';
import CancellationsOrderModal from '../../../modals/сancellationsOrder/CancellationsOrderModal';
import { Link } from 'react-router-dom';
import { Pagination } from '@mui/material';
import CompletedModal from '../../../modals/completedModal/CompletedModal';
import Load from '../../../modals/load/Load';

const MyOrderClient = () => {
  const { t } = useTranslation();
  const [selectSection, handleSection] = useState('active');
  const [isOpenCompleted, handleModalCompleted] = useState(false);
  const [ordersProces, setOrdersProces] = useState([]);
  const [selectCard, setSelectCard] = useState<IOrderAcount>();
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [isOpen, handleModal] = useState(false);
  const [isLoading, handleLoading] = useState<boolean>(false);

  useEffect(() => {
    getListOrder(selectSection);
  }, []);

  function getListOrder(str: string, numberPage?: number) {
    handleLoading(true);
    getListOrdersClientApi(numberPage ? `status=${str}&page=${numberPage}` : `status=${str}`).then(
      ({ data }) => {
        setTotal(data?.totalItemCount);
        setPage(data?.currentPageNumber);
        setPageCount(Math.ceil(data.totalItemCount / data.itemNumberPerPage));
        setOrdersProces(
          data.orders.map((item: any) => {
            return {
              reportImages: item.reportImages.map(
                (item: string) => `https://api.stephub.com.ua/${item}`
              ),
              orderServices: item.orderServices,
              start: item.appointment,
              address: {
                city: item.city.title,
                street: `${item.address},${item.building} `,
                hous: `підїзд ${item.flor}, поверх ${item.section}, квартира ${item.apartment}`,
              },
              price: item.amount,
              inProgress: item.createdAt,
              numberOrder: item.id,
              client: {
                fullName: `${item.firstName} ${item.lastName}`,
                phone: item.phone,
              },
            };
          })
        );
        handleLoading(false);
      }
    );
  }

  const handleChange = (e: any, p: any) => {
    //    getListOrdersAllApi(getAllStrFilter({ ...filter, page: e.target.innerText })).then(
    //   ({ data }) => {
    //     props.setTotal(data?.totalItemCount);
    //     props.setPage(data?.currentPageNumber);
    //     props.setPageCount(Math.ceil(data.totalItemCount / data.itemNumberPerPage));
    //     props.setListOrder(data.orders);
    //   }
    // );
    // props.setPage(p);
    getListOrder(selectSection, e.target.innerText);
  };
  function getCard(card: IOrderAcount, index: number) {
    return (
      <div
        className="card"
        key={`card_${index}`}
        onClick={() => {
          setSelectCard(card);
          selectSection === 'active' && handleModalCompleted(!isOpenCompleted);
          selectSection === 'finish' && handleModal(!isOpen);
        }}
      >
        <div className="separator" />
        <p>
          {card.orderServices.map((item: any, index) => {
            return (
              <span key={`${item.title}_${index}`}>
                {item.title}
                <br />
              </span>
            );
          })}
        </p>
        <div className="order_info">
          <div className="box_info">
            <img src={iconsImg.Calendar} alt="calendar" />
            {getDateTime(card?.start)}
          </div>
          <div className="box_info">
            <img src={iconsImg.LocationGreen} alt="location" />
            {card.address.city},{card.address.street},{card.address.hous}
          </div>
        </div>
        <div className="price">
          {card.price}
          {t('gazda_page.main_component.price.sub_2')}
        </div>
      </div>
    );
  }
  function emptyOrders() {
    return (
      <div className="empty">
        <img src={iconsImg.BadSmail} alt="BadSmail" />
        <p>{t('personal_account_page.order.empty.title')}</p>
        <span>Але Ви можете зробити замовлення в роботу прямо зараз</span>
        <Link to="/gazda">
          <ButtomCustom isValid={true} dirty={true} text={'Зробiть замовлення'} />
        </Link>
      </div>
    );
  }
  return (
    <>
      <div className="order client_order_container">
        <h3>{t('personal_account_page.contoll_panel.orders')}</h3>
        <div className="hadle">
          <div className={selectSection === 'active' ? 'box active' : 'box'}>
            <span
              onClick={() => {
                handleSection('active');
                getListOrder('active');
              }}
            >
              В обробці
            </span>
          </div>
          <div className={selectSection === 'in_process' ? 'box active' : 'box'}>
            <span
              onClick={() => {
                handleSection('in_process');
                getListOrder('in_process');
              }}
            >
              {t('personal_account_page.order.in_work')}
            </span>
          </div>
          <div className={selectSection === 'finish' ? 'box active' : 'box'}>
            <span
              onClick={() => {
                handleSection('finish');
                getListOrder('finish');
              }}
            >
              {t('personal_account_page.order.сompleted')}
            </span>
          </div>
          <div className={selectSection === 'reject' ? 'box active' : 'box'}>
            <span
              onClick={() => {
                handleSection('reject');
                getListOrder('reject');
              }}
            >
              Неактуальний
            </span>
          </div>
        </div>
        <div className="orders">
          <div className="separator" />
          {ordersProces?.length > 0
            ? ordersProces.map((card, index) => {
                return getCard(card, index);
              })
            : emptyOrders()}
        </div>
        {ordersProces?.length > 0 && (
          <Pagination
            count={pageCount ? pageCount : 1}
            size="large"
            page={page ? page : 1}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
          />
        )}
      </div>
      <CancellationsOrderModal
        handleModal={() => handleModalCompleted(!isOpenCompleted)}
        isOpen={isOpenCompleted}
        card={selectCard}
        ubdateList={() => getListOrder(selectSection)}
      />
      <CompletedModal
        handleModal={() => handleModal(!isOpen)}
        isOpen={isOpen}
        card={selectCard}
        isClient={true}
      />
      <Load isLoading={isLoading} />
    </>
  );
};

export default MyOrderClient;
