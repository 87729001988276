import { UserAction, UserActionTypes } from '../../types/users';
import { Dispatch } from 'redux';
import axios from 'axios';

export const fetchUsers = () => {
  return async (dispatch: Dispatch<UserAction>) => {
    try {
      dispatch({ type: UserActionTypes.FETCH_USERS });
      const response = await axios.get('https://.....');
      dispatch({ type: UserActionTypes.FETCH_USERS_SUCCESS, payload: response.data });
    } catch (e) {
      dispatch({
        type: UserActionTypes.FETCH_USERS_ERROR,
        payload: 'error',
      });
    }
  };
};

export const selectLanguage = (language: string) => {
  return (dispatch: Dispatch<UserAction>) => {
    try {
      dispatch({ type: UserActionTypes.FETCH_USERS });
      dispatch({ type: UserActionTypes.SELECT_LANGUAGE, payload: language });
    } catch (e) {
      dispatch({
        type: UserActionTypes.FETCH_USERS_ERROR,
        payload: 'error',
      });
    }
  };
};

export const setCity = (city: string) => {
  return (dispatch: Dispatch<UserAction>) => {
    try {
      dispatch({ type: UserActionTypes.FETCH_USERS });
      dispatch({ type: UserActionTypes.SET_CITY, payload: city });
    } catch (e) {
      dispatch({
        type: UserActionTypes.FETCH_USERS_ERROR,
        payload: 'error',
      });
    }
  };
};

export const setLogin = (token: string) => {
  return (dispatch: Dispatch<UserAction>) => {
    try {
      dispatch({ type: UserActionTypes.FETCH_USERS });
      dispatch({ type: UserActionTypes.SET_LOGIN, payload: token });
    } catch (e) {
      dispatch({
        type: UserActionTypes.FETCH_USERS_ERROR,
        payload: 'error',
      });
    }
  };
};

export const setGazdaHours = (hours: number) => {
  // debugger;
  return (dispatch: Dispatch<UserAction>) => {
    try {
      dispatch({ type: UserActionTypes.FETCH_USERS });
      dispatch({ type: UserActionTypes.GAZDA_HOURS, payload: hours });
    } catch (e) {
      dispatch({
        type: UserActionTypes.FETCH_USERS_ERROR,
        payload: 'error',
      });
    }
  };
};

export const setGazdaPrevOrder = (obj: any) => {
  return (dispatch: Dispatch<UserAction>) => {
    try {
      dispatch({ type: UserActionTypes.FETCH_USERS });
      dispatch({ type: UserActionTypes.GAZDA_PREV_ORDER, payload: obj });
    } catch (e) {
      dispatch({
        type: UserActionTypes.FETCH_USERS_ERROR,
        payload: 'error',
      });
    }
  };
};

export const setOrderFilter = (key: string, str: string) => {
  return (dispatch: Dispatch<UserAction>) => {
    try {
      dispatch({ type: UserActionTypes.FETCH_USERS });
      dispatch({ type: UserActionTypes.SET_ORDER_FILTER, payload: { key, str } });
    } catch (e) {
      dispatch({
        type: UserActionTypes.FETCH_USERS_ERROR,
        payload: 'error',
      });
    }
  };
};
