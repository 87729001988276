import React from 'react';
import { Link } from 'react-router-dom';
import BreadСrumbs from '../../components/breadСrumbs/BreadСrumbs';
import { useTranslation } from 'react-i18next';
import { Ilinks } from '../../types';
import { iconsImg } from '../../assets/imgs/icons';
import { cleaningImgs } from '../../assets/imgs/cleaning';

const Cleaning = () => {
  const { t } = useTranslation();

  const arrayLinks = [
    { name: t('main'), link: '/' },
    { name: t('cleaning'), link: '/cleaning' },
  ];

  const links: Ilinks[] = [
    {
      url: 'apartments',
      img: cleaningImgs.Apartments,
      text: t('header.apartments'),
    },
    {
      url: 'offices',
      img: cleaningImgs.Offices,
      text: t('header.offices'),
    },
    {
      url: 'after_repair',
      img: cleaningImgs.AfterRepair,
      text: t('header.after_repair'),
    },
  ];

  function getLink({ img, text, url }: Ilinks, index: number) {
    return (
      <Link key={`${url}_${index}`} to={url} className="wrapper_link">
        <img className="main_img" src={img} alt={url} />
        <img id="arrowLink" src={iconsImg.Link} alt="link" />
        <p>{text}</p>
      </Link>
    );
  }
  return (
    <div className="engineering_container pd_l_r">
      <BreadСrumbs arrayLinks={arrayLinks} isBlack={true} />
      <p className="title">{t('cleaning')}</p>
      <div className="box_links">
        {links.map((item, index) => {
          return getLink(item, index);
        })}
      </div>
    </div>
  );
};

export default Cleaning;
