import React, { useState, useEffect, useRef } from 'react';
import './SelectPriceStyle.scss';
import ButtomCustom from '../../../customs/buttomCustom/ButtonCustom';
import { Field, Form, Formik } from 'formik';
import { IOrderGazdaLast } from '../../../../types';
import { useTranslation } from 'react-i18next';
import Select, { InputActionMeta } from 'react-select';
import { getCategoriesApi } from '../../../../api';
import { useAction } from '../../../../hooks/useAction';
import { number } from 'yup';
import { units } from '../../../../assets/enums';

interface IProps {
  idCatgory: number | string;
  orderGazda: IOrderGazdaLast;
  handleModal: any;
  setOrder: any;
}

const SelectPriceEdite = ({ idCatgory, orderGazda, setOrder, handleModal }: IProps) => {
  const [categores, setСategory] = useState([]);
  const { t } = useTranslation();
  const [isValidation, handleValid] = useState(false);
  const { setGazdaPrevOrder } = useAction();

  useEffect(() => {
    getCategoriesApi(idCatgory).then(({ data }) => {
      const modifiedData = data.map((item: any) => {
        return {
          services: item.services.map((el: any) => {
            return {
              ...el,
              value: '',
            };
          }),
          title: item.title,
        };
      });
      setСategory(modifiedData);
    });
  }, []);

  function getCount(id: string) {
    categores.filter((item: any) => {
      item.services.filter((el: any) => {
        if (el.id === id) return el.value;
      });
    });
  }

  const handleChange = (event: any) => {
    const { value, id } = event.target;
    if (value.length > 2) return;
    setСategory((prevData) => {
      const updatedData: any = [...prevData];
      updatedData.forEach((element: any) => {
        element.services.forEach((el: any) => {
          if (el.id === parseInt(id)) {
            return (el.value = parseInt(value));
          }
        });
      });
      return updatedData;
    });
  };
  return (
    <div className="select_price">
      <Formik
        initialValues={{}}
        validate={(values) => {
          handleValid(true);
        }}
        onSubmit={async (values: any) => {
          const keys = Object.keys(values);
          const secondStepClient: any = [];
          categores.forEach((item: any) => {
            item.services.forEach((elem: any) => {
              keys.map((element: any) => {
                if (Number.parseInt(element) === elem.id) {
                  if (values[element] === true) {
                    return secondStepClient.push({
                      id: { ...elem, value: elem.value <= 0 ? 1 : elem.value },
                      count: getCount(elem),
                    });
                  }
                }
              });
            });
          });
          setOrder();
          handleModal();
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });

          return setGazdaPrevOrder({
            isMaster: true,
            order: {
              ...orderGazda,
              secondStep: keys,
              secondStepClient: secondStepClient,
            },
          });
        }}
      >
        {({ values }) => (
          <Form>
            <div className="box_info_check">
              {categores.map((item: any, index) => {
                return (
                  <div key={`box_category_${index}`}>
                    <p>{item.title}</p>
                    {item.services.map((element: any, ind: number) => {
                      return (
                        <label className="container_checkbox" key={`categores_${ind}`}>
                          <Field name={`${element.id}`} type="checkbox" />
                          <div className="box_price">
                            <span> {element.title} </span>
                            <div className="right_side">
                              <div className="box_input">
                                <input
                                  id={element.id}
                                  type="number"
                                  min="0"
                                  max="9999"
                                  value={element.value}
                                  placeholder="0"
                                  onChange={(event) => handleChange(event)}
                                />
                                {units[element.unit as keyof typeof units]}
                              </div>
                              {`${element.price} `}
                              {t('gazda_page.main_component.price.sub_2')}
                            </div>
                          </div>
                          <span className="checkmark"></span>
                        </label>
                      );
                    })}
                  </div>
                );
              })}
            </div>
            <div className="links">
              <ButtomCustom
                isValid={isValidation}
                dirty={isValidation}
                text={t('modals.select_city.btn')}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SelectPriceEdite;
