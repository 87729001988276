import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { iconsImg } from '../../../assets/imgs/icons';
import './BreadСrumbsGazdaStyle.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  step: number;
}

const BreadСrumbsGazda = ({ step }: IProps) => {
  const { t } = useTranslation();
  return (
    <div className="bread_crumbs_gazda">
      <div className={step >= 0 ? 'box_step was' : 'box_step'}>
        1.{t('gazda_page.info_component.order.details_order')}
      </div>
      <div className="sepor color" />
      <div className={step >= 1 ? 'box_step was' : 'box_step'}>
        2.{t('registration_worker.title')}
      </div>
      <div className={step >= 1 ? 'sepor color' : 'sepor'} />
      <div className={'box_step'}>{t('gazda_page.info_component.order.has_been_placed')}</div>
    </div>
  );
};
export default BreadСrumbsGazda;
