import React, { useState } from 'react';
import './RegistrFormStyle.scss';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import { iconsImg } from '../../../assets/imgs/icons';
import ButtomCustom from '../../customs/buttomCustom/ButtonCustom';
import MaskedInput from 'react-text-mask';
import { RegistrationClientrSchema } from '../../../assets/Schemes';
import { Link } from 'react-router-dom';
import { IRegistartion } from '../../../types';
import { phoneNumberMask } from '../../../helpers';
import { getInput } from '../../form';
import { registationClientApi } from '../../../api';

const languageOptions = [
  {
    label: 'Сантехнік',
    value: 'plumber',
  },
  {
    label: 'Майстер сервісного обслуговування',
    value: 'masterService',
  },
  {
    label: 'Інженер',
    value: 'engineer',
  },
  {
    label: 'Клінер',
    value: 'cleaner',
  },
  {
    label: 'Ландшафтний дизайнер',
    value: 'landscapeDesigner',
  },
  {
    label: 'Садівник',
    value: 'gardener',
  },
  {
    label: 'Різноробочий',
    value: 'handyman',
  },
];

interface IProps {
  registratin: IRegistartion;
  setRegistration: any;
  setStep: any;
}

const RegistrForm = ({
  registratin: { email, firstName, lastName, password, phone, replayPassword, surname, toggle },
  setStep,
  setRegistration,
}: IProps) => {
  const { t } = useTranslation();
  const [isShowPassword, handlShowPassword] = useState(false);
  const [isShowPasswordTwo, handlShowPasswordTwo] = useState(false);
  return (
    <div className="first_step">
      <p>{t('registration_worker.title')}</p>
      <span>{t('registration_client.sub_title')}</span>
      <div className="form_box">
        <Formik
          initialValues={{
            email,
            phone,
            lastName,
            firstName,
            surname,
            password,
            replayPassword,
            toggle,
          }}
          validationSchema={RegistrationClientrSchema}
          onSubmit={async (values: IRegistartion) => {
            registationClientApi({
              email: values.email,
              firstName: values.firstName,
              lastName: values.lastName,
              secondName: values.surname,
              password: values.password,
              phone: values.phone,
            }).then((data) => {
              // debugger;
            });
            setRegistration({ ...values });
            setStep();
          }}
        >
          {({ errors, touched, isValid, dirty, values, setFieldValue }) => (
            <Form>
              {/* last_name  */}
              {getInput({
                values,
                errors,
                touched,
                name: 'lastName',
                text: t('placeholder.last_name'),
              })}
              {/* firs_name  */}
              {getInput({
                values,
                errors,
                touched,
                name: 'firstName',
                text: t('placeholder.firs_name'),
              })}
              {/* last_name  */}
              {getInput({
                values,
                errors,
                touched,
                name: 'surname',
                text: t('placeholder.surname'),
              })}
              {/* email  */}
              {getInput({
                values,
                errors,
                touched,
                name: 'email',
                text: t('placeholder.email'),
              })}
              {/* phone */}
              <div className={!values.phone ? 'box_input' : 'box_input pd'}>
                <Field
                  name="phone"
                  render={({ field }: any) => (
                    <MaskedInput
                      {...field}
                      mask={phoneNumberMask}
                      id="phone"
                      placeholder={values.phone ? t('placeholder.phone') : '+380(__) ___-____'}
                      type="text"
                      className={errors.phone && touched.phone ? 'error_input' : ''}
                      onChange={(e: any) => {
                        setFieldValue('phone', e.currentTarget.value);
                      }}
                      onFocus={(e: any) => {
                        !values.phone && setFieldValue('phone', '+380(');
                      }}
                    />
                  )}
                />
                {values.phone && <span className="placeholder"> {t('placeholder.phone')}</span>}
                {/* {errors.phone && touched.phone ? (
                  <div className="error">
                    {' '}
                    <img src={iconsImg.Error} alt="error" />
                    {errors.phone}
                  </div>
                ) : null} */}
              </div>
              {/* password */}
              <div className={!values.password ? 'box_input' : 'box_input pd'}>
                <Field
                  name="password"
                  type={isShowPassword ? 'text' : 'password'}
                  className={errors.password && touched.password ? 'error_input' : ''}
                  placeholder={t('placeholder.password')}
                />
                <img
                  src={!isShowPassword ? iconsImg.ShowPassword : iconsImg.NotShowPassword}
                  alt="show_password"
                  onClick={() => handlShowPassword(!isShowPassword)}
                />
                {values.password && (
                  <span className="placeholder"> {t('placeholder.password')}</span>
                )}
                {/* {errors.password && touched.password ? (
                  <div className="error">
                    {' '}
                    <img src={iconsImg.Error} alt="error" />
                    {errors.password}
                  </div>
                ) : null} */}
              </div>
              {/* password two */}
              <div className={!values.replayPassword ? 'box_input' : 'box_input pd'}>
                <Field
                  name="replayPassword"
                  type={isShowPasswordTwo ? 'text' : 'password'}
                  className={errors.replayPassword && touched.replayPassword ? 'error_input' : ''}
                  placeholder={t('placeholder.replay_password')}
                />
                <img
                  src={!isShowPasswordTwo ? iconsImg.ShowPassword : iconsImg.NotShowPassword}
                  alt="show_password"
                  onClick={() => handlShowPasswordTwo(!isShowPasswordTwo)}
                />
                {values.replayPassword && (
                  <span className="placeholder"> {t('placeholder.replay_password')}</span>
                )}
                {/* {errors.replayPassword && touched.replayPassword ? (
                  <div className="error">
                    {' '}
                    <img src={iconsImg.Error} alt="error" />
                    {errors.replayPassword}
                  </div>
                ) : null} */}
              </div>
              <div className="sepparation" />
              {/* checkbox  */}
              <label className="container_checkbox">
                <Field name="toggle" type="checkbox" />
                {t('registration_worker.second_step.sucsses')}
                <span className="checkmark"></span>
              </label>
              <div className="links">
                <Link to="/registration-choice">
                  <img src={iconsImg.ArrowLeftBlack} alt="" /> {t('back')}
                </Link>
                <ButtomCustom
                  isValid={isValid}
                  dirty={dirty}
                  text={t('authorization.registration')}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default RegistrForm;
