import Plumbing from './plumbing.png';
import Electricity from './electricity.png';
import Repair from './repair.png';
import Furniture from './furniture.png';
import Ventilation from './ventilation.png';

export const engineeringImgs = {
  Plumbing,
  Electricity,
  Repair,
  Furniture,
  Ventilation,
};
