import { IUserState, UserAction, UserActionTypes } from '../../types/users';

const initialState: IUserState = {
  users: [],
  filter: {
    price: '',
    diapason: '',
    orderBy: 'date',
    sorts: 'dsc',
    page: '1',
    category: '',
    city: '',
    search: '',
    allStr: ``,
  },
  user: {
    city: '',
    name: '',
    login: localStorage.getItem('token') ? localStorage.getItem('token')! : '',
  },
  order: {
    gazdaHours: 2,
    prevOrder: {
      isMaster: true,
      order: {
        category: '',
        service: '',
        section: '',
      },
      secondStep: [],
      secondStepClient: [],
    },
  },
  loading: false,
  error: null,
};

export const userReducer = (state = initialState, action: UserAction): IUserState => {
  switch (action.type) {
    case UserActionTypes.FETCH_USERS:
      return { ...state, loading: true, error: null, users: [] };
    case UserActionTypes.FETCH_USERS_SUCCESS:
      return { ...state, loading: false, error: null, users: action.payload };
    case UserActionTypes.FETCH_USERS_ERROR:
      return { ...state, loading: true, error: action.payload, users: [] };
    case UserActionTypes.SET_CITY:
      return {
        ...state,
        loading: true,
        error: action.payload,
        user: { ...state.user, city: action.payload },
      };
    case UserActionTypes.SET_LOGIN:
      return {
        ...state,
        loading: true,
        error: action.payload,
        user: { ...state.user, login: action.payload },
      };
    case UserActionTypes.GAZDA_HOURS: {
      return {
        ...state,
        loading: true,
        error: 'not order hours',
        order: { ...state.order, gazdaHours: action.payload },
      };
    }
    case UserActionTypes.GAZDA_PREV_ORDER: {
      return {
        ...state,
        loading: true,
        error: 'not order hours',
        order: { ...state.order, prevOrder: action.payload },
      };
    }
    case UserActionTypes.SET_ORDER_FILTER: {
      return {
        ...state,
        loading: true,
        error: 'not key filter',
        filter: {
          ...state.filter,
          [action.payload.key]: action.payload.str,
          allStr: `price=${state.filter.price}&diapason=${state.filter.diapason}&orderBy=${state.filter.orderBy}&sorts=${state.filter.sorts}`,
        },
      };
    }

    default:
      return state;
  }
};
