import React from 'react';
import { Link } from 'react-router-dom';
import './ServicePayStyle.scss';

const ServicePay = () => {
  return (
    <div className="service_pay_container">
      <h3> Оплата на Сервісі</h3>
      <ul>
        <li>1. За допомогою сервісу LiqPay.</li>
        <li>
          2. Ціна доступу до Сервісу визначається у вигляді процентної ставки залежно від категорії
          Замовлення.
        </li>
        <li>
          2.2. Розрахунок ціни доступу до Сервісу для окремого Замовлення здійснюється шляхом
          множення вартості ціни Замовлення на процентну ставку, передбачену для такої категорії
          послуг (робіт).
        </li>
        <li>2.3. Базова процентна ставка - 10% (десять відсотків) від суми замовлення.</li>
        <li>
          2.4 Повернення внесеного платежу здійснюється на реквізити, які Виконавець/Замовник вказує
          в формі заяви на повернення на адресу електронної пошти stephub.ua@gmail.com. Таку заяву
          буде підтверджено і оброблено Компанією тільки після введення Виконавцем/Замовником
          унікально згенерованого коду, спрямованого йому в SMS-повідомленні на номер, який вказав
          Користувач в Особистому кабінеті. Введення отриманого за допомогою SMS-повідомлення
          унікально згенерованого коду є електронним підписом Користувача.
        </li>
        <li>
          2.5 У разі неможливості надання з вини Компанії з яких-небудь причин Сервісу
          Користувачеві, Компанія зобов&apos;язується повернути Користувачу оплачену їм суму
          Гарантійного платежу на підставі відповідної заяви.
        </li>
        <li>
          2.6 У разі позитивного вирішення питання про повернення Користувачеві грошових коштів
          Компанія здійснює таке повернення Користувачеві протягом 10 (десяти) календарних днів з
          дати заповнення повністю всієї необхідної інформації в заяві на повернення.
        </li>
      </ul>
    </div>
  );
};

export default ServicePay;
