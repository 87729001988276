import React, { useEffect, useState } from 'react';
import './ResetPasswordStyle.scss';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { iconsImg } from '../../assets/imgs/icons';
import ButtomCustom from '../../components/customs/buttomCustom/ButtonCustom';
import { Link, useLocation, useParams } from 'react-router-dom';
import WrapperAutorization from '../../components/wrapperAutorization/WrapperAutorization';
import { getResetConfirmApi, getResetEmailApi } from '../../api';

const SignupSchema = Yup.object().shape({
  password: Yup.string()
    .required('No password provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
});

const ResetPassword = () => {
  const { t } = useTranslation();
  const [emailState, setEmailState] = useState('');
  const [isShowPassword, handlShowPassword] = useState(false);
  const [isNextStep, setNextStap] = useState(false);
  const { token } = useParams();

  useEffect(() => {
    getResetEmailApi(token ? token : '').then(({ data }: any) => {
      setEmailState(data.email);
    });
  }, []);
  function successPassword() {
    return (
      <div className="reset_container">
        <div className="box_card wid">
          <img id="success" src={iconsImg.CheckResetPassword} alt="CheckResetPassword" />
          <p>{t('reset_page.success.title')}</p>
          <div className="box_text">
            <label className="sub_title">{t('reset_page.success.sub_title')}</label>
          </div>
          <div className="buttom_custom back_autorization">
            <Link to="/authorization">
              <button type="submit">{t('reset_page.success.btn')}</button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
  return (
    <WrapperAutorization>
      {!isNextStep ? (
        <div className="reset_container">
          <div className="box_card wid">
            <p>{t('reset_page.title')}</p>
            <div className="box_text">
              <label className="sub_title">{t('reset_page.sub_title')}</label>
              <label className="sub_title email"> {emailState}</label>
            </div>
            <div className="form_box">
              <Formik
                initialValues={{ password: '' }}
                validationSchema={SignupSchema}
                onSubmit={async (values) => {
                  // this sen API
                  // setEmailState(values.password);
                  getResetConfirmApi(token!, values.password);
                  setNextStap(!isNextStep);
                }}
              >
                {({ errors, touched, isValid, dirty }) => (
                  <Form>
                    <div className="box_input">
                      <Field
                        name="password"
                        type={isShowPassword ? 'text' : 'password'}
                        className={errors.password && touched.password ? 'error_input' : ''}
                        placeholder="Password"
                        autocomplete="off"
                      />
                      <img
                        src={!isShowPassword ? iconsImg.ShowPassword : iconsImg.NotShowPassword}
                        alt="show_password"
                        onClick={() => handlShowPassword(!isShowPassword)}
                      />
                      {errors.password && touched.password ? (
                        <div className="error">
                          {' '}
                          <img src={iconsImg.Error} alt="error" />
                          {errors.password}
                        </div>
                      ) : null}
                    </div>
                    <ButtomCustom isValid={isValid} dirty={dirty} text={t('forgot.next')} />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      ) : (
        successPassword()
      )}
    </WrapperAutorization>
  );
};
export default ResetPassword;
