import Resizer from 'react-image-file-resizer';
import { subServicesImg } from '../assets/imgs/sub_services';

export function isEmpty(obj: object) {
  // eslint-disable-next-line
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) return false;
    return JSON.stringify(obj) === JSON.stringify({});
  }
}

export const phoneNumberMask = [
  '+',
  '3',
  '8',
  '0',
  '(',
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export function setVisible(path: string, setVisibleUrl: any) {
  if (path === '/registration-choice') return setVisibleUrl('');
  if (
    path === '/authorization' ||
    path === '/forgot' ||
    path === '/register-worker' ||
    path === '/register-client'
  ) {
    return setVisibleUrl('empty');
  } else {
    return setVisibleUrl('main');
  }
}
export function blobToBase64(blob: any) {
  return new Promise((resolve, reject) => {
    const reader: any = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result.split(',')[1];
      resolve(base64String);
    };

    reader.onerror = () => {
      reject(new Error('Error reading blob as Base64'));
    };

    reader.readAsDataURL(blob);
  });
}

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export function base64toFile(base64String: any, filename: any) {
  // debugger;
  const byteCharacters = atob(base64String);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: '' }); // Replace '' with the desired MIME type of the file
  const file = new File([blob], filename);

  return file;
}
export const chooseTimeArray = [
  '9:00',
  '9:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
];

export const getAllStrFilter = (filter: any) => {
  if (
    !filter.price &&
    !filter.diapason &&
    !filter.diapason &&
    !filter.orderBy &&
    !filter.sorts &&
    !filter.city &&
    !filter.category &&
    !filter.search
  ) {
    return '';
  } else {
    return `price=${filter.price}&diapason=${filter.diapason}&orderBy=${filter.orderBy}&sorts=${filter.sorts}&city=${filter.city}&category=${filter.category}&page=${filter.page}&search=${filter.search}`;
  }
};

export const getDateTime = (date: any) => {
  return `
  ${('0' + new Date(date).getDate()).slice(-2)}.
  ${('0' + (new Date(date).getMonth() + 1)).slice(-2)}.
  ${new Date(date).getFullYear()}    
      ${'  -  '}
      ${new Date(date).getHours()}:${
    (new Date(date).getMinutes() < 10 ? '0' : '') + new Date(date).getMinutes()
  }`;
};

export const resizeFile = (file: File, height = 100, width = 100, size = 100): any =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      height,
      width,
      file.type.slice(file.type.indexOf('/') + 1),
      70,
      0,
      (uri) => {
        resolve(uri);
      },
      'base64'
    );
  });

export const getDataService = (key: string) => {
  const objectsByKey: any = {
    // houses
    rooms: {
      title: 'header.rooms',
      description: 'houses_page.rooms_page.sub_title',
      parent: 'houses',
      imgService: subServicesImg.Rooms,
    },
    territory: {
      title: 'header.territory',
      description: 'houses_page.territory_page.sub_title',
      parent: 'houses',
      imgService: subServicesImg.Territory,
    },
    // landscaping
    landscaping: {
      title: 'header.landscaping',
      description: 'landscaping_page.landscaping_page.sub_title',
      parent: 'landscaping',
      imgService: subServicesImg.Landscaping,
    },
    lawn: {
      title: 'header.lawn',
      description: 'landscaping_page.lawn_page.sub_title',
      parent: 'landscaping',
      imgService: subServicesImg.Lawn,
    },
    flowers: {
      title: 'header.flowers',
      description: 'landscaping_page.flowers_page.sub_title',
      parent: 'landscaping',
      imgService: subServicesImg.Flowers,
    },
    facade: {
      title: 'header.facade',
      description: 'landscaping_page.facade_page.sub_title',
      parent: 'landscaping',
      imgService: subServicesImg.Facade,
    },
    // engineering
    plumbing: {
      title: 'header.plumbing_equipment',
      description: 'engineering_page.plumbing_page.sub_title',
      parent: 'engineering',
      imgService: subServicesImg.Plumbing,
    },
    electricity: {
      title: 'header.electricity',
      description: 'engineering_page.engineering_page.sub_title',
      parent: 'engineering',
      imgService: subServicesImg.Electrician,
    },
    repair: {
      title: 'header.repair',
      description: 'engineering_page.repair_page.sub_title',
      parent: 'engineering',
      imgService: subServicesImg.Repair,
    },
    furniture: {
      title: 'engineering_page.furniture',
      description: 'engineering_page.furniture_page.sub_title',
      parent: 'engineering',
      imgService: subServicesImg.Furniture,
    },
    ventilation: {
      title: 'engineering_page.ventilation',
      description: 'engineering_page.ventilation_page.sub_title',
      parent: 'engineering',
      imgService: subServicesImg.Furniture,
    },
    // cleaning
    apartments: {
      title: 'header.apartments',
      description: 'cleaning_page.apartments_page.sub_title',
      parent: 'cleaning',
      imgService: subServicesImg.Apartments,
    },
    offices: {
      title: 'header.offices',
      description: 'cleaning_page.offices_page.sub_title',
      parent: 'cleaning',
      imgService: subServicesImg.Offices,
    },
    after_repair: {
      title: 'header.after_repair',
      description: 'cleaning_page.after_repair_page.sub_title',
      parent: 'cleaning',
      imgService: subServicesImg.AfterRepair,
    },
  };
  return objectsByKey[key] || null;
};
