import React from 'react';
import './MainAboutStyle.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { aboutMainImg } from '../../../assets/imgs/main_about';
import { aboutMainMobileImg } from '../../../assets/imgs/main_about/mobile';

interface Ilinks {
  url: string;
  img: string;
  text: string;
}

const MainAbout = () => {
  const { t } = useTranslation();
  return (
    <div className="main_about">
      {/* desktop imgs */}
      <img id="LeftB" src={aboutMainImg.LeftB} alt="LeftB" />
      <img id="LeftT" src={aboutMainImg.LeftT} alt="LeftT" />
      <img id="RightB" src={aboutMainImg.RightB} alt="RightB" />
      <img id="RightM" src={aboutMainImg.RightM} alt="RightM" />
      <img id="RightT" src={aboutMainImg.RightT} alt="RightT" />
      {/* mobile imgs */}
      <img id="LeftBM" src={aboutMainMobileImg.LeftB} alt="LeftB" />
      <img id="LeftTM" src={aboutMainMobileImg.LeftT} alt="LeftT" />
      <img id="RightBM" src={aboutMainMobileImg.RightB} alt="RightB" />
      <img id="RightTM" src={aboutMainMobileImg.RightT} alt="RightT" />
      <p className="name_page">{t('maim_page.main_about.name_page')}</p>
      <h3>{t('maim_page.main_about.title')}</h3>
      <label>{t('maim_page.main_about.sub_title')}</label>
      <Link to="/about">{t('maim_page.main_about.link')}</Link>
    </div>
  );
};

export default MainAbout;
