import React, { useState, useRef, useEffect } from 'react';
import CustomModal from '../customModal/CustomModal';
import { useTranslation } from 'react-i18next';
import { iconsImg } from '../../../assets/imgs/icons';
import { Link, useNavigate } from 'react-router-dom';
import './PayModal.scss';
import ButtomCustom from '../../customs/buttomCustom/ButtonCustom';
import { getOrderPayApi, getUserInfo } from '../../../api';
import ViewImg from '../../customs/viewImg/ViewImg';
interface IProps {
  handleModal: any;
  isOpen: boolean;
  card?: any;
  getList: any;
}

const PayModal = React.memo(({ handleModal, isOpen, card, getList }: IProps) => {
  console.log('card', card);
  const { t } = useTranslation();
  const [isValid, handleValid] = useState(false);
  const [isPay, handlePay] = useState(false);
  const [dataOrder, handleDataOrder] = useState('');
  const [signatureOrder, handleSignaturerder] = useState('');
  const [listImgs, setListImgs] = useState([]);
  const navigate = useNavigate();
  const formRefPay = useRef(null);

  const handleModalDufault = () => {
    handleValid(false);
    handleModal();
  };
  useEffect(() => {
    getUserInfo().then(({ data }) => {
      handlePay(data.isPay);
    });
    setListImgs(
      card?.orderFiles
        ? card?.orderFiles.map((item: any) => {
            return `https://api.stephub.com.ua/${item.path}`;
          })
        : []
    );
  }, [card]);
  return (
    <CustomModal handleModal={handleModalDufault} isOpen={isOpen}>
      <div className="modal_personal_account pay_modal">
        <div className="bread" onClick={handleModalDufault}>
          <img src={iconsImg.ArrowFooter} alt="ArrowLeftBlack" /> <span>{t('back')}</span>
        </div>
        <div className="form">
          {card?.orderServices.map((item: any, index: number) => {
            return (
              <div key={`${index}_${item.title}`}>
                <p>{item.service?.typeUkr}</p>
                <p>{item.title}</p>
                <span>
                  {item.sumPrice * 0.9}
                  {t('gazda_page.main_component.price.sub_2')}.
                </span>
              </div>
            );
          })}
          <div className="in_progress">
            <img src={iconsImg.Clock} alt="clock" />
            <span>
              <span>Опубліковано: </span>
              {('0' + new Date(card?.createdAt).getDate()).slice(-2)}.
              {('0' + (new Date(card?.createdAt).getMonth() + 1)).slice(-2)}.
              {new Date(card?.createdAt).getFullYear()}
            </span>
          </div>
          {card?.isUrgent && (
            <div className="urgent">
              <img src={iconsImg.Urgent} alt="urgent" />
              {t('personal_account_page.modal.urgent_order')}
            </div>
          )}
          <div className="sepparetion" />
          <div className="box_local">
            <>
              <span>
                {' '}
                <img src={iconsImg.Calendar} alt="calendar" />
                {('0' + new Date(card?.appointment).getDate()).slice(-2)}.
                {('0' + (new Date(card?.appointment).getMonth() + 1)).slice(-2)}.
                {new Date(card?.appointment).getFullYear()}
                {'  -  '}
                {new Date(card?.appointment).getHours()}:
                {(new Date(card?.appointment).getMinutes() < 10 ? '0' : '') +
                  new Date(card?.appointment).getMinutes()}
              </span>
            </>
            <>
              <span>
                <img src={iconsImg.LocationGray} alt="LocationGray" />
                {card?.city.title}, {card?.address}
              </span>
            </>
          </div>
          {card?.comment && (
            <>
              <div className="sepparetion" />

              <div className="box_comment_input">
                <p>{t('gazda_page.info_component.order.comment')}:</p>
                <span>{card.comment}</span>
              </div>
            </>
          )}
          {card?.orderFiles && (
            <>
              <div className="sepparetion" />
              <ViewImg imgList={listImgs} />
            </>
          )}
          <div className="sepparetion" />
          <ButtomCustom
            dirty={isValid}
            isValid={isValid}
            text="Взяти замовлення"
            onClick={() => {
              return getOrderPayApi(card?.id).then(({ data }) => {
                if (!isPay) {
                  return navigate('/personal-account');
                }
                handleDataOrder(data.form.data);
                handleSignaturerder(data.form.signature);
                const form: any = formRefPay.current;
                if (form) {
                  setTimeout(() => {
                    document.body.appendChild(form);
                    form.submit();
                  }, 100);
                }
              });
            }}
          />
          <div className="box_valid">
            <div className="container_checkbox">
              <label>
                <input
                  type="checkbox"
                  onClick={(e) => {
                    handleValid(!isValid);
                    e.stopPropagation();
                  }}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="box_links">
              <p>{t('docs.accses')}</p>
              <div>
                <Link to="/terms" target="_blank">
                  {t('docs.offer')}
                </Link>
                <span>{t('docs.company')}</span>
              </div>
            </div>
          </div>
        </div>
        <form
          ref={formRefPay}
          method="POST"
          action="https://www.liqpay.ua/api/3/checkout"
          acceptCharset="utf-8"
        >
          <input type="hidden" name="data" value={dataOrder} />
          <input type="hidden" name="signature" value={signatureOrder} />
          <input type="hidden" name="result_url" value="https://stephub.com.ua/order-gazda" />
        </form>
      </div>
    </CustomModal>
  );
});
PayModal.displayName = 'MyComponent';
export default PayModal;
