import React from 'react';
import './DoneRegistrationStyle.scss';
import { useTranslation } from 'react-i18next';
import { iconsImg } from '../../../assets/imgs/icons';
import ButtomCustom from '../../customs/buttomCustom/ButtonCustom';
import { IRegistartion } from '../../../types';
import { Link } from 'react-router-dom';

const DoneRegistration = () => {
  const { t } = useTranslation();

  return (
    <div className="done_registration">
      <img src={iconsImg.CheckResetPassword} alt="" />
      {/* <p>{t('registration_worker.done_registration.title')}</p> */}
      <span>
        Щоб завершити реєстрацію, перейдіть за посиланням, надісланим на Вашу електронну адресу
      </span>

      <div className="links">
        <Link to="/order_board" className="box_back">
          <ButtomCustom
            isValid={true}
            dirty={true}
            text={t('registration_worker.done_registration.bt')}
          />
        </Link>
        <Link to="/">
          {t('registration_worker.done_registration.back_to_main')}
          <img src={iconsImg.ArrowLeftBlack} alt="arrow" />{' '}
        </Link>
      </div>
    </div>
  );
};
export default DoneRegistration;
