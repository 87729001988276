import React from 'react';
import './OurServicesStyle.scss';
import { useTranslation } from 'react-i18next';
import { iconsImg } from '../../../assets/imgs/icons';
import { ourServicesImgs } from '../../../assets/imgs/our_services';
import { Link } from 'react-router-dom';
import { Ilinks } from '../../../types';

const OurServices = () => {
  const { t } = useTranslation();

  const links: Ilinks[] = [
    {
      url: '/gazda',
      img: ourServicesImgs.Gazda,
      text: t('gazda'),
    },
    {
      url: '/engineering',
      img: ourServicesImgs.Engineering,
      text: t('engineering'),
    },
    {
      url: '/cleaning',
      img: ourServicesImgs.Cleaning,
      text: t('cleaning'),
    },
    {
      url: '/landscaping',
      img: ourServicesImgs.Landscaping,
      text: t('landscaping'),
    },
    {
      url: '/houses',
      img: ourServicesImgs.Houses,
      text: t('houses'),
    },
  ];

  function getLink({ img, text, url }: Ilinks, index: number) {
    return (
      <Link key={`${url}_${index}`} to={url} className="wrapper_link">
        <img className="main_img" src={img} alt={url} />
        <img id="arrowLink" src={iconsImg.Link} alt="link" />
        <p>{text}</p>
      </Link>
    );
  }
  return (
    <div className="our_services">
      <p className="name_page">{t('footer.our_services')}</p>
      <div className="box_info">
        <p>{t('maim_page.our_services.title')}</p>{' '}
        <label>{t('maim_page.our_services.sub_title')}</label>{' '}
      </div>
      <div className="box_links">
        {links.map((item, index) => {
          return getLink(item, index);
        })}
      </div>
    </div>
  );
};

export default OurServices;
