import React, { useEffect, useState } from 'react';
import { useLocation, useParams, Route, Routes, useNavigate } from 'react-router-dom';
import './App.scss';
import MainPage from './pages/main/MainPage';
import Header from './components/header';
import Footer from './components/footer/Footer';
import { useAction } from './hooks/useAction';
import i18n from './i18n/i18n';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Forgot from './pages/forgot/Forgot';
import ResetPassword from './pages/resetPassword/ResetPassword';
import AuthorizationForm from './pages/authorization/Authorization';
import RegistrationСhoice from './pages/registrationСhoice/RegistrationСhoice';
import GazdaPage from './pages/gazda/GazdaPage';
import RegistrationWorker from './pages/registrationWorker/RegistrationWorker';
import RegistrationClient from './pages/registrationClient/RegistrationClient';
import PersonalAccount from './pages/personalAccount/PersonalAccount';
import GazdaOrderPage from './pages/gazdaOrder/GazdaOrderPage';
import 'react-datepicker/dist/react-datepicker.css';
import Terms from './pages/terms/Terms';
import ConfirmEmail from './pages/confirmEmail/ConfirmEmail';
import OrdersBoard from './pages/ordersBoard/OrdersBoard';
import About from './pages/about/About';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy';
import ServicePay from './pages/servicePay/ServicePay';
import Empty from './pages/empty/Empty';
import Engineering from './pages/engineering/Engineering';
import Plumbing from './pages/engineering/plumbing/Plumbing';
import Houses from './pages/houses/Houses';
import HousesDetail from './pages/houses/housesDetail/HousesDetail';
import Landscaping from './pages/landscaping/Landscaping';
import LandscapingDetail from './pages/landscaping/landscapingDetail/LandscapingDetail';
import Cleaning from './pages/cleaning/Сleaning';
import CleaningDetails from './pages/cleaning/cleaningDetails/СleaningDetails';

function App() {
  const { selectLanguage } = useAction();
  const navigate = useNavigate();
  // const location = useLocation();
  const { id } = useParams();
  console.log('navigate', id);

  const nonHeader = [
    '/authorization',
    '/registration-choice',
    '/register-worker',
    '/register-client',
  ];
  const [isHeader, handleHeader] = useState(true);
  const preloadLanguage = (lang: string) => {
    selectLanguage(lang);
    i18n.changeLanguage(lang);
  };
  const changeLanguage = (lang: string) => {
    localStorage.setItem('lang', lang);
    selectLanguage(lang);
    i18n.changeLanguage(lang);
  };
  useEffect(() => {
    const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ua';
    localStorage.setItem('lang', lang!);
    preloadLanguage(lang!);
  }, []);
  useEffect(() => {
    const test = window.innerWidth <= 768 && nonHeader.includes(location.pathname);
    window.innerWidth <= 768 && handleHeader(nonHeader.includes(location.pathname) ? true : false);
  }, [location.pathname]);

  useEffect(() => {
    console.log('location.pathname', location.pathname);
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <div className="App" style={{ paddingTop: isHeader ? 0 : '72px' }}>
      <Header changeLanguage={changeLanguage} />
      <Routes>
        <Route path={`/`} element={<MainPage />} />
        <Route path={`/authorization`} element={<AuthorizationForm />} />
        <Route path={`/forgot`} element={<Forgot />} />
        <Route path={`/restore/:token`} element={<ResetPassword />} />
        <Route path={`/confirm-email`} element={<ConfirmEmail />} />
        <Route path={`/registration-choice`} element={<RegistrationСhoice />} />
        <Route path={`/register-worker`} element={<RegistrationWorker />} />
        <Route path={`/register-client`} element={<RegistrationClient />} />
        <Route path={`/gazda`} element={<GazdaPage />} />
        <Route path={`/personal-account`} element={<PersonalAccount />} />
        <Route path={`/order-gazda`} element={<GazdaOrderPage />} />
        <Route path={`/terms`} element={<Terms />} />
        <Route path={`/order_board`} element={<OrdersBoard />} />
        <Route path={`/about`} element={<About />} />
        <Route path={`/privacy-policy`} element={<PrivacyPolicy />} />
        <Route path={`/service-pay`} element={<ServicePay />} />
        {/* Engineering */}
        <Route path={`/engineering`} element={<Engineering />} />
        <Route path={`/engineering/:service`} element={<Plumbing />} />
        {/* <Route path={`/plumbing`} element={<Plumbing />} />
        <Route path={`/electrician`} element={<Empty />} />
        <Route path={`/repair`} element={<Empty />} /> */}

        <Route path={`/houses`} element={<Houses />} />
        <Route path={`/houses/:service`} element={<HousesDetail />} />
        {/* <Route path={`/rooms`} element={<Empty />} /> */}
        {/* <Route path={`/territory`} element={<Empty />} /> */}

        <Route path={`/landscaping`} element={<Landscaping />} />
        <Route path={`/landscaping/:service`} element={<LandscapingDetail />} />
        {/* <Route path={`/landscaping`} element={<Empty />} />
        <Route path={`/lawn`} element={<Empty />} />
        <Route path={`/flowers`} element={<Empty />} />
        <Route path={`/facade`} element={<Empty />} /> */}

        <Route path={`/cleaning`} element={<Cleaning />} />
        <Route path={`/cleaning/:service`} element={<CleaningDetails />} />
        {/* <Route path={`/apartments`} element={<Empty />} /> */}
        {/* <Route path={`/offices`} element={<Empty />} /> */}
        {/* <Route path={`/after_repair`} element={<Empty />} /> */}

        {/* // заглушки */}
        {/* <Route path={`/actions`} element={<Empty />} /> */}
        {/* <Route path={`/vacancies`} element={<Empty />} /> */}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
