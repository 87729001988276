import React from 'react';
import './CallBackStyle.scss';
import { useTranslation } from 'react-i18next';
import { iconsImg } from '../../../assets/imgs/icons';
import { Link } from 'react-router-dom';

const CallBack = () => {
  const { t } = useTranslation();

  return (
    <div className="callback_container">
      <h3>{t('about_page.callback.title')}</h3>
      <p>
        {t('about_page.callback.sub_title')} &quot;
        <Link to="/gazda">{t('about_page.callback.link')}</Link>&quot;
      </p>
      <div className="box_button">
        {window.innerWidth > 768 ? (
          <button>
            <img src={iconsImg.Phone} alt="phone" /> +380 73 9 836 837
          </button>
        ) : (
          <a href="tel:+380739836837">
            <button className="tel">
              <img src={iconsImg.Phone} alt="phone" /> +380 73 9 836 837
            </button>
          </a>
        )}
        <Link to="/gazda ">
          <button>{t('about_page.callback.link')}</button>
        </Link>
      </div>
    </div>
  );
};

export default CallBack;
