import React, { useState } from 'react';
import './HeaderEmptyStyle.scss';
import Logo from '../../../assets/imgs/logo.svg';
import { Link } from 'react-router-dom';

interface iSelectHeader {
  link: string;
  name: string;
}

interface IProps {
  changeLanguage: any;
}

const HeaderEmpty = () => {
  const [isEngineering, setEngineering] = useState(false);

  return (
    <header className="headerEmpty">
      <Link to="/">
        <img src={Logo} alt="logo" />
      </Link>
      <span>+380 73 9 836 837</span>
    </header>
  );
};

export default HeaderEmpty;
