import Landscaping from './landscaping.jpg';
import Cleaning from './cleaning.jpg';
import Engineering from './engineering.jpg';
import Houses from './houses.jpg';
import Gazda from './gazda.jpg';

export const sliderMobileImg = {
  Landscaping,
  Cleaning,
  Engineering,
  Houses,
  Gazda,
};
