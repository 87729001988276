import React from 'react';
import MainAbout from '../../components/main/about/MainAbout';
import OurAdvantages from '../../components/main/ourAdvantages/OurAdvantages';
import OurProcess from '../../components/main/ourProcess/OurProcess';
import OurServices from '../../components/main/ourServices/OurServices';
import Question from '../../components/main/question/Question';
import MainSlider from '../../components/main/slider/MainSlider';
import SphereServices from '../../components/main/sphereServices/SphereServices';
import './MainStyle.scss';

const MainPage = () => {
  return (
    <div className="main_page">
      <MainSlider />
      <OurServices />
      <SphereServices />
      <OurAdvantages />
      <MainAbout />
      <OurProcess />
      <Question />
    </div>
  );
};

export default MainPage;
