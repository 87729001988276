import React, { useState } from 'react';
import CustomModal from '../customModal/CustomModal';
import { useTranslation } from 'react-i18next';
import { iconsImg } from '../../../assets/imgs/icons';
import { IOrderAcount } from '../../../types';
import ViewImg from '../../customs/viewImg/ViewImg';
import { getDateTime } from '../../../helpers';

interface IProps {
  handleModal: any;
  isOpen: boolean;
  card: IOrderAcount | undefined;
  isClient?: boolean;
}
const CompletedModal = ({ handleModal, isOpen, card, isClient }: IProps) => {
  const { t } = useTranslation();
  return (
    <CustomModal handleModal={handleModal} isOpen={isOpen}>
      <div className="modal_personal_account">
        <div className="bread" onClick={handleModal}>
          <img src={iconsImg.ArrowFooter} alt="ArrowLeftBlack" /> <span>{t('back')}</span>
        </div>
        <div className="form">
          <p>
            {' '}
            {card?.orderServices.map((item: any, index) => {
              return (
                <span key={`${item.title}_${index}`}>
                  {item.title}
                  <br />
                </span>
              );
            })}
          </p>
          <div className="in_progress">
            <img src={iconsImg.Clock} alt="clock" />
            {t('personal_account_page.modal.completed')}
            {getDateTime(card?.inProgress)}
          </div>
          <div className="info">
            <div className="sub_info">
              <img src={iconsImg.Id} alt="Id" />
              <span>
                {t('gazda_page.info_component.order.title')}
                {` №`}
                {card?.numberOrder}
              </span>
            </div>
            <div className="separator" />
            <div className="sub_info">
              <img src={iconsImg.Calendar} alt="Calendar" />
              {card?.start && (
                <span>
                  {t('personal_account_page.modal.start')} -{getDateTime(card?.start)}
                </span>
              )}
            </div>
            <div className="separator" />
            <div className="sub_info as">
              <img src={iconsImg.LocationGray} alt="Location" />
              <>
                <span>
                  <b>{card?.address.city}</b>
                  <br />
                  <span className="c_gray">
                    {card?.address.street}
                    <br />
                    {card?.address.hous}
                  </span>
                </span>
              </>
            </div>
            <div className="separator" />
            <div className="sub_info">
              <img src={iconsImg.UserGray} alt="User" />
              <>
                <span>
                  {card?.client.fullName}
                  <br />
                  <span className="c_gray">{card?.client.phone}</span>
                </span>
              </>
            </div>
            <div className="separator" />
            {card?.price && (
              <div className="sub_info">
                <img src={iconsImg.Card} alt="Card" />
                <div className="price">
                  <span>{t('price')}</span>
                  <span>
                    {isClient ? card.price : Math.ceil(card.price * 0.9)}
                    {t('gazda_page.main_component.price.sub_2')}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="report">
            <img src={iconsImg.Info} alt="Info" />
            <p>{t('personal_account_page.modal.photo_report')}</p>
          </div>
          {card?.reportImages && <ViewImg imgList={card?.reportImages} />}
        </div>
      </div>
    </CustomModal>
  );
};

export default CompletedModal;
