import React, { useState } from 'react';
import './LoadStyle.scss';
import CustomModal from '../customModal/CustomModal';

interface IProps {
  isLoading: boolean;
}
const Load = ({ isLoading }: IProps) => {
  const handleModal = () => {
    return;
  };
  return (
    <CustomModal handleModal={handleModal} isOpen={isLoading}>
      <div className="load_container" onClick={handleModal}>
        <div id="load">
          <div>P</div>
          <div>E</div>
          <div>T</div>
          <div>S</div>
        </div>
      </div>
    </CustomModal>
  );
};

export default Load;
