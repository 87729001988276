import React from 'react';
import './PrivacyPolicyStyle.scss';

const PrivacyPolicy = () => {
  return (
    <div className="terms_container privacy_policy_container">
      <h3>Політика конфіденційності</h3>
      <p>1. ОБЛАСТЬ ЗАСТОСУВАННЯ ТА ЗГОДА</p>
      <ul>
        <li>
          {' '}
          1.1. Персональні дані фізичних осіб - користувачів сервісу ТОВ «СЛУЖБА ТЕХНІЧНОЇ
          ЕКСПЛУАТАЦІЇ ПРИМІЩЕНЬ» ( STEP ), (далі – Компанія),є володільцем Бази персональних даних
          користувачів.
        </li>
        <li>
          {' '}
          1.2. Ця Політика конфіденційності описує встановлений Компанією порядок обробки
          персональних даних, зібраних за допомогою сайту stephub.com.ua (далі – Сайт), і
          пов&apos;язаних з ними послуг і інструментів, що дозволяють користувачам реєструватися на
          Сайті та використовувати сервіс. У всіх зазначених випадках Компанія обробляє персональні
          дані користувачів виключно в рамках вимог Закону України «Про захист персональних даних».
        </li>{' '}
        <li>
          {' '}
          1.3. Використовуючи Сайт, та/або інші пов&apos;язані сервіси, Користувач підтверджує, що з
          метою здійснення Компанією своєї господарської діяльності та пропонування послуг Компанії,
          Користувач надав згоду на обробку Компанією персональних даних (надалі – Персональні
          дані), а саме: • прізвища, власного імені, по батькові; • паспортних даних; • номерів
          контактних телефонів; електронної пошти; • у деяких випадках даних про банківські картки
          Користувача із зазначенням перших та останніх чотирьох цифр, зображених на передній
          (лицьовій) стороні банківської картки (Користувач зобов’язаний закреслити або приховати за
          допомогою будь яких технічних засобів проміжні цифри банківської картки, а також строк її
          дії).{' '}
        </li>{' '}
        <li>
          1.4. Персональні дані, що обробляються Компанією відносяться до конфіденційної інформації.
        </li>
      </ul>
      <p>2. ІНФОРМАЦІЯ, ЯКУ ЗБИРАЄ ТА ОБРОБЛЯЄ КОМПАНІЯ</p>
      <ul>
        <li>
          {' '}
          2.1. Інформація про реєстрацію: при створенні Користувачем облікового запису на Сайті,
          Компанія може вимагати інформацію, перелічену в п. 1.3 цієї Політики.
        </li>
        <li>
          2.2. В процесі роботи Сайту Компанія може збирати інформацію про участь Користувачів у
          маркетингових акціях, організованих на Сайті. В процесі роботи Сайту Компанія може також
          обробляти інформацію пов&apos;язану з ефективністю рекламних кампаній, в тому числі
          перегляди оголошень на Сайті.
        </li>
        <li>
          2.3. Обслуговування клієнтів: при зверненні Користувачів до департаменту підтримки
          клієнтів, Компанія, при необхідності, може в процесі роботи свого Сайту збирати
          інформацію, необхідну для виконання запиту Користувача і отримання зворотного
          зв&apos;язку. Компанія може також зв&apos;язатися з Користувачем, використовуючи існуючу
          контактну інформацію облікового запису, надану для цієї мети. Компанія може також збирати
          іншу інформацію про спілкування з Користувачами, наприклад, будь-які запити до
          департаменту підтримки клієнтів, що подаються Користувачами, або будь-який зворотний
          зв&apos;язок, що надається ними.
        </li>
        <li>
          2.4. Компанія може збирати та зберігати інформацію, що отримана в результаті опитувань,
          які можуть проводитись Компанією, або залученими Компанією підрядниками - третіми особами.
          Інформація, яку Компанія не збирає і не обробляє: Компанія не збирає і не обробляє
          персональні дані про расове або етнічне походження, політичні, релігійні або світоглядні
          переконання, членство в політичних партіях та професійних спілках, та подібну інформацію.
        </li>
      </ul>
      <p>3. КУКІ (COOKIE)</p>
      <ul>
        <li>
          3.1. В процесі роботи Сайту Компанія може збирати певну інформацію за допомогою таких
          технологій як кукі (cookie).
        </li>
        <li>
          3.2. За допомогою кукі (cookie), веб-сервер зберігає вподобання та налаштування на
          комп&apos;ютері Користувача, його мобільному телефоні або іншому пристрої(ях), яке потім
          автоматично відновлюється при наступному відвідуванні Сайту, що робить використання Сайту
          більш зручним. Компанія використовує як постійні, так і сесійні кукі. Постійні кукі
          залишаються на комп&apos;ютері Користувача протягом більш тривалого періоду часу, а
          сесійні кукі автоматично видаляться при закритті вікна браузера.
        </li>
      </ul>
      <p>4. ВИКОРИСТАННЯ ПЕРСОНАЛЬНИХ ДАНИХ </p>
      <ul>
        <li>
          4.1. Для надання своїх послуг Компанія може використовувати інформацію, яку Компанія
          збирає та обробляє для наступних цілей:
          <ul>
            <li>
              4.1.1. забезпечення обслуговування клієнтів, в тому числі для створення і управління
              обліковими записами користувачів, вирішення технічних труднощів і доступу до різних
              функцій;
            </li>
            <li>
              4.1.2. адаптація пропозицій і досвіду, в тому числі реклами на своїх сервісах або
              сервісах третіх осіб;
            </li>
            <li>
              4.1.3. контроль загальної та індивідуальної активності користувачів для управління
              трафіком на Сайті;
            </li>
            <li>
              4.1.4. зв&apos;язок з користувачами, в тому числі з питань сервісу, обслуговування
              клієнтів або дозволених маркетингових комунікацій через будь-які доступні канали
              зв&apos;язку;
            </li>
            <li>
              4.1.5. проведення науково-дослідницької та аналітичної активності з метою поліпшення
              сервісу;
            </li>
            <li>
              {' '}
              4.1.6. забезпечення дотримання Угоди з користувачами сервісу, враховуючи боротьбу з
              шахрайством і образами;
            </li>
            <li> 4.1.7. аналіз та прогнозування особистих вподобань та інтересів.</li>
          </ul>
        </li>
        <li>
          4.2. Компанія може зберігати інформацію, яку вона збирає і отримує на своєму Сайті не
          довше ніж це потрібно для виконання вищевказаних цілей.
        </li>
      </ul>
      <p>5. ОБМІН ІНФОРМАЦІЄЮ </p>
      <ul>
        <li>
          5.1. Компанія може використовувати в якості розпорядників персональних даних третіх осіб
          для надання деяких складових своїх послуг. В таких випадках розпорядники не мають
          повноважень використовувати отримані за допомогою Сайту Компанії персональні дані, інакше
          ніж для надання послуг Компанії.
        </li>
        <li>
          5.2. Компанія може надавати персональні дані Користувачів на запити компетентних державних
          органів, оформлених відповідно до вимог чинного законодавства. 6. КОРИСТУВАЦЬКИЙ КОНТРОЛЬ
        </li>
      </ul>
      <p>6. ДОСТУП КУКІ </p>
      <ul>
        <li>
          6.1. Доступ, виправлення і вилучення: Користувачі, які створили обліковий запис на Сайті,
          можуть отримувати доступ, виправляти або видаляти інформацію, яку вони надають. Користувач
          несе відповідальність за точність наданих даних, або повідомлень на Сайті. Розміщена
          інформація може бути змінена в особистому кабінеті користувача на вебсторінці Сайту.
          Компанія припиняє обробку та видаляє персональні дані Користувача в разі отримання
          письмового повідомлення Користувача про відкликання згоди на обробку персональних даних.
        </li>
        <li>
          6.2. Кукі (сookie): більшість основних комп&apos;ютерних (десктопних) і мобільних
          веббраузерів (наприклад, Safari, Firefox, Internet Explorer, Chrome, Opera) забезпечують
          елементи управління, які дозволяють користувачеві обмежити або заблокувати встановлення
          кукі (сookie). Відключення кукі (сookie) щодо доменів першої категорії (відвідуваних
          сайтів) та інших доменів (сайтів, пов&apos;язаних з відвідуваними) може привести в деяких
          випадках до обмеженої функціональності Сайту.
        </li>
      </ul>
      <p> 7. БЕЗПЕКА</p>
      <ul>
        <li>
          7.1. Вся інформація, яку надає Користувач та збирає Компанія захищена технічними засобами
          і процедурами забезпечення безпеки з метою запобігання несанкціонованому доступу або
          використання даних.
        </li>
        <li>
          7.2. Розпорядники персональних даних зобов&apos;язуються використовувати отриману від
          Компанії інформацію відповідно до вимог безпеки та цієї Політики Конфіденційності.{' '}
        </li>{' '}
      </ul>
      <p> 8. ПРАВА КОРИСТУВАЧІВ </p>
      <ul>
        <li>
          8.1. Користувач має право:
          <ul>
            <li>
              1) знати про джерела збирання, місцезнаходження своїх персональних даних, мету їх
              обробки, місцезнаходження або місце проживання (перебування) володільця чи
              розпорядника персональних даних або дати відповідне доручення щодо отримання цієї
              інформації уповноваженим ним особам, крім випадків, встановлених законом;
            </li>
            <li>
              {' '}
              2) отримувати інформацію про умови надання доступу до персональних даних, зокрема
              інформацію про третіх осіб, яким передаються його персональні дані;
            </li>
            <li>3) на доступ до своїх персональних даних; </li>
            <li>
              4) отримувати не пізніш як за тридцять календарних днів з дня надходження запиту, крім
              випадків, передбачених законом, відповідь про те, чи обробляються його персональні
              дані, а також отримувати зміст таких персональних даних;{' '}
            </li>{' '}
            <li>
              {' '}
              5) пред’являти вмотивовану вимогу володільцю персональних даних із запереченням проти
              обробки своїх персональних даних;
            </li>
            <li>
              {' '}
              6) пред&apos;являти вмотивовану вимогу щодо зміни або знищення своїх персональних
              даних будь-яким володільцем та розпорядником персональних даних, якщо ці дані
              обробляються незаконно чи є недостовірними;{' '}
            </li>
            <li>
              7) на захист своїх персональних даних від незаконної обробки та випадкової втрати,
              знищення, пошкодження у зв&apos;язку з умисним приховуванням, ненаданням чи
              несвоєчасним їх наданням, а також на захист від надання відомостей, що є
              недостовірними чи ганьблять честь, гідність та ділову репутацію фізичної особи;
            </li>{' '}
            <li>
              {' '}
              8) звертатися із скаргами на обробку своїх персональних даних до Уповноваженого
              Верховної Ради України з прав людини або до суду;
            </li>{' '}
            <li>
              {' '}
              9) застосовувати засоби правового захисту в разі порушення законодавства про захист
              персональних даних;
            </li>{' '}
            <li>
              {' '}
              10) вносити застереження стосовно обмеження права на обробку своїх персональних даних
              під час надання згоди;
            </li>
          </ul>
        </li>
      </ul>
      <p>9. ВНЕСЕННЯ ЗМІН ДО ЦІЄЇ ПОЛІТИКИ </p>
      <ul>
        <li>
          9.1. Ця політика конфіденційності була оновлена 15.09.2022р. За необхідності Компанія
          оновлює цю політику конфіденційності.
        </li>
        <li>
          9.2. У разі якщо Компанією були внесені будь-які зміни в Політику Конфіденційності, з
          якими Користувач не згоден, він зобов&apos;язаний припинити використання сервісів Сайту.
          Факт продовження використання Сайту є підтвердженням згоди і прийняття Користувачем
          відповідної редакції Політики Конфіденційності.
        </li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
