import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { personalAccountImg } from '../../../assets/imgs/icons/personalAccount';
import './ContollPanelStyle.scss';
import { Link } from 'react-router-dom';

interface IProps {
  selectName: string;
  handleSelect: any;
  isProfessional: boolean;
}

const ContollPanel = ({ selectName, handleSelect, isProfessional }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className="contoll_panel">
      <div
        className={selectName === 'order' ? 'box_contoll select' : 'box_contoll'}
        onClick={() => handleSelect('order')}
      >
        <img
          src={
            selectName === 'order'
              ? personalAccountImg.BriefcaseSelect
              : personalAccountImg.Briefcase
          }
          alt="briefcase"
        />
        <span>{t('personal_account_page.contoll_panel.orders')}</span>
      </div>
      {isProfessional && (
        <Link to="/order_board" className={'box_contoll'} onClick={() => handleSelect('order')}>
          <img src={personalAccountImg.Briefcase} alt="briefcase" />
          <span>{t('personal_account_page.order.empty.bt')}</span>
        </Link>
      )}
      <div
        className={selectName === 'user' ? 'box_contoll select' : 'box_contoll'}
        onClick={() => handleSelect('user')}
      >
        <img
          src={selectName === 'user' ? personalAccountImg.UserSelect : personalAccountImg.User}
          alt="User"
        />
        <span>{t('registration_worker.title')}</span>
      </div>
      <div
        className={selectName === 'log_out' ? 'box_contoll select' : 'box_contoll'}
        onClick={() => {
          handleSelect('log_out');
          // localStorage.removeItem('token');
          localStorage.clear();
          sessionStorage.clear();
          window.location.replace('/');
        }}
      >
        <img
          src={
            selectName === 'log_out' ? personalAccountImg.LogOutSelect : personalAccountImg.LogOut
          }
          alt="log_out"
        />
        <span>{t('personal_account_page.contoll_panel.log_out')}</span>
      </div>
    </div>
  );
};

export default ContollPanel;
