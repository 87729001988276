import React, { useState } from 'react';
import './RegistrationWorkerStyle.scss';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FirstStep from '../../components/registrationWorker/firstStep/FirtsStep';
import WrapperAutorization from '../../components/wrapperAutorization/WrapperAutorization';
import { IRegistartion } from '../../types';
import SecondStep from '../../components/registrationWorker/secondStep/SecondStep';
import DoneRegistration from '../../components/registrationWorker/doneRegistration/DoneRegistration';

const defaultRegistartion: IRegistartion = {
  email: '',
  phone: '',
  lastName: '',
  firstName: '',
  surname: '',
  password: '',
  replayPassword: '',
  city: '',
  fieldActivity: [],
  avatar: [],
};

const RegistrationWorker = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState('1');
  const [registratin, setRegistration] = useState(defaultRegistartion);
  const [files, setFiles] = useState([]);

  return (
    <div className="registration_worker">
      <WrapperAutorization>
        <div className="box_card">
          {step !== '3' && (
            <span className="step">
              {t('step')}
              {step}/2
            </span>
          )}
          {step === '1' && (
            <FirstStep
              registratin={registratin}
              setRegistration={setRegistration}
              setStep={() => setStep('2')}
            />
          )}
          {step === '2' && (
            <SecondStep
              files={files}
              registratin={registratin}
              setRegistration={setFiles}
              setStep={setStep}
            />
          )}
          {step === '3' && <DoneRegistration />}
        </div>
        {step !== '3' && (
          <div className="box_steps">
            <div className="steps">
              <div className="select_step" />
              <div className={step === '1' ? 'default' : 'select_step'}></div>
            </div>
          </div>
        )}
      </WrapperAutorization>
    </div>
  );
};
export default RegistrationWorker;
