import React, { useState } from 'react';
import './RegistrationClientStyle.scss';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import WrapperAutorization from '../../components/wrapperAutorization/WrapperAutorization';
import { IRegistartion } from '../../types';
import DoneRegistration from '../../components/registrationClient/doneRegistration/DoneRegistration';
import RegistrForm from '../../components/registrationClient/firstStep/RegistrForm';

const defaultRegistartion: IRegistartion = {
  email: '',
  phone: '',
  lastName: '',
  firstName: '',
  surname: '',
  password: '',
  replayPassword: '',
  fieldActivity: [],
  toggle: false,
};

const RegistrationClient = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState('1');
  const [registratin, setRegistration] = useState(defaultRegistartion);

  return (
    <div className="registration_client">
      <WrapperAutorization>
        <div className="box_card">
          {step === '1' && (
            <RegistrForm
              registratin={registratin}
              setRegistration={setRegistration}
              setStep={() => setStep('2')}
            />
          )}

          {step === '2' && <DoneRegistration />}
        </div>
      </WrapperAutorization>
    </div>
  );
};
export default RegistrationClient;
