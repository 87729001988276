import LeftB from './left_bottom.png';
import LeftT from './left_top.png';
import RightB from './right_bottom.png';
import RightT from './right_top.png';

export const aboutMainMobileImg = {
  LeftB,
  LeftT,
  RightB,
  RightT,
};
