import React from 'react';
import './CallBackServiceStyle.scss';
import { useTranslation } from 'react-i18next';
import { iconsImg } from '../../../assets/imgs/icons';

const CallBackService = () => {
  const { t } = useTranslation();

  return (
    <div className="callback_service_container">
      <h3>{t('calback_service.title')}</h3>
      <p>{t('calback_service.sub_title')} </p>
      <div className="box_button">
        {window.innerWidth > 768 ? (
          <button className="tel">
            <img src={iconsImg.Phone} alt="phone" /> +380 73 9 836 837
          </button>
        ) : (
          <a href="tel:+380739836837">
            <button className="tel">
              <img src={iconsImg.Phone} alt="phone" /> +380 73 9 836 837
            </button>
          </a>
        )}
      </div>
    </div>
  );
};

export default CallBackService;
