import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { iconsImg } from '../../../assets/imgs/icons';
import './SecondStepStyle.scss';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import { IOrderGazda, IOrderGazdaSecondPage } from '../../../types';
import { getInputNotError, getRaioGroup } from '../../form';
import MaskedInput from 'react-text-mask';
import { chooseTimeArray, phoneNumberMask, resizeFile, toBase64 } from '../../../helpers';
import { OrderGazdaSchema } from '../../../assets/Schemes';
import { getCities, orderGazda } from '../../../api';
import CustomSelect from '../../customs/customSelectLib/CustomSelectLib';
import { units } from '../../../assets/enums';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import Load from '../../modals/load/Load';

interface IProps {
  orderGazda: IOrderGazda;
  price: number;
  prevOrder: any;
  isPay: boolean;
}

const SecondStep = ({
  prevOrder,
  price,
  orderGazda: {
    address: { boothNumber, entrance, flat, floor, street, city },
    contact,
    email,
    firstName,
    lastName,
    secondName,
    phone,
    typePayment,
    comment,
    docs,
  },
  isPay,
}: IProps) => {
  const [allPrice, setAllPrice] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [cities, setСities] = useState([]);
  const [time, setTime] = useState('');
  const [errorPhone, handleErrorPhone] = useState(false);
  const [dataOrder, handleDataOrder] = useState('');
  const [signatureOrder, handleSignaturerder] = useState('');
  const { t } = useTranslation();
  const formRef = useRef(null);
  const navigate = useNavigate();
  const { isMaster } = useTypedSelector((state) => state.user.order.prevOrder);
  const [isLoading, handleLoading] = useState<boolean>(false);

  useEffect(() => {
    let allPriceCalc = price;
    prevOrder.order.secondStepClient?.forEach((element: any) => {
      allPriceCalc += element.id.price * element.id.value;
    });
    setAllPrice(allPriceCalc);
  }, [prevOrder.order.secondStepClient || price]);

  useEffect(() => {
    getCities().then(({ data }) => {
      setСities(data);
    });
  }, []);
  const paymentsList = [
    {
      value: 'pay_type_card',
      name: t('form.radio_groups.type_payment.sub_1'),
    },
    {
      value: 'pay_type_cash',
      name: t('form.radio_groups.type_payment.sub_2'),
    },
    {
      value: 'pay_type_company',
      name: t('form.radio_groups.type_payment.sub_3'),
    },
  ];

  const contactList = [
    {
      value: 'contact_type_telegram',
      name: t('form.radio_groups.how_to_contact.sub_1'),
    },
    {
      value: 'contact_type_viber',
      name: t('form.radio_groups.how_to_contact.sub_2'),
    },
    {
      value: 'contact_type_phone',
      name: t('form.radio_groups.how_to_contact.sub_3'),
    },
  ];

  return (
    <div className="second_step_order_gazda_container">
      <p>{t('registration_worker.title')}</p>
      <div className="orders_gazda">
        <div className="orders">
          <div className="handle">
            <span>{t('footer.contacts')}</span>
          </div>
          <div className="form_box">
            <Formik
              initialValues={{
                email,
                phone,
                lastName,
                firstName,
                secondName,
                contact,
                typePayment,
                boothNumber,
                flat,
                street,
                entrance,
                floor,
                city,
                startDate,
              }}
              validationSchema={OrderGazdaSchema}
              isSubmitting={true}
              onSubmit={async (values: IOrderGazdaSecondPage) => {
                handleLoading(true);
                const apiObj: any = {
                  email: values.email,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  phone: values.phone,
                  payType: values.typePayment,
                  contactChanel: values.contact,
                  address: values.street,
                  building: values.boothNumber,
                  section: values.entrance,
                  flor: values.floor,
                  apartment: values.flat,
                  comment: comment,
                  city: values.city,
                  appointment_date: values.startDate,
                  appointment_time: time,
                  services: [],
                  docs: [],
                  fixedPrices: [
                    {
                      item: 1,
                      time: 1,
                    },
                  ],
                };
                apiObj.category = prevOrder.order.category;
                if (!prevOrder.isMaster) {
                  apiObj.individual = true;
                }
                prevOrder.order.secondStepClient?.forEach((element: any) => {
                  apiObj.services.push({ id: element.id.id, unit: element.id.value });
                });
                docs.length > 0
                  ? docs.forEach((item: any, index: number) => {
                      resizeFile(item, 800, 800, 200).then((resolveDoc: File) => {
                        apiObj.docs.push({ originalName: docs[index].name, file: resolveDoc });
                        if (docs.length - 1 === index) {
                          orderGazda(apiObj)
                            .then(({ data }) => {
                              handleLoading(false);
                              if (prevOrder.isMaster) {
                                if (!isPay) {
                                  return navigate('/');
                                }
                                handleDataOrder(data.form.data);
                                handleSignaturerder(data.form.signature);
                                const form: any = formRef.current;
                                if (form) {
                                  setTimeout(() => {
                                    document.body.appendChild(form);
                                    form.submit();
                                  }, 100);
                                }
                              } else navigate('/');
                            })
                            .finally(() => handleLoading(false));
                        }
                      });
                    })
                  : orderGazda(apiObj)
                      .then(({ data }) => {
                        handleLoading(false);
                        if (prevOrder.isMaster) {
                          if (!isPay) {
                            return navigate('/');
                          }
                          handleDataOrder(data.form.data);
                          handleSignaturerder(data.form.signature);
                          const form: any = formRef.current;
                          if (form) {
                            setTimeout(() => {
                              document.body.appendChild(form);
                              form.submit();
                            }, 100);
                          }
                        } else navigate('/');
                      })
                      .finally(() => handleLoading(false));

                // docs.map((item: File, index: number) => {
                //   toBase64(item).then((resolveDoc: any) => {
                //     apiObj.docs.push({ originalName: item.name, file: resolveDoc });
                //   });
                // });
              }}
            >
              {({ errors, touched, values, setFieldValue, validateOnBlur }) => (
                <Form id="form1">
                  {/* last_name  */}
                  {getInputNotError({
                    values,
                    errors,
                    touched,
                    name: 'lastName',
                    text: isMaster ? t('placeholder.last_name') : 'Назва компанії',
                  })}
                  {/* firs_name  */}
                  {getInputNotError({
                    values,
                    errors,
                    touched,
                    name: 'firstName',
                    text: t('placeholder.firs_name'),
                  })}
                  {/* surname  */}
                  {getInputNotError({
                    values,
                    errors,
                    touched,
                    name: 'secondName',
                    text: t('placeholder.surname'),
                  })}
                  {/* phone */}
                  <div className={!values.phone ? 'box_input' : 'box_input pd'}>
                    <Field
                      name="phone"
                      // onChange={(e: any) => {
                      //   debugger;
                      // }}
                      render={({ field }: any) => (
                        <MaskedInput
                          {...field}
                          mask={phoneNumberMask}
                          id="phone"
                          placeholder={values.phone ? t('placeholder.phone') : '+380(__) ___-____'}
                          type="text"
                          className={
                            (errors.phone && touched.phone) || errorPhone ? 'error_input' : ''
                          }
                          onBlur={(e: any) => {
                            handleErrorPhone(values.phone.indexOf('_') !== -1);
                          }}
                          onChange={(e: any) => {
                            // console.log('values.phone.length ', values.phone);
                            // console.log(' values.phone.indexOf ', values.phone.indexOf('_'));
                            // console.log(' values.phone.indexOf ', values.phone.indexOf('_') === -1);
                            setFieldValue('phone', e.currentTarget.value);
                          }}
                          onFocus={(e: any) => {
                            !values.phone && setFieldValue('phone', '+380(');
                          }}
                        />
                      )}
                    />
                    {values.phone && <span className="placeholder"> {t('placeholder.phone')}</span>}
                  </div>
                  {/* email  */}
                  {getInputNotError({
                    values,
                    errors,
                    touched,
                    name: 'email',
                    text: t('placeholder.email'),
                  })}
                  <div className="sepor" />
                  {/* RaioGroup */}
                  <div className="raio_group_form">
                    {getRaioGroup(
                      'typePayment',
                      t('form.radio_groups.type_payment.name'),
                      paymentsList
                    )}
                    {/* RaioGroup */}
                    {getRaioGroup(
                      'contact',
                      t('form.radio_groups.how_to_contact.name'),
                      contactList
                    )}
                  </div>
                  <div className="sepor" />
                  <div className="our_address">
                    <p>{t('gazda_page.info_component.order.our_address')}</p>
                    <div
                      className={
                        errors.city && touched.city
                          ? 'box_input box_select ones'
                          : 'box_input  box_select pd ones'
                      }
                    >
                      {values.city === 0 ||
                        (values.city && <span className="name_input">Мiсто</span>)}
                      <Field
                        className={errors.city && touched.city ? 'error_select' : 'custom-select'}
                        name="city"
                        options={cities}
                        component={CustomSelect}
                        placeholder={'Мiсто'}
                      />
                    </div>
                    {getInputNotError({
                      values,
                      errors,
                      touched,
                      name: 'street',
                      text: t('placeholder.street'),
                    })}
                    <div className="detail_address">
                      {getInputNotError({
                        values,
                        errors,
                        touched,
                        name: 'boothNumber',
                        text: t('placeholder.booth_number'),
                      })}
                      {getInputNotError({
                        values,
                        errors,
                        touched,
                        name: 'entrance',
                        text: t('placeholder.entrance'),
                        type: 'number',
                      })}
                      {getInputNotError({
                        values,
                        errors,
                        touched,
                        name: 'floor',
                        text: t('placeholder.floor'),
                        type: 'number',
                      })}
                      {getInputNotError({
                        values,
                        errors,
                        touched,
                        name: 'flat',
                        text: isMaster ? t('placeholder.flat') : `${t('placeholder.flat')}/офіс`,
                        type: 'number',
                      })}
                    </div>
                  </div>
                  <div className="date">
                    <p>{t('date')}</p>
                    <div className={!values.startDate ? 'box_input' : 'box_input pd'}>
                      {values.startDate && (
                        <span className="placeholder">{t('placeholder.date_order')}</span>
                      )}
                      <Field
                        className={errors['startDate'] && touched['startDate'] ? 'error_input' : ''}
                        name="startDate"
                        component={DatePicker}
                        onChange={(date: Date) => setFieldValue('startDate', date)}
                        selected={values.startDate}
                        placeholderText={t('placeholder.date_order')}
                        showIcon={true}
                        minDate={new Date()}
                      />
                      <img src={iconsImg.Calendar} alt="сalendar" />
                    </div>
                  </div>
                  <div className="choose_time">
                    <p>{t('choose_time')}</p>
                    <div className="box_time">
                      {chooseTimeArray.map((item, idx) => {
                        return (
                          <div
                            className={item !== time ? 'time' : 'time choose'}
                            onClick={() => setTime(item)}
                            key={`${item}_${idx}`}
                          >
                            {item}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="wrapper_price">
          <div className="price">
            <div className="sb all">
              <span>{t('all')}</span>
              <div>
                {allPrice}
                {t('gazda_page.info_component.price.sub_title.sub_2')}
              </div>
            </div>
            {prevOrder.isMaster && (
              <div className="sb prepayment">
                <span>{t('prepayment')}</span>
                <>
                  {allPrice * 0.1} {t('gazda_page.info_component.price.sub_title.sub_2')}
                </>
              </div>
            )}
            <div className="sb">
              <span>{t('order_price')}</span>
              <>
                {Math.ceil(price)} {t('gazda_page.info_component.price.sub_title.sub_2')}
              </>
            </div>
            {prevOrder.order.secondStepClient?.map((item: any, ind: number) => {
              return (
                <div className="sb" key={`secondStepClient_${ind}`}>
                  <span>{item.id.title}</span>
                  <div className="box_price">
                    <div className="count_unit">
                      <span>{item.id.value}</span>
                      {units[item.id.unit as keyof typeof units]}
                    </div>
                    {item.id.price} {t('gazda_page.info_component.price.sub_title.sub_2')}{' '}
                  </div>
                </div>
              );
            })}
            <div className="sepor" />
            <div className="sb">
              <div className="box">
                <span>{t('gazda_page.info_component.order.region')}</span>
                <img src={iconsImg.Info} alt="Info" />
              </div>
              <>40{t('gazda_page.info_component.price.sub_title.sub_2')}/км</>
            </div>
            <div className="sepor" />
            <button className="next" type="submit" form="form1">
              {t('order')}
            </button>
          </div>
          <div className="box_links">
            <p>{t('docs.accses')}</p>
            <p>
              <Link to="/terms" target="_blank">
                {t('docs.offer')}
              </Link>
              {t('docs.company')}
            </p>
          </div>
        </div>
      </div>
      <Load isLoading={isLoading} />
      <form
        ref={formRef}
        method="POST"
        action="https://www.liqpay.ua/api/3/checkout"
        acceptCharset="utf-8"
      >
        <input type="hidden" name="data" value={dataOrder} />
        <input type="hidden" name="signature" value={signatureOrder} />
        <input type="hidden" name="result_url" value="https://stephub.com.ua/order-gazda" />
      </form>
    </div>
  );
};
export default SecondStep;
