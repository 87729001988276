import * as Yup from 'yup';

export const RegistrationWorkerSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  phone: Yup.string().required('Required').min(8, 'Required'),
  city: Yup.string().required('Required'),
  fieldActivity: Yup.array()
    .min(1, 'You need at least three friends')
    .required('Go out! Make your life enjoyable!'),
  lastName: Yup.string().required('Required').min(2, 'Required'),
  firstName: Yup.string().required('Required').min(2, 'Required'),
  surname: Yup.string().required('Required').min(2, 'Required'),
  password: Yup.string()
    .required('No password provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  replayPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('No password provided.'),
  avatar: Yup.array()
    .nullable()
    .test('FILE_SIZE', 'Uploaded file is too big.', (value) => !!value?.length),
});

export const RegistrationWorkerSchemaSecondStep = Yup.object().shape({
  files: Yup.array()
    .nullable()
    .test('FILE_SIZE', 'Uploaded file is too big.', (value) => {
      if (value && value.length >= 2) {
        return true;
      } else return false;
    }),
});

export const RegistrationClientrSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  phone: Yup.string().required('Required').min(8, 'Required'),
  lastName: Yup.string().required('Required').min(2, 'Required'),
  firstName: Yup.string().required('Required').min(2, 'Required'),
  surname: Yup.string().required('Required').min(2, 'Required'),
  password: Yup.string()
    .required('No password provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  replayPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('No password provided.'),
  toggle: Yup.bool().oneOf([true], 'You need to accept the terms and conditions'),
});

export const UpdateSchema = Yup.object().shape({
  fieldActivity: Yup.array()
    .min(1, 'You need at least three friends')
    .required('Go out! Make your life enjoyable!'),
  lastName: Yup.string().required('Required').min(2, 'Required'),
  firstName: Yup.string().required('Required').min(2, 'Required'),
  surname: Yup.string().required('Required').min(2, 'Required'),
  password: Yup.string()
    .required('No password provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  avatar: Yup.mixed().test('fileSize', 'The file is too large', (value) => {
    if (!value.length) return true; // attachment is optional
    return value[0].size <= 2000000;
  }),
});

export const CompleteTheOrder = Yup.object().shape({
  photos: Yup.array().test((value) => {
    if (value && value.length >= 1) {
      return true;
    } else return false;
  }),
});

export const OrderGazdaSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  phone: Yup.string().required('Required').min(8, 'Required'),
  lastName: Yup.string().required('Required').min(2, 'Required'),
  firstName: Yup.string().required('Required').min(2, 'Required'),
  secondName: Yup.string().required('Required').min(2, 'Required'),
  boothNumber: Yup.string().required('Required'),
  flat: Yup.number().required('Required'),
  street: Yup.string().required('Required').min(2, 'Required'),
  entrance: Yup.number().required('Required'),
  floor: Yup.number().required('Required'),
  city: Yup.string().required('Required'),
});
