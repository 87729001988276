import React, { useState, useEffect, useRef } from 'react';
import './CallMasterStyle.scss';
import ButtomCustom from '../../../customs/buttomCustom/ButtonCustom';
import { Field, Form, Formik } from 'formik';
import { IOrderGazdaLast } from '../../../../types';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

interface IProps {
  orderGazda: IOrderGazdaLast;
  allCategoies: any;
  setOrderGazda: any;
  handleStep: any;
  handleTitleSecondStep: any;
}

const CallMaster = ({
  orderGazda: { category, service, section },
  allCategoies,
  setOrderGazda,
  handleStep,
  handleTitleSecondStep,
}: IProps) => {
  const [categores, setСategory] = useState([]);
  const [services, setServices] = useState([]);
  const [sections, setSections] = useState([]);
  const { t } = useTranslation();
  const [isValidation, handleValid] = useState(false);
  const categoresRef: any = useRef(null);
  const servicesRef: any = useRef(null);
  const sectionsRef: any = useRef(null);
  const [categoryDefault, setCategoryDefault] = useState<any>();
  const [servicesDefault, setServicesDefault] = useState<any>();
  const [sectionsDefault, setSectionsDefault] = useState<any>();
  useEffect(() => {
    setСategory(
      allCategoies.map((item: any) => {
        return { value: item.id, label: item.title, ...item };
      })
    );
  }, []);
  useEffect(() => {
    getDefaultValueCategory();
  }, [categores]);

  function handleСategory(selectItem: any, values: any) {
    setServices(
      selectItem.children.map((item: any) => {
        return { value: item.id, label: item.title, ...item };
      })
    );
    values.service && servicesRef.current!.clearValue();
    values.section && sectionsRef.current!.clearValue();
  }

  function handleServices(selectItem: any, values: any) {
    selectItem &&
      setSections(
        selectItem.children.map((item: any) => {
          return { value: item.id, label: item.title, ...item };
        })
      );
    values.section && sectionsRef.current!.clearValue();
  }
  function getDefaultValueCategory() {
    if (category && service && section) {
      const listServices: any = [];
      const listSection: any = [];
      categores.forEach((element: any) => {
        if (element.id === category) {
          setCategoryDefault({ value: element.id, label: element.title });
          element.children.forEach((item: any) => {
            listServices.push({ value: item.id, label: item.title, ...item });
            if (item.id === service) {
              setServicesDefault({ value: item.id, label: item.title });
              item.children.forEach((el: any) => {
                listSection.push({ value: el.id, label: el.title, ...item });
                if (el.id === section) {
                  setSectionsDefault({ value: el.id, label: el.title });
                }
              });
            }
          });
        }
      });
      setServices(listServices);
      setSections(listSection);
    }
  }
  return (
    <div className="call_master">
      <Formik
        initialValues={{
          category,
          service,
          section,
        }}
        validate={(values) => {
          if (values.category && values.service && values.section) {
            handleValid(true);
          } else handleValid(false);
        }}
        onSubmit={async (values: any) => {
          handleStep();
          setOrderGazda({ ...values });
        }}
      >
        {({ values }) => (
          <Form>
            {/* //category */}
            <div
              className={
                !values.category ? 'box_input box_select ones' : 'box_input  box_select pd ones'
              }
            >
              {values.category && <span className="name_input">Категорія</span>}
              <Field
                className="custom-select"
                name="category"
                render={({ form, field }: any) => (
                  <Select
                    className="custom-select"
                    onChange={(e: any) => {
                      handleСategory(e, form.values);
                      form.setFieldValue('service', '');
                      form.setFieldValue('section', '');
                      form.setFieldValue(field.name, e ? e.value : '');
                      handleValid(false);
                      setCategoryDefault({ value: e.value, label: e.label });
                      setServicesDefault({ value: '', label: 'Послуга' });
                      setSectionsDefault({ value: '', label: 'Розділ' });
                      setSections([]);
                    }}
                    options={categores}
                    placeholder={'Категорія'}
                    ref={categoresRef}
                    value={categoryDefault}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#f1f1ee',
                        primary: '#b6b6a1',
                        primary50: '#f1f1ee',
                      },
                    })}
                    maxMenuHeight={150}
                  />
                )}
              />
            </div>
            {/* //service */}
            <div
              className={
                !values.service ? 'box_input box_select ones' : 'box_input  box_select pd ones'
              }
            >
              {values.service && <span className="name_input">Послуга</span>}
              <Field
                options={services}
                placeholder={'Послуга'}
                name="service"
                render={({ form, field }: any) => (
                  <Select
                    className="custom-select"
                    onChange={(e: any) => {
                      if (e) {
                        handleServices(e, form.values);
                        form.setFieldValue(field.name, e ? e.value : '');
                        setServicesDefault({ value: e.value, label: e.label });
                      }
                      form.setFieldValue('section', '');
                      setSectionsDefault({ value: '', label: 'Розділ' });
                      handleValid(false);
                    }}
                    options={services}
                    isDisabled={!values.category}
                    placeholder={'Послуга'}
                    ref={servicesRef}
                    value={servicesDefault}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#f1f1ee',
                        primary: '#b6b6a1',
                        primary50: '#f1f1ee',
                      },
                    })}
                    maxMenuHeight={150}
                  />
                )}
              />
            </div>
            {/* //sections */}
            <div
              className={
                !values.section ? 'box_input box_select ones' : 'box_input  box_select pd ones'
              }
            >
              {values.section && <span className="name_input">Розділ</span>}
              <Field
                options={sections}
                name="section"
                render={({ field, form }: any) => (
                  <Select
                    className="custom-select"
                    onChange={(e: any) => {
                      if (e) {
                        form.setFieldValue(field.name, e ? e.value : '');
                        handleTitleSecondStep(e ? e.label : '');
                        setSectionsDefault({ value: e.value, label: e.label });
                      }
                    }}
                    options={sections}
                    ref={sectionsRef}
                    isDisabled={!values.service}
                    placeholder={'Розділ'}
                    value={sectionsDefault}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#f1f1ee',
                        primary: '#b6b6a1',
                        primary50: '#f1f1ee',
                      },
                    })}
                    maxMenuHeight={150}
                  />
                )}
              />
            </div>
            <div className="links">
              <ButtomCustom
                isValid={isValidation}
                dirty={isValidation}
                text={t('modals.select_city.btn')}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CallMaster;
