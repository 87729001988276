import React, { useState, useEffect } from 'react';
import './MainComponentServiceStyle.scss';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BreadСrumbs from '../../../components/breadСrumbs/BreadСrumbs';
import { iconsImg } from '../../../assets/imgs/icons';
import { getDataService } from '../../../helpers';

interface IProps {
  imgService: string;
  title: string;
  subTitle: string;
  parent: string;
  arrayLinks: { name: string; link: string }[];
  openModal: any;
  isEmpty?: boolean;
}

interface IDataService {
  title: string;
  description: string;
  parent: string;
  imgService: string;
}

const MainComponentService = ({
  imgService,
  subTitle,
  title,
  parent,
  arrayLinks,
  openModal,
  isEmpty,
}: IProps) => {
  const [serviceItem, setServiceItem] = useState('');
  const [dataServicePage, setDataServicePage] = useState<IDataService>();
  const { t } = useTranslation();
  const { service } = useParams();
  // useEffect(() => {
  //   service && setServiceItem(service);
  //   // debugger;
  // });
  useEffect(() => {
    service && setDataServicePage(getDataService(service));
  }, [service]);
  return dataServicePage ? (
    <div className="sub_service_main_component">
      <BreadСrumbs arrayLinks={arrayLinks} />
      <div className={`box_slid ${service}`}>
        <div className={`info_slid `}>
          <p className="parent">{parent}</p>
          <h3>{t(dataServicePage.title)}</h3>
          <p>{t(dataServicePage.description)}</p>
          {isEmpty ? (
            window.innerWidth > 768 ? (
              <button className="tel">
                <img src={iconsImg.Phone} alt="phone" /> +380 73 9 836 837
              </button>
            ) : (
              <a href="tel:+380739836837">
                <button className="tel">
                  <img src={iconsImg.Phone} alt="phone" /> +380 73 9 836 837
                </button>
              </a>
            )
          ) : (
            <button className="order_bt" onClick={openModal}>
              {t('order')}
            </button>
          )}
        </div>
        <img src={imgService} alt={title} />
      </div>
    </div>
  ) : null;
};

export default MainComponentService;
