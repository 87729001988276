import React from 'react';
import { Field } from 'formik';
import { iconsImg } from '../../assets/imgs/icons';
import './indexStyle.scss';

export const getInput = ({ values, errors, touched, name, text, type = 'text' }: any) => {
  return (
    <div className={!values[name] ? 'box_input' : 'box_input pd'}>
      <Field
        className={errors[name] && touched[name] ? 'error_input' : ''}
        name={name}
        type={type}
        placeholder={text}
      />
      {values[name] && <span className="placeholder">{text}</span>}
      {/* {errors[name] && touched[name] ? (
        <div className="error">
          <img src={iconsImg.Error} alt="error" />
          {errors[name]}
        </div>
      ) : null} */}
    </div>
  );
};
export const getInputNotError = ({ values, errors, touched, name, text, type = 'text' }: any) => {
  return (
    <div className={!values[name] ? 'box_input' : 'box_input pd'}>
      <Field
        className={errors[name] && touched[name] ? 'error_input' : ''}
        name={name}
        type={type}
        placeholder={text}
      />
      {values[name] && <span className="placeholder">{text}</span>}
    </div>
  );
};

export function getRaioGroup(
  name: string,
  nameGroup: string,
  list: { name: string; value: string }[]
) {
  return (
    <div className="radio_group_container">
      <p className="name_group">{nameGroup}</p>
      <div className="group" aria-labelledby="my-radio-group">
        {list.map((item, ind) => {
          return (
            <label className="sub_group" key={`${name}_${ind}`}>
              <Field id={`${name}_${ind}`} type="radio" name={name} value={item.value} />

              <label htmlFor={`${name}_${ind}`} className="radio-label">
                {item.name}
              </label>
            </label>
          );
        })}
      </div>
    </div>
  );
}
