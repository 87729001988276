import React, { useState } from 'react';
import CustomModal from '../customModal/CustomModal';
import './PersonalAccountModalStyle.scss';
import { useTranslation } from 'react-i18next';
import { iconsImg } from '../../../assets/imgs/icons';
import { IOrderAcount } from '../../../types';
import { Field, Form, Formik } from 'formik';
import { CompleteTheOrder } from '../../../assets/Schemes';
import DragDrop from '../../customs/dragDrop/DragDrop';
import ButtomCustom from '../../customs/buttomCustom/ButtonCustom';
import { getDateTime, resizeFile, toBase64 } from '../../../helpers';
import { orderFinish } from '../../../api';
import ViewImg from '../../customs/viewImg/ViewImg';

interface IProps {
  handleModal: any;
  isOpen: boolean;
  card: IOrderAcount | undefined;
  getListsApi: any;
}

const PersonalAccountModal = ({ handleModal, isOpen, card, getListsApi }: IProps) => {
  const [isFinished, setFinished] = useState(false);
  const [photos, setPhotos] = useState([]);
  const { t } = useTranslation();
  return (
    <CustomModal handleModal={handleModal} isOpen={isOpen}>
      <div className="modal_personal_account">
        <div className="bread" onClick={handleModal}>
          <img src={iconsImg.ArrowFooter} alt="ArrowLeftBlack" /> <span>{t('back')}</span>
        </div>
        {!isFinished ? (
          <div className="form">
            {card?.orderServices.map((item: any, index) => {
              return (
                <span key={`${item.title}_${index}`}>
                  {item.title}
                  <br />
                </span>
              );
            })}
            <div className="in_progress">
              <img src={iconsImg.Clock} alt="clock" />
              {getDateTime(card?.inProgress)}
            </div>
            {/* <div className="urgent">
              <img src={iconsImg.Urgent} alt="urgent" />
              {t('personal_account_page.modal.urgent_order')}
            </div> */}
            <div className="info">
              <div className="sub_info">
                <img src={iconsImg.Id} alt="Id" />
                <span>
                  {t('gazda_page.info_component.order.title')}
                  {` №`}
                  {card?.numberOrder}
                </span>
              </div>
              <div className="separator" />
              <div className="sub_info">
                <img src={iconsImg.Calendar} alt="Calendar" />
                {card?.start && (
                  <span>
                    {t('personal_account_page.modal.start')} - {getDateTime(card?.start)}
                  </span>
                )}
              </div>
              <div className="separator" />
              <div className="sub_info as">
                <img src={iconsImg.LocationGray} alt="Location" />
                <>
                  <span>
                    <b>{card?.address.city}</b>
                    <br />
                    <span className="c_gray">
                      {card?.address.street}
                      <br />
                      {card?.address.hous}
                    </span>
                  </span>
                </>
              </div>
              <div className="separator" />
              <div className="sub_info">
                <img src={iconsImg.UserGray} alt="User" />
                <>
                  <span>
                    {card?.client.fullName}
                    <br />
                    <span className="c_gray">{card?.client.phone}</span>
                  </span>
                </>
              </div>
              <div className="separator" />
              {card?.price && (
                <div className="sub_info">
                  <img src={iconsImg.Card} alt="Card" />
                  <div className="price">
                    <span>{t('price')}</span>
                    <span>
                      {Math.ceil(card.price * 0.9)}
                      {t('gazda_page.main_component.price.sub_2')}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="report">
              <img src={iconsImg.Info} alt="Info" />
              <p>{t('personal_account_page.modal.photo_report')}</p>
            </div>
            {card?.comment && (
              <>
                <div className="separator" />
                <div className="box_comment_input">
                  <p>{t('gazda_page.info_component.order.comment')}:</p>
                  <span>{card.comment}</span>
                </div>
                <div className="separator" />
              </>
            )}
            {card?.orderFiles && <ViewImg imgList={card.orderFiles} />}
            <div className="separator" />
            <Formik
              initialValues={{ photos: photos }}
              validationSchema={CompleteTheOrder}
              onSubmit={async (values) => {
                // orderFinish()
                const apiObj: any = {
                  id: card?.numberOrder,
                  files: [],
                };
                values.photos.forEach((item: any, index: number) => {
                  resizeFile(item, 800, 800, 200).then((resolveDoc: any) => {
                    apiObj.files.push({ file: resolveDoc });
                    if (values.photos.length - 1 === index) {
                      orderFinish(apiObj).then(() => {
                        getListsApi();
                        setFinished(!isFinished);
                      });
                    }
                  });
                });
              }}
            >
              {({ errors, touched, isValid, dirty, values }) => (
                <Form>
                  <div className="drop">
                    <span className="name_input">{t('placeholder.documents')}</span>
                    <Field
                      name="photos"
                      component={DragDrop}
                      placeholder={t('placeholder.our_activity')}
                      isMulti={true}
                      isFinish={true}
                    />
                  </div>
                  <ButtomCustom
                    isValid={values.photos.length > 0}
                    dirty={values.photos.length > 0}
                    text={t('personal_account_page.modal.bt')}
                  />
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <div className="finished">
            <img src={iconsImg.CheckResetPassword} alt="Check" />
            <p>{t('personal_account_page.modal.finished')}</p>
            <button
              onClick={() => {
                handleModal();
                setFinished(!isFinished);
              }}
            >
              {t('personal_account_page.modal.back')}
            </button>
          </div>
        )}
      </div>
    </CustomModal>
  );
};

export default PersonalAccountModal;
