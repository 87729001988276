import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MainComponentService from '../../../components/subServices/mainComponent/MainComponentService';
import OurProcess from '../../../components/gazda/ourProcess/OurProcess';
import ChooseUs from '../../../components/gazda/chooseUs/ChooseUs';
import CallBackService from '../../../components/subServices/steps/CallBackService';
import OrderGazda from '../../../components/gazda/orderGazda/OrderGazda';
import { useParams } from 'react-router-dom';
import { getDataService } from '../../../helpers';

interface IDataService {
  title: string;
  description: string;
  parent: string;
  imgService: string;
}

const LandscapingDetail = () => {
  const [isModal, setModal] = useState(false);
  const [isOrder, setOrder] = useState(false);
  const [price, setPrice] = useState(0);
  const [dataServicePage, setDataServicePage] = useState<IDataService>();
  const { service } = useParams();

  const { t } = useTranslation();
  useEffect(() => {
    service && setDataServicePage(getDataService(service));
  }, []);

  const arrayLinks = [
    { name: t('main'), link: '/' },
    { name: t('landscaping'), link: '/landscaping' },
    { name: t(`header.${service}`), link: `/${service}` },
  ];

  return (
    <div className="engineering_container">
      {isOrder && <OrderGazda count={price} />}
      <MainComponentService
        imgService={dataServicePage?.imgService ? t(dataServicePage?.imgService) : ''}
        subTitle={dataServicePage?.description ? t(dataServicePage?.description) : ''}
        title={dataServicePage?.title ? t(dataServicePage?.title) : ''}
        parent={dataServicePage?.parent ? t(dataServicePage?.parent) : ''}
        arrayLinks={arrayLinks}
        openModal={() => {
          setModal(!isModal);
        }}
        isEmpty={true}
      />
      <OurProcess />
      <ChooseUs />
      <CallBackService />
    </div>
  );
};

export default LandscapingDetail;
