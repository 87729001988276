export enum units {
  'point' = 'точка',
  'item' = 'шт',
  'square_meter' = 'м²',
  'linear_meter' = 'п.м',
  'hour' = 'час',
}

// export enum serviceUrl {
//   '/actions' = '',
//   '/rooms' = '',
//   '/territory' = '',
//   '/landscaping' = '',
//   '/lawn' = '',
//   '/flowers' = '',
//   '/facade' = '',
//   '/apartments' = '',
//   '/offices' = '',
//   '/after_repair' = '',
//   '/plumbing' = '',
//   '/electrician' = '',
//   '/repair' = '',
//   '/engineering' = '',
//   '/cleaning' = '',
//   '/houses' = '',
//   '/vacancies' = '',
// }

export enum serviceUrl {
  // "rooms" = "",
  // "territory" = "",
  // "landscaping" = "",
  // "lawn" = "",
  // "flowers" = "",
  // "facade" = "",
  'plumbing' = 'santekhnіka',
  'electricity' = 'elektrika',
  'repair' = 'remont',
  'apartments' = '',
  'offices' = 'offices',
  'furniture' = 'meblі',
  'ventilation' = 'ventilyatsіya',
}
