import React, { useEffect, useState } from 'react';
import './OrdersBoardStyle.scss';
import { useTranslation } from 'react-i18next';
import { getCategory, getCities, getListOrdersAllApi, sendTokenApi } from '../../api';
import Filter from '../../components/ordersBoard/filter/Filter';
import ListOrder from '../../components/ordersBoard/listOrder/ListOrder';
import BreadСrumbs from '../../components/breadСrumbs/BreadСrumbs';
import { ICategory, ICities } from '../../types';

const OrdersBoard = () => {
  const { t } = useTranslation();
  const [categoryList, setCategoryList] = useState<ICategory[]>([]);
  const [cityList, setCityList] = useState<ICities[]>([]);
  const [listOrder, setListOrder] = useState<any>([]);
  const [dataJob, setDataJob] = useState<any>();
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const arrayLinks = [
    { name: t('main'), link: '/' },
    { name: t('gazda_page.info_component.order.title'), link: '/order_board' },
  ];
  const getList = () => {
    getListOrdersAllApi('').then(({ data }) => {
      setDataJob(data);
      setListOrder(data.orders);
      window.scrollTo(0, 0);
    });
  };
  useEffect(() => {
    getList();
    getCities().then(({ data }) => {
      setCityList(data);
    });
    getCategory().then(({ data }) => {
      setCategoryList(data);
    });
  }, []);

  return (
    <div className="box_orders_page">
      <BreadСrumbs arrayLinks={arrayLinks} isBlack={true} />
      <div className="orders_board_container">
        {window.innerWidth > 1024 && (
          <Filter
            cityList={cityList}
            categoryList={categoryList}
            setListOrder={setListOrder}
            total={total}
            setTotal={setTotal}
            page={page}
            setPage={setPage}
            pageCount={pageCount}
            setPageCount={setPageCount}
          />
        )}
        {/* {dataJob ? ( */}
        <ListOrder
          categoryList={categoryList}
          cityList={cityList}
          listOrder={listOrder}
          allData={dataJob}
          setListOrder={setListOrder}
          total={total}
          setTotal={setTotal}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
          getList={getList}
        />
        {/* ) : ( */}
        {/* emptyOrders() */}
        {/* )} */}
      </div>
    </div>
  );
};
export default OrdersBoard;
