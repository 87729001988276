import React, { useState, useEffect } from 'react';
import './OurProcessStyle.scss';
import { useTranslation } from 'react-i18next';

const OurProcess = () => {
  const { t } = useTranslation();

  return (
    <div className="gazda_our_process">
      <p className="name_page">{t('maim_page.our_process.name_page')}</p>
      <p>{t('gazda_page.our_process.title')}</p>
      <div className="steps">
        <div className="step">
          <p>{t('gazda_page.our_process.elements.step_1.title')}</p>
          <div className="sepparetion" />
          <p className="sub_title">{t('gazda_page.our_process.elements.step_1.sub_title')}</p>
        </div>
        <div className="step">
          <p>{t('gazda_page.our_process.elements.step_2.title')}</p>
          <div className="sepparetion" />
          <p className="sub_title">{t('gazda_page.our_process.elements.step_2.sub_title')}</p>
        </div>
        <div className="step">
          <p>{t('gazda_page.our_process.elements.step_3.title')}</p>
          <div className="sepparetion" />
          <p className="sub_title">{t('gazda_page.our_process.elements.step_3.sub_title')}</p>
        </div>
      </div>
    </div>
  );
};

export default OurProcess;
