import React, { useEffect, useRef, useState } from 'react';
import './SphereServicesStyle.scss';
import { useTranslation } from 'react-i18next';
import { Element, scroller } from 'react-scroll';

interface IElement {
  title: string;
  subTitle: string;
}

const SphereServices = () => {
  const { t } = useTranslation();
  const [animation, setAnimatio] = useState('');
  const componentRef: any = useRef(null);
  const elements: IElement[] = [
    {
      title: t('maim_page.sphere_services.elements.business.title'),
      subTitle: t('maim_page.sphere_services.elements.business.subTitle'),
    },
    {
      title: t('maim_page.sphere_services.elements.undertakings.title'),
      subTitle: t('maim_page.sphere_services.elements.undertakings.subTitle'),
    },
    {
      title: t('maim_page.sphere_services.elements.houses.title'),
      subTitle: t('maim_page.sphere_services.elements.houses.subTitle'),
    },
    {
      title: t('maim_page.sphere_services.elements.restaurants.title'),
      subTitle: t('maim_page.sphere_services.elements.restaurants.subTitle'),
    },
    {
      title: t('maim_page.sphere_services.elements.stores.title'),
      subTitle: t('maim_page.sphere_services.elements.stores.subTitle'),
    },
    {
      title: t('maim_page.sphere_services.elements.store.title'),
      subTitle: t('maim_page.sphere_services.elements.store.subTitle'),
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const { top } = componentRef.current.getBoundingClientRect();
      if (top <= window.innerHeight / 2) {
        setAnimatio('animation');
      } else {
        setAnimatio('');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  function getElement({ title, subTitle }: IElement, index: number) {
    return (
      <div key={`elment_${index}`} className={`wrapper_element ${animation}`}>
        <p className="title">{title}</p>
        <div className="seppartion" />
        <p className="sub_title">{subTitle}</p>
      </div>
    );
  }
  return (
    <div className="sphere_services" ref={componentRef}>
      <div className={`box_title ${animation}`}>
        <label>{t('maim_page.sphere_services.name_page')}</label>
        <p>
          <span>{t('maim_page.sphere_services.title.sub_1')}</span>
          {t('maim_page.sphere_services.title.sub_2')}
          <span>{t('maim_page.sphere_services.title.sub_3')}</span>
        </p>
      </div>
      <div className="box_info">
        {elements.map((item, index) => {
          return getElement(item, index);
        })}
      </div>
    </div>
  );
};

export default SphereServices;
