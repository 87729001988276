import Engineering from './engineering.png';
import Cleaning from './cleaning.png';
import Landscaping from './landscaping.png';
import Houses from './houses.png';
import Gazda from './gazda.png';

export const ourServicesImgs = {
  Engineering,
  Cleaning,
  Landscaping,
  Houses,
  Gazda,
};
