import React, { useState } from 'react';
import './RegistrationСhoiceStyle.scss';
import { useTranslation } from 'react-i18next';
import RegistrationImg from '../../assets/imgs/registation_main.jpg';
import LogoImg from '../../assets/imgs/logo.svg';
import ButtomCustom from '../../components/customs/buttomCustom/ButtonCustom';
import { Link } from 'react-router-dom';
import { iconsImg } from '../../assets/imgs/icons';

const RegistrationСhoice = () => {
  const { t } = useTranslation();
  const [choiceRegistration, setChoiceRegistration] = useState('');

  function getSelectRegistration() {
    return (
      <div className="select">
        <div
          className={choiceRegistration === 'client' ? 'card select_card' : 'card'}
          onClick={() => {
            setChoiceRegistration('client');
          }}
        >
          <div className="box_imgs">
            <img
              src={choiceRegistration === 'client' ? iconsImg.UserRadioSelect : iconsImg.UserRadio}
              alt="SettingRadio"
            />
            <img
              src={choiceRegistration === 'client' ? iconsImg.RadioSelect : iconsImg.Radio}
              alt="Radio"
            />
          </div>
          <span>{t('registration_choice.choice_client')}</span>
        </div>
        <div
          className={choiceRegistration === 'worker' ? 'card select_card' : 'card'}
          onClick={() => {
            setChoiceRegistration('worker');
          }}
        >
          <div className="box_imgs">
            <img
              src={
                choiceRegistration === 'worker'
                  ? iconsImg.SettingRadioSelect
                  : iconsImg.SettingRadio
              }
              alt="SettingRadio"
            />
            <img
              src={choiceRegistration === 'worker' ? iconsImg.RadioSelect : iconsImg.Radio}
              alt="Radio"
            />
          </div>
          <span>{t('registration_choice.choice_worker')}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="registration_choice_container">
      <div className="wrapper_info">
        <div className="info_container">
          <Link to="/">
            <img id="logo" src={LogoImg} alt="logo" />
          </Link>
          <div className="box_choice">
            <p>{t('registration_choice.title')}</p>
            <div className="box_text">
              <label className="sub_title">{t('registration_choice.sub_title')}</label>
            </div>
            {getSelectRegistration()}
            <Link to={choiceRegistration === 'worker' ? '/register-worker' : '/register-client'}>
              <ButtomCustom
                isValid={choiceRegistration ? true : false}
                dirty={choiceRegistration ? true : false}
                text={t('forgot.next')}
              />
            </Link>
          </div>
        </div>
        <span>
          {t('registration_choice.question')}
          <Link to="/authorization"> {t('sign_in')}</Link>
        </span>
      </div>
      <Link to="/">
        {' '}
        <img id="mainImg" src={RegistrationImg} alt="RegistrationImg" />
      </Link>
    </div>
  );
};
export default RegistrationСhoice;
