import React, { useState } from 'react';
import './ButtonCustomStyle.scss';

interface IProps {
  isValid: boolean;
  dirty: any;
  text: string;
  onClick?: any;
}

const ButtomCustom = ({ isValid, dirty, text, onClick }: IProps) => {
  return (
    <div className="buttom_custom">
      <button type="submit" disabled={!(isValid && dirty)} onClick={onClick}>
        {text}
      </button>
    </div>
  );
};

export default ButtomCustom;
