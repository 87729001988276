import React, { useState } from 'react';
import './InfoComponentStyle.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  openModal: any;
  price: number;
}

const InfoComponent = ({ openModal, price }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className="gazda_info_component">
      <p>{t('gazda_page.info_component.title')}</p>
      <div className="container_info">
        <div className="info">
          <p>{t('gazda_page.info_component.price.title')}</p>
          <div className="sepparetion" />
          <span>
            {t('order_price')} {price}
            {t('gazda_page.main_component.price.sub_2')}
          </span>
        </div>
        <div className="info">
          <p>{t('gazda_page.info_component.order.title')}</p>
          <div className="sepparetion" />
          <span>
            {t('order_minimum')} 600 {t('gazda_page.main_component.price.sub_2')}
          </span>
        </div>
        <div className="info">
          <p>{t('gazda_page.info_component.advice.title')}</p>
          <div className="sepparetion" />
          <span>{t('gazda_page.info_component.advice.sub_title')}</span>
        </div>
      </div>
      <div className="box_button">
        <button onClick={openModal}>{t('order')}</button>
      </div>
    </div>
  );
};

export default InfoComponent;
