import React, { useState, useEffect } from 'react';
import HeaderMobile from './headerMobile/HeaderMobile';
import HeaderDesktop from './Header';
import HeaderEmpty from './headerEmpty/HeaderEmpty';
import { useReactPath } from '../../hooks/useReactPath';
import { setVisible } from '../../helpers';
import { useLocation } from 'react-router-dom';
const Header = ({ changeLanguage }: any) => {
  const [visibleUrl, setVisibleUrl] = useState('');
  const location = useLocation();
  useEffect(() => {
    setVisible(location.pathname, setVisibleUrl);
  }, [location.pathname]);

  return (
    <header>
      {visibleUrl === 'main' && <HeaderDesktop changeLanguage={changeLanguage} />}
      {visibleUrl === 'main' && <HeaderMobile changeLanguage={changeLanguage} />}
      {visibleUrl === 'empty' && <HeaderEmpty />}
    </header>
  );
};

export default Header;
