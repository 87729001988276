import React, { useEffect, useRef, useState } from 'react';
import './OurAdvantagesStyle.scss';
import { useTranslation } from 'react-i18next';
import { ourAdvantagesImgs } from '../../../assets/imgs/icons/our_advantages';

interface IElement {
  img: string;
  title: string;
  subTitle: string;
}

const OurAdvantages = () => {
  const { t } = useTranslation();
  const [animation, setAnimatio] = useState('');
  const compRef: any = useRef(null);
  const elements: IElement[] = [
    {
      img: ourAdvantagesImgs.People,
      title: t('maim_page.our_advantages.elements.retelny.title'),
      subTitle: t('maim_page.our_advantages.elements.retelny.sub_title'),
    },
    {
      img: ourAdvantagesImgs.Checked,
      title: t('maim_page.our_advantages.elements.rating.title'),
      subTitle: t('maim_page.our_advantages.elements.rating.sub_title'),
    },
    {
      img: ourAdvantagesImgs.Places,
      title: t('maim_page.our_advantages.elements.orientation.title'),
      subTitle: t('maim_page.our_advantages.elements.orientation.sub_title'),
    },
    {
      img: ourAdvantagesImgs.Files,
      title: t('maim_page.our_advantages.elements.guaranteed.title'),
      subTitle: t('maim_page.our_advantages.elements.guaranteed.sub_title'),
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const { top } = compRef.current.getBoundingClientRect();
      if (top <= window.innerHeight / 2) {
        setAnimatio('animation');
      } else {
        setAnimatio('');
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  function getElement({ title, subTitle, img }: IElement, index: number) {
    return (
      <div key={`elment_${index}`} className={`wrapper_element ${animation}`}>
        <img src={img} alt="icon" />
        <div className="box_text">
          <p className="title">{title}</p>
          <p className="sub_title">{subTitle}</p>
        </div>
      </div>
    );
  }
  return (
    <div className="our_advantages" ref={compRef}>
      <label className="name_page">{t('maim_page.our_advantages.name_page')}</label>
      <div className={`box_info ${animation}`}>
        <p>{t('maim_page.our_advantages.title')}</p>
      </div>
      <div className="flex_end">
        <div className="wrapper_info">
          {elements.map((item, index) => {
            return getElement(item, index);
          })}
        </div>
      </div>
    </div>
  );
};

export default OurAdvantages;
