import { Field, Formik, Form } from 'formik';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { iconsImg } from '../../../../assets/imgs/icons';
import { resizeFile, toBase64 } from '../../../../helpers';
import ButtomCustom from '../../../customs/buttomCustom/ButtonCustom';
import CustomSelect from '../../../customs/customSelectLib/CustomSelectLib';
import './InfoBlockStyle.scss';
import { getListJobsApi, updateUserInfo } from '../../../../api';

interface IProps {
  infoUser: any;
  handleLoading: any;
  isLoading: boolean;
}

const InfoBlock = ({ infoUser, handleLoading, isLoading }: IProps) => {
  const { t } = useTranslation();
  const [isShowPassword, handlShowPassword] = useState(false);
  const [fullName, setFullName] = useState('');
  const [fileAvatar, setFileAvatar] = useState<any>();
  const [fileTypeAvatar, setFileTypeAvatar] = useState<any>();
  const [languageOptions, setLanguageOptions] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);

  const [errorApi, handleError] = useState('');

  const sendApi = async (apiObj: any) => {
    try {
      await updateUserInfo(apiObj).then(() => {
        handleLoading(false);
      });
    } catch (error: any) {
      handleLoading(false);
      handleError(error.response.data.error);
      if (error.response.status === 401) {
        window.location.replace('authorization');
        sessionStorage.setItem('last_url', location.pathname);
      }
    }
  };

  useEffect(() => {
    setFullName(`${infoUser.firstName} ${infoUser.lastName} ${infoUser.surname}`);
    setIsDataFetched(true);
    getListJobsApi().then(({ data }) => {
      setLanguageOptions(data);
    });
  }, [isDataFetched]);

  function getInput({ values, errors, touched, name, text, type = 'text', disable = false }: any) {
    return (
      <div className={!values[name] ? 'box_input' : 'box_input pd'}>
        <Field
          className={errors[name] && touched[name] ? 'error_input' : ''}
          name={name}
          type={type}
          placeholder={text}
          disabled={disable}
        />
        {values[name] && <span className="placeholder">{text}</span>}
        {errors[name] && touched[name] ? (
          <div className="error">
            <img src={iconsImg.Error} alt="error" />
            {errors[name]}
          </div>
        ) : null}
      </div>
    );
  }
  const handleFileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const files = Array.from(e.target.files);
    try {
      // const file = e.target.files[0];
      const image = await resizeFile(files[0]);
      setFileAvatar(image);
      setFileTypeAvatar(image);
      // console.log(image);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="info_block">
      <h3>{t('registration_worker.title')}</h3>
      <div className="form_box">
        {isDataFetched && (
          <Formik
            initialValues={infoUser}
            onSubmit={async (values: any) => {
              // if (values.documents.length > 5) {
              //   return handleError(`Максисмум 5 фалiв, у вас:${values.documents.length}`);
              // }
              const apiObj: any = {
                firstName: values.firstName,
                lastName: values.lastName,
                secondName: values.surname,
                phone: values.phone,
                jobAreas: [],
                // docs: [],
                avatarData: fileTypeAvatar,
              };
              values.fieldActivity.map((item: any) => {
                if (Number.isInteger(item)) apiObj.jobAreas.push(item);
                else {
                  apiObj.jobAreas.push(item.value);
                }
              });
              if (infoUser.password) apiObj.password = values.password;

              handleLoading(!isLoading);
              // avatar
              // fileTypeAvatar &&
              //   toBase64(fileTypeAvatar).then((resolveAvatar: any) => {
              //     apiObj.avatarData = resolveAvatar;
              //   });

              // const getFilesPromise = values.documents.map((itemFilse: File, index: number) => {
              //   const formData = new FormData();
              //   formData.append(`files[${index}]`, itemFilse);
              //   return new Promise((resolve, reject) => {
              //     preloadFilesToBackEnd(formData)
              //       .then(({ data }: any) => {
              //         console.log('data', data);
              //         apiObj.docs.push({ name: data[0].name, path: data[0].path });
              //         resolve(data);
              //       })
              //       .catch((error) => {
              //         // Reject the promise with the error
              //         reject(error);
              //       });
              //   });
              // });
              if (!apiObj.avatarData) {
                apiObj.avatarData = `${infoUser.avatar}`;
              }
              sendApi(apiObj);
            }}
          >
            {({ errors, touched, isValid, dirty, values, setFieldValue }) => (
              <Form>
                <div className="avatar_box">
                  <div className="avatar_info">
                    <img src={!fileAvatar ? `${infoUser.avatar}` : fileAvatar} alt="Avatar" />
                    <div className="info">
                      <p>{fullName}</p>
                      <span>{infoUser.email}</span>
                      <span>{infoUser.phone}</span>
                    </div>
                  </div>
                  <label id="input_file_box">
                    <input type="file" onChange={handleFileSelected} accept={'.png, .jpg, .jpeg'} />
                    {t('personal_account_page.info_block.update_photo')}
                  </label>
                </div>
                <div className="seppartion" />
                <div className="full_name">
                  {/* last_name  */}
                  {getInput({
                    values,
                    errors,
                    touched,
                    name: 'lastName',
                    text: t('placeholder.last_name'),
                  })}
                  {/* firs_name  */}
                  {getInput({
                    values,
                    errors,
                    touched,
                    name: 'firstName',
                    text: t('placeholder.firs_name'),
                  })}
                  {/* last_name  */}
                  {getInput({
                    values,
                    errors,
                    touched,
                    name: 'surname',
                    text: t('placeholder.surname'),
                  })}
                </div>
                {/* email  */}
                {getInput({
                  values,
                  errors,
                  touched,
                  name: 'email',
                  text: t('placeholder.email'),
                  disable: true,
                })}
                {/* select  */}
                <div
                  className={
                    values.fieldActivity.length === 0
                      ? 'box_input box_select'
                      : 'box_input  box_select pd'
                  }
                >
                  {values.fieldActivity.length !== 0 && (
                    <span className="name_input">{t('placeholder.our_activity')}</span>
                  )}
                  <Field
                    className="custom-select"
                    name="fieldActivity"
                    defaultValue={values.fieldActivity}
                    options={languageOptions}
                    component={CustomSelect}
                    placeholder={t('placeholder.our_activity')}
                    isMulti={true}
                  />
                  {errors.fieldActivity && touched.fieldActivity ? (
                    <div className="error">
                      {' '}
                      <img src={iconsImg.Error} alt="error" />
                      {!errors.phone}
                    </div>
                  ) : null}
                </div>
                {/* password */}
                <div className={!values.password ? 'box_input' : 'box_input pd'}>
                  <Field
                    name="password"
                    type={isShowPassword ? 'text' : 'password'}
                    className={errors.password && touched.password ? 'error_input' : ''}
                    placeholder={t('placeholder.password')}
                  />
                  <img
                    src={!isShowPassword ? iconsImg.ShowPassword : iconsImg.NotShowPassword}
                    alt="show_password"
                    onClick={() => handlShowPassword(!isShowPassword)}
                  />
                  {values.password && (
                    <span className="placeholder"> {t('placeholder.password')}</span>
                  )}
                  {errors.password && touched.password ? (
                    <div className="error">
                      {' '}
                      <img src={iconsImg.Error} alt="error" />
                      {!errors.password}
                    </div>
                  ) : null}
                </div>
                {/* <div className="drop">
                  <span className="name_input">
                    {t('personal_account_page.info_block.your_passport')}
                  </span>
                  <Field
                    name="documents"
                    component={DragDrop}
                    placeholder={t('placeholder.our_activity')}
                    isMulti={true}
                    values={infoUser.documents}
                  />
                  {isEmpty(values.avatar) && (
                    <div className="files">
                      <div className="box_name">
                        <img src={iconsImg.File} alt="File" />
                        <span>{values.avatar.name}</span>
                      </div>
                      <img
                        src={iconsImg.BasketSvg}
                        alt="Basket"
                        onClick={() => {
                          setFieldValue('avatar', {});
                        }}
                      />
                    </div>
                  )}
                </div> */}
                <div className="links">
                  <ButtomCustom
                    isValid={isValid || fileAvatar}
                    dirty={dirty || fileAvatar}
                    text={t('personal_account_page.info_block.save')}
                  />
                </div>
                {errorApi && <span className="error">{errorApi}</span>}
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default InfoBlock;
