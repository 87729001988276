import React, { useState, useEffect } from 'react';
import CustomModal from '../customModal/CustomModal';
import './ServiceModalStyle.scss';
import { useTranslation } from 'react-i18next';
import { iconsImg } from '../../../assets/imgs/icons';
import { getSectionsApi } from '../../../api';
import SelectPrice from './selectPrice/SelectPrice';
import Select from 'react-select';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { serviceUrl } from '../../../assets/enums';
import { useParams } from 'react-router-dom';
interface IProps {
  handleModal: any;
  isOpen: boolean;
  price: number;
  setOrder: any;
  isEdite?: boolean;
  title: string;
}

const ServiceModal = ({ handleModal, isOpen, price, setOrder, isEdite = false, title }: IProps) => {
  const [selectServiceItem, setServiceSelectItem] = useState('');
  const [sections, setSections] = useState();
  const [isFirstStep, handleStep] = useState(true);
  const [titleSecondStep, handleTitleSecondStep] = useState('');
  const { prevOrder } = useTypedSelector((state) => state.user.order);
  const { service } = useParams();
  serviceUrl;

  const { t } = useTranslation();

  useEffect(() => {
    getSectionsApi(serviceUrl[service as keyof typeof serviceUrl]).then(({ data }) => {
      setSections(
        data[0].children.map((item: any) => {
          return { value: item.id, label: item.title, ...item };
        })
      );
    });
  }, []);

  return (
    <CustomModal handleModal={handleModal} isOpen={isOpen}>
      <div className="modal_service">
        <div className="box_gazda_form">
          <div className="box_close">
            {isFirstStep ? (
              <p>{title}</p>
            ) : (
              <div
                className="back"
                onClick={() => {
                  handleStep(!isFirstStep);
                }}
              >
                <img src={iconsImg.ArrowLeftLong} alt="ArrowLeftLong" />
                <span className="none_up">Назад</span>
              </div>
            )}
            <img className="close" src={iconsImg.Close} alt="close" onClick={() => handleModal()} />
          </div>
          {isFirstStep && <p>{titleSecondStep}</p>}
          {!isFirstStep && selectServiceItem && (
            <SelectPrice
              idCatgory={selectServiceItem}
              orderGazda={{ category: '', service: '', section: selectServiceItem }}
              setOrder={setOrder}
              handleModal={handleModal}
            />
          )}
          {isFirstStep && (
            <div className="service">
              <Select
                className="custom-select"
                onChange={(e: any) => {
                  setServiceSelectItem(e.value);
                  return;
                }}
                options={sections}
                placeholder={'Послуга'}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#f1f1ee',
                    primary: '#b6b6a1',
                    primary50: '#f1f1ee',
                  },
                })}
                maxMenuHeight={150}
              />
              <div className="links">
                <button
                  disabled={!selectServiceItem}
                  onClick={() => {
                    handleStep(!isFirstStep);
                    // setGazdaPrevOrder({ isMaster: false, order: { category: selectServiceItem } });
                    // setOrder();
                    // handleModal();
                    // // setOrderGazda()
                    // window.scrollTo({
                    //   top: 0,
                    //   behavior: 'smooth',
                    // });
                  }}
                >
                  {t('modals.select_city.btn')}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </CustomModal>
  );
};

export default ServiceModal;
