import React, { useEffect, useState } from 'react';
import './FilterStyle.scss';
import { useTranslation } from 'react-i18next';
import { getCategory, getCities, getListOrdersAllApi } from '../../../api';
import { ICategory, ICities } from '../../../types';
import { iconsImg } from '../../../assets/imgs/icons';
import { setOrderFilter } from '../../../store/action-creators/user';
import { useAction } from '../../../hooks/useAction';
import { getAllStrFilter } from '../../../helpers';
import { useTypedSelector } from '../../../hooks/useTypedSelector';

const Filter = ({
  cityList,
  setListOrder,
  categoryList,
  setTotal,
  page,
  setPage,
  pageCount,
  setPageCount,
}: any) => {
  // const [categoryList, setCategoryList] = useState<ICategory[]>([]);
  // const [cityList, setCityList] = useState<ICities[]>([]);
  const [isCategory, handleCategory] = useState<boolean>(true);
  const [isCity, handleCity] = useState<boolean>(true);
  const [isPrice, handlePrice] = useState<boolean>(true);
  const [isRange, handleRange] = useState<boolean>(true);
  const [sendApiCategory, setSendApiCategory] = useState<any>([]);
  const [sendApiCity, setSendApiCity] = useState<any>([]);
  const [sendApiPrice, setSendApiPrice] = useState<any>([]);
  const [sendApiRange, setSendApiRange] = useState<any>([]);
  const [strCategory, setStrCategory] = useState<string>('');
  const [strApiCity, setStrApiCity] = useState<string>('');
  const [strApiPrice, setStrApiPrice] = useState<string>('');
  const [strApiRange, setStrApiRange] = useState<string>('');
  const { t } = useTranslation();
  const { setOrderFilter } = useAction();
  const { filter } = useTypedSelector((state) => state.user);

  const timeRange: ICities[] = [
    { label: t('orders_page.filter.time_range.all'), value: '' },
    { label: t('orders_page.filter.time_range.today'), value: 'today' },
    { label: t('orders_page.filter.time_range.coming_week'), value: 'week' },
    { label: t('orders_page.filter.time_range.month'), value: 'month' },
    { label: t('orders_page.filter.time_range.two_month'), value: 'two_month' },
  ];
  const priceArray: ICities[] = [
    { label: 'від 200 грн', value: '200' },
    { label: 'від 500 грн', value: '500' },
    { label: 'від 1000 грн', value: '1000' },
    { label: 'від 2000 грн', value: '2000' },
  ];

  const handleChange = (
    event: any,
    id: number | string,
    array: any[],
    setStr: any,
    key: string
  ) => {
    let str = '';
    if (event.target.checked) {
      array.push(id);
      array.forEach((item, index) => {
        index === 0 ? (str = item) : (str = `${str},${item}`);
      });
    } else {
      array.filter((item, idx) => {
        item === id && array.splice(idx, 1);
      });
      array.forEach((item, index) => {
        index === 0 ? (str = item) : (str = `${str},${item}`);
      });
    }
    setOrderFilter(key, str);
    const allStr = getAllStrFilter({ ...filter, [key]: str });
    getListOrdersAllApi(allStr).then(({ data }) => {
      setListOrder(data.orders);
      setTotal(data.totalItemCount);
      setPageCount(Math.ceil(data.totalItemCount / data.itemNumberPerPage));
      setPage(data.currentPageNumber);
    });
    setStr(str);
  };

  function getFilter(name: string, id: number | string, array: string[], setStr: any, key: string) {
    return (
      <label className="container_checkbox" key={id}>
        <input
          type="checkbox"
          onClick={(e) => {
            handleChange(e, id, array, setStr, key);
            e.stopPropagation();
          }}
        />
        {name}
        <span className="checkmark"></span>
      </label>
    );
  }

  function getBoxFilter(
    array: ICities[],
    func: any,
    isShow: boolean,
    title: string,
    arrayApi: any,
    setStr: any,
    key: string
  ) {
    return (
      <div className="box_filter">
        <div className="handle" onClick={func}>
          <span className="title">{title}</span>
          <img src={iconsImg.ArrowDownHeaderSvg} alt="arrow" className={!isShow ? 'rotate' : ''} />
        </div>
        {isShow &&
          array.map((item) => {
            return getFilter(item.label, item.value, arrayApi, setStr, key);
          })}
      </div>
    );
  }
  return (
    <div className="filter_container">
      <p className="title">{t('orders_page.filter.title')}</p>
      <div className="box_filter">
        <div className="handle" onClick={() => handleCategory(!isCategory)}>
          <span className="title">{t('orders_page.filter.sphere')}</span>
          <img
            src={iconsImg.ArrowDownHeaderSvg}
            alt="arrow"
            className={!isCategory ? 'rotate' : ''}
          />
        </div>
        {isCategory &&
          categoryList.map((item: any) => {
            return getFilter(item.title, item.id, sendApiCategory, setStrCategory, 'category');
          })}
      </div>
      <div className="sepparetion" />
      {getBoxFilter(
        timeRange,
        () => {
          handleRange(!isRange);
        },
        isRange,
        t('orders_page.filter.time_range.title'),
        sendApiRange,
        setStrApiRange,
        'diapason'
      )}
      <div className="sepparetion" />
      {getBoxFilter(
        cityList,
        () => {
          handleCity(!isCity);
        },
        isCity,
        t('orders_page.filter.city'),
        sendApiCity,
        setStrApiCity,
        'city'
      )}
      <div className="sepparetion" />
      {getBoxFilter(
        priceArray,
        () => {
          handlePrice(!isPrice);
        },
        isPrice,
        t('orders_page.filter.price'),
        sendApiPrice,
        setStrApiPrice,
        'price'
      )}
    </div>
  );
};
export default Filter;
