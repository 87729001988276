import React from 'react';
import { Link } from 'react-router-dom';
import './TermsStyle.scss';

const Terms = () => {
  return (
    <div className="terms_container">
      <p> 1. Загальні положення</p>
      <ul>
        <li>
          1.1. Правила користування сайтом Stephub.com.ua (далі - &quot;Правила&quot;) є
          невід&apos;ємною частиною Публічної оферти договору на надання послуг (далі -
          &quot;Оферта&quot;) розміщеної за адресою:…
        </li>
        <li>
          1.2. Правила складено Товариством з обмеженою відповідальністю ТОВ «СЛУЖБА ТЕХНІЧНОЇ
          ЕКСПЛУАТАЦІЇ ПРИМІЩЕНЬ» ( STEP ) зареєстрованим і діючим за законодавством України,
          ідентифікаційний код ….. (далі - &quot;Компанія&quot; / &quot;Адміністрація сайту&quot;).
        </li>
        <li>
          1.3. Компанія залишає за собою право вносити зміни в Правила. У разі внесення змін, такі
          зміни вступають в силу з моменту розміщення оновленої редакції Правил на Сайті.
        </li>
        <li>
          1.4. Всі терміни що використовуються в Правилах, якщо контекст не вимагає іншого,
          використовуються у значеннях, визначених в п.1.5. Оферти.
        </li>
      </ul>
      <p> 2. Порядок реєстрації Користувачів:</p>
      <p> 2.1. Реєстрація Виконавців (Фахівців):</p>
      <ul>
        <li>2.1.1. На Сайті передбачено два типи Фахівців:</li>
        <li>
          2.1.1.1. Приватний фахівець - фізична особа, яка не зареєстрована як суб&apos;єкт
          підприємницької діяльності і яка не виступає в ролі офіційного представника юридичної
          особи.{' '}
        </li>
        <li>
          2.1.1.1. Фахівець-компанія - фізична особа-підприємець або юридична особа (уповноважений
          представник). У разі реєстрації в якості Фахівця-компанії, такий Фахівець може пропонувати
          послуги кількох Фахівців, при цьому залишаючись відповідальним за їх дії перед Замовниками
          і Компанією в частині користування Сервісами. Також в якості Фахівця-компанії реєструються
          фізичні особи, що провадять незалежну професійну діяльність (за умови, що вони стали на
          спеціальний податковий облік).
        </li>
        <li>2.1.2. Реєстрація Фахівця здійснюється в кілька етапів:</li>
        <li>
          <Link to="/register-worker" target="_blank">
            2.1.2.1. Створення профілю Фахівця по посиланню.https://stephub.com.ua/register-worker
          </Link>
        </li>
        <li>
          2.1.2.2. Підтвердження номера телефону (українського оператора) і електронної пошти,
          зазначених при створенні профілю Виконавця.
        </li>
        <li>
          2.1.2.3. Вибір категорії Замовлень. Фахівцю необхідно вибрати категорії Замовлень в яких
          він зацікавлений надавати послуги / виконувати роботи. Вибір категорії Замовлень при
          реєстрації не є остаточним і список категорій можна змінити через особистий профіль. Який
          доступний за посиланням.
        </li>
        <li>2.1.2.4. Заповнення анкети.</li>
        <ul>
          <li>
            Для заповнення анкети Приватного фахівця Користувачеві необхідно вказати ім&apos;я та
            прізвище, завантажити фото профілю та фото з паспортом в форматі Селфі.
          </li>
          <li>
            Щоб заповнити анкету Фахівця-компанії Користувачеві необхідно вказати найменування
            суб&apos;єкта підприємницької діяльності, ПІБ відповідальної особи, завантажити фото
            логотипу або фото особи, яка буде безпосередньо виконувати Замовлення і завантажити фото
            / скан-копію виписки (свідоцтва реєстрації) з Єдиного реєстру юридичних осіб, фізичних
            осіб -підприємців та громадських формувань.
          </li>
          <li>
            Для Фахівця-компанії, який є фізичною особою, що провадить незалежну професійну
            діяльність необхідно надати інформацію про ПІБ, довідку про взяття на облік платника
            податків, відомості щодо якого не підлягають включенню до ЄДР та, у випадку наявності,
            документ (дозвіл, сертифікат тощо), що підтверджує право фізичної особи на провадження
            незалежної професійної діяльності.
          </li>
          <li>Адміністрація сайту здійснює модерацію анкети.</li>
          <li>Строк модерації - від 3 до 5 (п&apos;яти) робочих днів.</li>
          <li>
            Адміністрація сайту має право запитувати додаткову інформацію для уточнення інформації,
            зазначеної в анкеті
          </li>
        </ul>
        <li>2.1.3. Адміністрація сайту має право відхилити анкету Фахівця, якщо:</li>
        <li>2.1.3.1. Користувач вже має профіль на Сайті.</li>
        <li>
          2.1.3.2. Користувач не реагує на зауваження модератора щодо анкети. Користувач має
          можливість подати на модерацію анкету не більше 3 (трьох) разів. У разі відмови модератора
          прийняти анкету Користувач може звернутися в службу підтримки для отримання додаткової
          можливості заповнення анкети.
        </li>
        <li>2.1.3.3. Документ, наданий Фахівцем значиться викраденим або втраченим.</li>
        <li>
          2.1.3.4. У разі якщо суб&apos;єкт підприємницької діяльності згідно з даними з ЄДР
          ліквідовано або перебуває в стані ліквідації. Адміністрація сайту залишає за собою право
          відхилити анкету Фахівця без розголошення причин.
        </li>
        <li>
          2.1.4. Після виконання всіх дій, зазначених в п.2.1.2. Правил, Користувач отримує
          можливість доступу до розміщених на сайті Замовлень.
        </li>
      </ul>
      <p> 2.2. Реєстрація Замовника:</p>
      <ul>
        <li>
          2.2.1. Реєстрація Користувача в якості Замовника здійснюється на сторінці створення
          Замовлення.
        </li>
        <li>
          2.2.2. Користувачеві необхідно вказати ім`&apos;я, контактний номер телефону українського
          оператора та адресу електронної пошти. На вказаний номер телефону буде надіслано
          смс-повідомлення з кодом для аутентифікації Користувача.
        </li>
      </ul>
      <p> 3. Профіль Фахівця:</p>
      <ul>
        <li>3.1. Профіль Фахівця містить наступну інформацію:</li>
        <li>3.1.1. Контактні дані: номер телефону та електронної пошти.</li>
        <li>
          3.1.2. Основні дані профілю: ПІБ, місто, дата народження, стать, про себе. Перевірений
          фахівець не може змінювати інформацію про ПІБ, дату народження, стать. У разі необхідності
          зміни такої інформації Фахівець звертається в службу підтримки Сервісу із запитом на її
          зміну. Фахівцю в такому випадку необхідно здійснити доопрацювання анкети. Для
          підтвердження даних Адміністрація Сайту повторно проводить модерацію такої анкети.
        </li>
        <li>
          3.1.3. Категорії Замовлень. У профілі будуть вказані категорії Замовлень, які були обрані
          Фахівцем при реєстрації.
          <span>Фахівець самостійно може змінювати список категорій в своєму профілі.</span>
        </li>
        <li>
          3.1.4. Міста Замовлень. Фахівець має право реєстрації в одному місті, при цьому має право
          отримувати замовлення з обласних центрів.
        </li>
        <li>
          3.1.5. Гарантія. Фахівець може вказати, що він надає гарантію на результати своїх робіт.
          Ця можливість доступна виключно для ТОП-Фахівців.
        </li>
      </ul>
      <p> 4. Блокування та видалення профілю Користувача.</p>
      <ul>
        <li>4.1. Адміністрація сайту блокує профіль Користувача у випадку:</li>
        <li>
          4.1.1. Пропозиції Приватними фахівцями в Замовленні послуг / робіт третіх осіб або
          передоручення Замовлення.
        </li>
        <li>4.1.2. Ухилення Фахівцем від оплати за доступ до сервісу.</li>
        <li>
          <span>
            4.1.3. Розміщення Замовником контактних даних в описі Замовлення, чаті Замовлення або
            описі профілю Замовника.
          </span>
        </li>
        <li>4.1.4. Вказівка контактів третьої особи.</li>
        <li>4.1.5. Створення Замовником або участі Фахівця в наступних Замовленнях:</li>
        <li>
          4.1.5.1. Викривлення особистих показників за допомогою сторонніх сервісів (фіктивні
          відгуки та відвідувачі, створення ботів, СПАМ розсилка).
        </li>
        <li>4.1.5.2. порушують законодавство України</li>
        <li>4.1.5.3. з пропозицією інтимних послуг.</li>
        <li>4.1.5.4. з метою купівлі / продажу / оренди акаунтів / товарів / послуг.</li>
        <li>
          4.1.5.5. з метою замовлення послуги з набору тексту і страховим внеском з боку фахівця.
        </li>
        <li>4.1.6. Порушення Користувачем цих Правил</li>
        <li>4.1.7. Некоректного спілкування Користувача зі службою підтримки Сервісу.</li>
        <li>
          4.1.8. У разі наявності загрози для працездатності Сервісу і / або безпеки Користувачів.
        </li>
        <li>4.1.9. Виконання Замовлення в стані алкогольного або наркотичного сп&apos;яніння.</li>
        <li>4.1.10. Розголошення контактів другої сторони Замовлення без згоди такої сторони.</li>
        <li>4.2. Профіль Користувача блокується автоматично в разі:</li>
        <li>
          4.2.1. Автоматичного видалення 2 (двох) Замовлень за скаргами Фахівців протягом 72 годин.
          Перша блокування в такому трапляється триває 3 (три) дні. При повторному автоматичному
          вилучень профіль Користувача блокується назавжди.
        </li>
        <li>
          4.3. Видалення профілю Користувача здійснюється безпосередньо самим Користувачем за
          допомогою натискання посилання &quot;Видалити профіль&quot;. Підтвердження видалення
          профілю здійснюється за допомогою введення пароля від профілю Користувача. Після видалення
          профілю Адміністрація сайту блокує доступ до даних цього профілю протягом терміну
          зберігання даних відповідно до Політики конфіденційності.
        </li>
        <li>4.4. Видалення профілю Користувача недоступно в разі:</li>
        <li>4.4.1. Наявності заборгованості на балансі Фахівця.</li>
        <li>4.4.2. Наявності відкритого Замовлення.</li>
      </ul>
      <p> 5.Оплата на Сервісі</p>
      <ul>
        <li>5.1. За допомогою сервісу LiqPay.</li>
        <li>
          5.2. Ціна доступу до Сервісу визначається у вигляді процентної ставки залежно від
          категорії Замовлення.
        </li>
        <li>
          5.2.2. Розрахунок ціни доступу до Сервісу для окремого Замовлення здійснюється шляхом
          множення вартості ціни Замовлення на процентну ставку, передбачену для такої категорії
          послуг (робіт).
        </li>
        <li>5.2.3. Базова процентна ставка - 10% (десять відсотків) від суми замовлення.</li>
        <li>
          5.2.4 Повернення внесеного платежу здійснюється на реквізити, які Виконавець/Замовник
          вказує в формі заяви на повернення на адресу електронної пошти stephub.ua@gmail.com. Таку
          заяву буде підтверджено і оброблено Компанією тільки після введення Виконавцем/Замовником
          унікально згенерованого коду, спрямованого йому в SMS-повідомленні на номер, який вказав
          Користувач в Особистому кабінеті. Введення отриманого за допомогою SMS-повідомлення
          унікально згенерованого коду є електронним підписом Користувача.
        </li>
        <li>
          5.2.5 У разі неможливості надання з вини Компанії з яких-небудь причин Сервісу
          Користувачеві, Компанія зобов&apos;язується повернути Користувачу оплачену їм суму
          Гарантійного платежу на підставі відповідної заяви.
        </li>
        <li>
          5.2.6 У разі позитивного вирішення питання про повернення Користувачеві грошових коштів
          Компанія здійснює таке повернення Користувачеві протягом 10 (десяти) календарних днів з
          дати заповнення повністю всієї необхідної інформації в заяві на повернення.
        </li>
      </ul>
      <p> 6. Розміщення Замовлення на сервісі:</p>
      <ul>
        <li>
          6.1. Замовнику для розміщення Замовлення на Сервісі необхідно вказати наступний дані:
          категорію Замовлення, назва Замовлення (короткий опис), докладний опис Замовлення, термін
          виконання Замовлення, місто та адресу виконання Замовлення, спосіб оплати, контактні дані
          (якщо Замовник не авторизований на Сервісі).
        </li>
        <li>
          6.2. При створенні Замовлення Сервіс автоматично розраховує ціну Замовлення на підставі
          даних, що були введені Замовником. Остаточна ціна{' '}
          <span>
            Замовлення може бути скоригована в разі збільшення або зменшення обсягу робіт при
            закритті такого Замовлення.
          </span>
        </li>
        <li>
          <span>
            6.2.1. Розрахунок ціни за розміщення Замовлення здійснюється шляхом множення вартості
            ціни Замовлення на процентну ставку, передбачену для такої категорії послуг (робіт)
            п.6.2.2.
          </span>
        </li>
        <li>
          <span>
            6.2.2. Базова процентна ставка - 10% (десять відсотків), за винятком випадків від суми
            замовлення. Оплата відбувається згідно п 5.1.
          </span>
        </li>
        <li>
          6.3. У разі якщо ваше місто відсутнє у списку міст, то в такому випадку ви можете
          розміщувати Замовлення тільки для послуг (робіт), що надаються дистанційно.
        </li>
        <li>
          6.4. На сервісі передбачена оплата готівкою. Оплата готівкою здійснюється безпосередньо
          між Замовником та Фахівцем виходячи з їх домовленостей.
        </li>
        <li>6.5. Вибір Фахівця для виконання Замовлення відбувається автоматично Сервісом.</li>
      </ul>
      <p> 7. Робота з Замовленнями</p>
      <ul>
        <li>
          7.1. Для того, щоб Фахівцю підписатися на Замовлення, йому необхідно додати пропозицію на
          його виконання безпосередньо в стрічці Замовлень або перейти на сторінку Замовлення і
          додати пропозицію в відповідному блоці. На Замовлення можливо підписатися, коли воно
          знаходиться в статусі &quot;Очікує виконання&quot;.
        </li>
        <li>7.2. Кількість Замовлень, які може брати Фахівець не обмежена.</li>
        <li>
          7.3. У разі необхідності продовження терміну виконання Замовлення, Користувач має
          можливість продовжити термін виконання Замовлення. Користувач може продовжувати Замовлення
          не більше 1 (одного) разу.
        </li>
      </ul>
      <p> 8. Програма захисту Користувачів:</p>
      <ul>
        <li>
          8.1. На Сервісі передбачена можливість для Користувачів, які постраждали від несумлінних
          дій другої сторони Замовлення претендувати на виплату до 15% від загальної суми
          замовлення. Після розгляду звернення Замовника Адміністрація сервісу приймає рішення про
          можливість надання компенсації.
        </li>
        <li>
          8.2. Для участі в Програмі захисту Замовників повинні бути дотримані наступні умови:
        </li>
        <li>
          8.2.1. Угода між Замовником та Фахівцем повинна бути укладена за допомогою Замовлення.
        </li>
        <li>8.2.2. До виконання Замовлення приступив Фахівець, який взяв в роботу Замовлення.</li>
        <li>
          8.2.3. Замовнику необхідно надати фото або скановану копію заяви в правоохоронні органи у
          зв&apos;язку з діями Фахівця по Замовленню. У заяві необхідно вказати дані про Фахівця,
          які доступні на Сервісі і суму заподіяної шкоди. Також необхідно надати
          талон-повідомлення, що підтверджує прийняття заяви або витяг з Єдиного реєстру досудових
          розслідувань.
        </li>
        <li>
          8.2.4. Замовник не отримував компенсацію від сервісу протягом 90 днів від дати звернення
          за Програмою захисту Замовників.
        </li>
        <li>8.3. Замовник не може бути учасником Програми захисту Замовників якщо:</li>
        <li>
          8.3.1. Замовник уклав угоду безпосередньо з Фахівцем, а не через Сервіс за допомогою
          Замовлення.
        </li>
        <li>8.3.2. Замовлення було створено з порушенням положень Оферти або цих Правил.</li>
        <li>8.3.3. Місце виконання Замовлення знаходиться за межами України.</li>
        <li>
          8.3.4. В результаті дій Фахівців відсутні прямі матеріальні збитки та / або Замовник
          відмовляється надати підтвердження завданих збитків.
        </li>
        <li>
          8.3.5. Замовник звернувся по Програмі захисту Замовників після 14 (чотирнадцяти)
          календарних днів від дати закриття Замовлення.
        </li>
        <li>
          8.3.6. Замовник звернувся щодо Замовлення в статусі &quot;Скасовано&quot;, &quot;Вилучено
          модератором&quot; або &quot;Прострочено&quot;
        </li>
        <li>8.4. Для участі в Програмі захисту Фахівців повинні бути дотримані наступні умови:</li>
        <li>8.4.1. Фахівець був обраний для виконання Замовлення.</li>
        <li>
          8.4.2. Фахівець зв&apos;язався з Замовником і підтвердив готовність виконати Замовлення.
        </li>
        <li>
          8.4.3. Фахівець підписався на Замовлення не пізніше 14 днів від дати звернення за
          Програмою захисту Фахівців.
        </li>
        <li>
          8.4.4. Замовник не виходить на зв&apos;язок зі Фахівцем більше 3 днів, в тому числі не
          відповідає по телефону, на СМС-повідомлення, електронні листи і на повідомлення в
          месенджерах.
        </li>
        <li>
          8.4.5. Фахівець виконав Замовлення в повному обсязі відповідно до завдання, зазначеним в
          такому Замовленні й з боку Замовника не було зауважень.
        </li>
        <li>
          8.4.6. Фахівець може надати фото або скановану копію заяви, що підтверджує звернення в
          правоохоронні органи України в разі наявності в діях Замовників ознак шахрайських дій і
          талон-повідомлення з підтвердженням прийняття такої заяви або витяг з Єдиного реєстру
          досудових розслідувань. У заяві повинні бути вказані ПІБ Фахівця і суму заявленого
          матеріального збитку
        </li>
        <li>
          8.4.7. Фахівець може надати підтвердження виконання Замовлення згідно із завданням,
          вказаною в ньому.
        </li>
        <li>
          8.4.8. Фахівець використовував особисті кошти для виконання Замовлення і у нього є
          підтверджуючі документи (наприклад, чек, квитанція та інше).
        </li>
      </ul>
      <p>9. Порядок публікації Відгуків. Редагування і видалення Відгуків.</p>
      <ul>
        <li>9.1. Відгуки на Сервісі публікуються:</li>
        <li>
          9.1.1. У разі закриття Замовлення Замовником - після розміщення Відгуку Фахівцем або після
          закінчення 14 днів від дати розміщення Відгуку
        </li>
        <li>
          9.2. Користувач втрачає можливість розмістити Відгук по закінченню 7 днів після розміщення
          Відгуку другою стороною за окремим Замовленням.
        </li>
        <li>
          9.3. У разі якщо Користувач не згоден з Відгуком за фактом закриття Замовлення, то йому
          необхідно звернутися до іншої сторони угоди із запитом про редагування Відгуку або пошуку
          іншого компромісного рішення.
        </li>
        <li>
          9.4. Користувач може заявити в службу підтримки про фіктивність залишеного йому Відгуку. В
          такому випадку Користувачеві необхідно надати інформацію, що підтверджує фіктивність
          Відгуку і на підставі таких даних Адміністрація сайту може видалити такий Відгук.
        </li>
        <li>
          9.5. Якщо Замовлення знаходиться в статусі &quot;Закрито автоматично / замовником&quot;,
          Замовник може змінити Відгук тільки за допомогою служби підтримки Сервісу. У такому
          випадку Замовнику необхідно відправити на адресу{' '}
          <span className="email">Stephub.ua@gmail.com </span>та нову редакцію Відгуку та оцінки.
          Інформація буде відредагована виключно за згодою Адміністрації сайту.
        </li>
      </ul>
      <p>10. Різне</p>
      <ul>
        <li>
          10.1. Ці Правила застосовуються відповідно до законодавства України. Будь-які спори щодо
          реалізації цих Правил будуть вирішуватися відповідним судом, в порядку, визначеному
          чинними законодавством України.
        </li>
        <li>
          10.2. Незважаючи на будь-які положення з вищевикладеного, нічого в цих Правилах не може
          використовуватися для тлумачення положень, зазначених в Політиці конфіденційності.
        </li>
        <li>
          10.3. Листування слід здійснювати на <span className="email">Stephub.ua@gmail.com</span>
        </li>
        <li>
          10.4. Ви погоджуєтесь негайно повідомляти про будь-які порушення авторських прав щодо
          матеріалів Сайту. Про виникнення претензій з Вашого боку щодо порушення авторських прав
          стосовно матеріалів, які містяться на Сайті, повідомляйте на на email.
        </li>
        <li>
          10.5. Матеріали можуть містити посилання, пов&apos;язані з іншими World Wide Web
          Інтернет-сайтами, ресурсами та рекламодавцями. Так як Ми не несемо відповідальності за
          доступність цих зовнішніх ресурсів або їх зміст, Ви повинні направляти будь-яке
          занепокоєння щодо будь-якого зовнішнього посилання адміністратору такого веб-сайту.
        </li>
      </ul>
    </div>
  );
};

export default Terms;
