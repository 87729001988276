import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { iconsImg } from '../../../assets/imgs/icons';
import './FirstStepStyle.scss';

import { useTranslation } from 'react-i18next';
import InputCustom from '../../customs/inputCustom/InputCustom';
import DragDrop from '../../customs/dragDrop/DragDrop';
import { Field, Form, Formik } from 'formik';
import { IOrderGazda } from '../../../types';
import GazdaModal from '../../modals/gazdaModal/GazdaModal';
import { units } from '../../../assets/enums';

interface IProps {
  step: number;
  hours: number;
  setHours: any;
  defaultHorus: number;
  setOrderGazda: any;
  orderGazda: IOrderGazda;
  price: number;
  nextStep: any;
  prevOrder: any;
}

const FirstStep = ({
  hours,
  setHours,
  defaultHorus,
  setOrderGazda,
  orderGazda,
  price,
  nextStep,
  prevOrder,
}: IProps) => {
  const [allPrice, setAllPrice] = useState(0);
  const [regionPrice, setRegionPrice] = useState(40);
  const [isOpen, handelOpen] = useState(false);
  const [selectItem, setSelectItem] = useState(hours);
  const [isOpenComment, handleOpenComment] = useState(false);
  const [isDisabled, setDisabled] = useState(true);
  const [isModal, setModal] = useState(false);
  const [isOrder, setOrder] = useState(false);
  const [docs, setDocs] = useState([]);
  const { t } = useTranslation();
  const commentEllemRef: any = useRef(null);
  const [errorApi, handleError] = useState('');

  useEffect(() => {
    let allPriceCalc = price;
    prevOrder.order.secondStepClient?.forEach((element: any) => {
      allPriceCalc += element.id.price * element.id.value;
    });
    setAllPrice(allPriceCalc);
  }, [prevOrder.order.secondStepClient, price]);
  const handlePrice = (item: any) => {
    setSelectItem(item);
    getDisabled(item);
  };
  const clearComent = () => {
    commentEllemRef.current.value = '';
  };
  const getDisabled = (value: number) => {
    if (value === defaultHorus || value < 2 || !value) {
      return setDisabled(true);
    } else return setDisabled(false);
  };
  return (
    <div className="first_step_order_gazda_container">
      <p>{t('gazda_page.info_component.order.details_order')}</p>
      <div className="orders_gazda">
        <div className="orders">
          <div
            className="handle"
            onClick={() => {
              // handelOpen(!isOpen);
              setModal(!isModal);
            }}
          >
            {isOpenComment}
            <span>{t('gazda')}</span>
            <button>Редагувати</button>
          </div>
          {isOpen && (
            <div className="details">
              <div className="sepparation" />
              <span>{t('gazda_page.info_component.order.details_order')}</span>
              <div className="wrapper_info">
                <div className="info">
                  <span>{t('modals.gazda.sub_title')}</span>
                  <label className="price_count">
                    {allPrice} {t('gazda_page.main_component.price.sub_2')}
                  </label>
                </div>
                <InputCustom selectLabel={t('modals.gazda.hours')} setItem={handlePrice} />
              </div>
              <div className="box_button">
                <button
                  disabled={isDisabled}
                  onClick={() => {
                    setHours(selectItem);
                  }}
                >
                  {t('save')}
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="price">
          <div className="sb all">
            <span>{t('all')}</span>
            <div>
              {allPrice}
              {t('gazda_page.info_component.price.sub_title.sub_2')}
            </div>
          </div>
          <div className="sb">
            <span> {t('order_price')} </span>
            <>
              {price} {t('gazda_page.info_component.price.sub_title.sub_2')}
            </>
          </div>
          {prevOrder.order.secondStepClient?.map((item: any, ind: number) => {
            return (
              <div className="sb" key={`secondStepClient_${ind}`}>
                <span>{item.id.title}</span>
                <div className="box_price">
                  <div className="count_unit">
                    <span>{item.id.value}</span>
                    {units[item.id.unit as keyof typeof units]}
                  </div>
                  {item.id.price * item.id.value}{' '}
                  {t('gazda_page.info_component.price.sub_title.sub_2')}{' '}
                </div>
              </div>
            );
          })}
          <div className="sepor" />
          <div className="sb">
            <div className="box">
              <span>{t('gazda_page.info_component.order.region')}</span>
              <img src={iconsImg.Info} alt="Info" />
            </div>
            <>40{t('gazda_page.info_component.price.sub_title.sub_2')}/км</>
          </div>
          <div className="sepor" />
          {!isOpenComment ? (
            <div className="comment" onClick={() => handleOpenComment(!isOpenComment)}>
              <img src={iconsImg.Plus} alt="Plus" />
              <span>{t('gazda_page.info_component.order.add_comment')}</span>
            </div>
          ) : (
            <div className="box_comment_input">
              <label>{t('gazda_page.info_component.order.comment')}</label>
              <textarea
                ref={commentEllemRef}
                id="commentArea"
                className="select"
                cols={2}
              ></textarea>
              <div className="remove_comment">
                <label
                  onClick={() => {
                    clearComent();
                  }}
                >
                  {t('gazda_page.info_component.order.remove_comment')}
                </label>
              </div>
            </div>
          )}
          <div className="form_box">
            <Formik
              initialValues={{
                docs,
              }}
              onSubmit={(values: any) => {
                if (values.docs.length > 5) {
                  return handleError(`Максисмум 5 фалiв, у вас:${values.docs.length}`);
                }

                setOrderGazda({
                  ...orderGazda,
                  docs: values.docs,
                  hours: selectItem,
                  comment: commentEllemRef.current ? commentEllemRef.current.value : '',
                });
                nextStep();
              }}
            >
              {({ isValid, dirty, values }) => (
                <Form>
                  <div className={values.docs.length > 0 ? 'drop mb' : 'drop'}>
                    <span className="name_input">{t('placeholder.documents')}</span>
                    <Field
                      name="docs"
                      component={DragDrop}
                      placeholder={t('placeholder.our_activity')}
                      isMulti={true}
                      isFinish={true}
                    />
                  </div>
                  <button className="next" type="submit">
                    {t('forgot.next')}
                  </button>
                  {errorApi && <span className="error">{errorApi}</span>}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <GazdaModal
        handleModal={() => setModal(!isModal)}
        isOpen={isModal}
        price={price}
        setOrder={() => setOrder(!isOrder)}
        isEdite={true}
      />
    </div>
  );
};
export default FirstStep;
