import React, { useState, useCallback, useEffect } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import './ViewImgStyle.scss';
import { iconsImg } from '../../../assets/imgs/icons';
interface IViewImg {
  imgList: string[];
}

export const ViewImg = ({ imgList }: IViewImg) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  return (
    <div className="view_img_container">
      {isViewerOpen && (
        <div
          className="close"
          onClick={() => {
            closeImageViewer();
          }}
        >
          <img src={iconsImg.CloseGold} alt="Close" />
        </div>
      )}
      {imgList.map((src: string, index: number) => (
        <img
          className="mini_img"
          src={src}
          onClick={() => openImageViewer(index)}
          key={index}
          alt=""
        />
      ))}

      {isViewerOpen && (
        <ImageViewer
          src={imgList}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          closeOnClickOutside={true}
        />
      )}
    </div>
  );
};

export default ViewImg;
