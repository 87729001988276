import React from 'react';
import './ChooseUsStyle.scss';
import { useTranslation } from 'react-i18next';
import { ourAdvantagesImgs } from '../../../assets/imgs/icons/our_advantages';

interface IElement {
  img: string;
  title: string;
  subTitle: string;
}

const ChooseUs = () => {
  const { t } = useTranslation();

  const elements: IElement[] = [
    {
      img: ourAdvantagesImgs.People,
      title: t('gazda_page.choose_us.elements.retelny.title'),
      subTitle: t('gazda_page.choose_us.elements.retelny.sub_title'),
    },
    {
      img: ourAdvantagesImgs.Checked,
      title: t('gazda_page.choose_us.elements.rating.title'),
      subTitle: t('gazda_page.choose_us.elements.rating.sub_title'),
    },
    {
      img: ourAdvantagesImgs.Places,
      title: t('gazda_page.choose_us.elements.orientation.title'),
      subTitle: t('gazda_page.choose_us.elements.orientation.sub_title'),
    },
    {
      img: ourAdvantagesImgs.Files,
      title: t('gazda_page.choose_us.elements.guaranteed.title'),
      subTitle: t('gazda_page.choose_us.elements.guaranteed.sub_title'),
    },
  ];

  function getElement({ title, subTitle, img }: IElement, index: number) {
    return (
      <div key={`elment_${index}`} className="wrapper_element">
        <img src={img} alt="icon" />
        <div className="box_text">
          <p className="title">{title}</p>
          <p className="sub_title">{subTitle}</p>
        </div>
      </div>
    );
  }
  return (
    <div className="choose_us">
      <label className="name_page">{t('gazda_page.choose_us.name_page')}</label>
      <div className="box_info">
        <p>{t('gazda_page.choose_us.title')}</p>
      </div>
      <div className="flex_end">
        <div className="wrapper_info">
          {elements.map((item, index) => {
            return getElement(item, index);
          })}
        </div>
      </div>
    </div>
  );
};

export default ChooseUs;
