import React, { useState, useEffect } from 'react';
import './HeaderMobileStyle.scss';
import { iconsImg } from '../../../assets/imgs/icons';
import Logo from '../../../assets/imgs/logo.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SelectCityModal from '../../modals/selectCity/SelectCityModal';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import CustomModal from '../../modals/customModal/CustomModal';

interface iSelectHeader {
  link: string;
  name: string;
}

interface IDataSelect {
  title: string;
  isShow: boolean;
  handleShow: any;
  links: iSelectHeader[];
}

interface IProps {
  changeLanguage: any;
}

const HeaderMobile = ({ changeLanguage }: IProps) => {
  const [isEngineering, setEngineering] = useState(false);
  const [isCleaning, setCleaning] = useState(false);
  const [isLandscaping, setLandscaping] = useState(false);
  const [isHouses, setHouses] = useState(false);
  const [isOpenMenu, setOpenMenu] = useState(false);
  const [isLangModal, setLangModal] = useState(false);
  const [lang, setLang] = useState<string>('');
  const [isSelectModalCity, setSelectModalCity] = useState(false);
  const { t } = useTranslation();
  // redux
  const { user, error, loading } = useTypedSelector((state) => state.user);

  const arrayDataSelects: IDataSelect[] = [
    {
      isShow: isEngineering,
      handleShow: setEngineering,
      title: t('engineering'),
      links: [
        { link: 'engineering/plumbing', name: t('header.plumbing_equipment') },
        { link: 'engineering/electricity', name: t('header.electricity') },
        { link: 'engineering/repair', name: t('header.repair') },
        { link: 'engineering/furniture', name: t('engineering_page.furniture') },
        { link: 'engineering/ventilation', name: t('engineering_page.ventilation') },
      ],
    },
    {
      isShow: isCleaning,
      handleShow: setCleaning,
      title: t('cleaning'),
      links: [
        { link: 'cleaning/apartments', name: t('header.apartments') },
        { link: 'cleaning/offices', name: t('header.offices') },
        { link: 'cleaning/after_repair', name: t('header.after_repair') },
      ],
    },
    {
      isShow: isLandscaping,
      handleShow: setLandscaping,
      title: t('landscaping'),
      links: [
        { link: 'landscaping/landscaping', name: t('header.landscaping') },
        { link: 'landscaping/lawn', name: t('header.lawn') },
        { link: 'landscaping/flowers', name: t('header.flowers') },
        { link: 'landscaping/facade', name: t('header.facade') },
      ],
    },
    {
      isShow: isHouses,
      handleShow: setHouses,
      title: t('houses'),
      links: [
        { link: 'houses/rooms', name: t('header.rooms') },
        { link: 'houses/territory', name: t('header.territory') },
      ],
    },
  ];
  function closeAll(title: string) {
    switch (title) {
      case 'Інженерія': {
        setCleaning(false);
        setLandscaping(false);
        setHouses(false);
        break;
      }
      case 'Клінінг': {
        setEngineering(false);
        setLandscaping(false);
        setHouses(false);
        break;
      }
      case 'Озеленення': {
        setEngineering(false);
        setCleaning(false);
        setHouses(false);
        break;
      }
      case 'Будинки': {
        setEngineering(false);
        setCleaning(false);
        setLandscaping(false);
        break;
      }
    }
  }
  function getHandleLinks(isShow: boolean, onClick: any, links: iSelectHeader[], title: string) {
    return (
      <div
        onClick={() => {
          closeAll(title);
          onClick(!isShow);
        }}
        key={title}
      >
        <div className={isShow ? 'title selectColor' : 'title'}>
          <p>{title}</p>{' '}
          <img
            className={isShow ? 'rotate' : ''}
            src={isShow ? iconsImg.ArrowUpSvg : iconsImg.ArrowDownHeaderSvg}
            alt="arrow"
          />
        </div>
        {isShow && (
          <div className="subLinks">
            {links.map((item) => {
              return (
                <Link to={item.link} key={`${item.link}`} onClick={() => setOpenMenu(!isOpenMenu)}>
                  {item.name}
                </Link>
              );
            })}
          </div>
        )}
      </div>
    );
  }
  useEffect(() => {
    setLang(localStorage.getItem('lang')!);
  });

  function getLang() {
    return (
      <>
        <div
          className="subSetting"
          onClick={() => {
            setLangModal(!isLangModal);
          }}
        >
          <div className="wrapper_icon">
            <img src={!isLangModal ? iconsImg.Lang : iconsImg.LangSelect} alt="Lang" />
            <label style={{ color: !isLangModal ? '' : '#9F9978' }}>{t('header.lang_name')}</label>
          </div>
          <img
            className={isLangModal ? 'rotate' : ''}
            src={!isLangModal ? iconsImg.ArrowDownHeaderSvg : iconsImg.ArrowUpSvg}
            alt="arrow"
          />
        </div>
        {isLangModal && (
          <>
            <div
              className="subSetting"
              onClick={() => {
                setLang('ua');
                localStorage.setItem('lang', 'ua');
                changeLanguage('ua');
              }}
            >
              <div className="wrapper_icon">
                <img
                  style={{ visibility: lang === 'ua' ? 'visible' : 'hidden' }}
                  src={iconsImg.Check}
                  alt="Check"
                />
                <label style={{ color: lang === 'en' ? '#333333' : '#9F9978' }}>
                  {t('header.lang.ua')}
                </label>
              </div>
            </div>
            {/* <div
              className="subSetting"
              onClick={() => {
                setLang('en');
                localStorage.setItem('lang', 'en');
                changeLanguage('en');
              }}
            >
              <div className="wrapper_icon">
                <img
                  style={{ visibility: lang === 'en' ? 'visible' : 'hidden' }}
                  src={iconsImg.Check}
                  alt="Check"
                />
                <label style={{ color: lang !== 'en' ? '#333333' : '#9F9978' }}>
                  {t('header.lang.en')}
                </label>
              </div>
            </div> */}
          </>
        )}
      </>
    );
  }

  return (
    <>
      <div className="headerMobile">
        <Link to="/" id="logo" onClick={() => setOpenMenu(false)}>
          <img src={Logo} alt="logo" />
        </Link>
        <div className="wrapperLinks">
          {/* {arrayDataSelects.map((item) => {
          return getHandleLinks(item.isShow, item.handleShow, item.links, item.title);
        })} */}
          {/* <Link className="monoLink" to="/helper">
          ГАЗДА
        </Link>
        <Link className="monoLink" to="/actions">
          Акції
        </Link> */}
        </div>
        <div className={!isOpenMenu ? 'infoHeader' : 'isActive infoHeader'}>
          {!isOpenMenu ? (
            <>
              {/* <img src={iconsImg.Search} alt="Search" />
              <img src={iconsImg.Basket} alt="Basket" /> */}
              <img src={iconsImg.Menu} alt="menu" onClick={() => setOpenMenu(!isOpenMenu)} />
            </>
          ) : (
            <img
              className="close"
              src={iconsImg.Close}
              alt="close"
              onClick={() => setOpenMenu(!isOpenMenu)}
            />
          )}
          {isOpenMenu && (
            <CustomModal isOpen={isOpenMenu}>
              <div className="infoMobile">
                <div className="subLinks gazda_link">
                  <Link className="monoLink" to="/gazda" onClick={() => setOpenMenu(!isOpenMenu)}>
                    {t('gazda')}
                  </Link>
                </div>
                {arrayDataSelects.map((item) => {
                  return getHandleLinks(item.isShow, item.handleShow, item.links, item.title);
                })}
                <div className="subLinks">
                  {/* <Link className="monoLink" to="/actions" onClick={() => setOpenMenu(!isOpenMenu)}>
                    Акції
                  </Link> */}
                </div>
                <div className="settingHeader">
                  <div className="subSetting">
                    {!user.login ? (
                      <Link to="/authorization" onClick={() => setOpenMenu(!isOpenMenu)}>
                        <div className="wrapper_icon">
                          <img src={iconsImg.User} alt="User" />
                          <label>Увійти</label>
                        </div>
                      </Link>
                    ) : (
                      <Link to={'/personal-account'} onClick={() => setOpenMenu(!isOpenMenu)}>
                        <div className="wrapper_icon">
                          <img src={iconsImg.User} alt="sign" />
                          <label>{t('personal_account_page.title')}</label>
                        </div>
                      </Link>
                    )}
                  </div>
                  {getLang()}
                  {/* <div className="subSetting">
                    <div className="wrapper_icon">
                      <img src={iconsImg.Location} alt="Location" />
                      <label
                        onClick={() => {
                          setSelectModalCity(!isSelectModalCity);
                        }}
                      >
                        {user.city ? user.city : 'test'}
                      </label>
                    </div>
                  </div> */}
                </div>
              </div>
            </CustomModal>
          )}
        </div>
      </div>
      <SelectCityModal
        isOpen={isSelectModalCity}
        handleModal={() => setSelectModalCity(!isSelectModalCity)}
      />
    </>
  );
};

export default HeaderMobile;
