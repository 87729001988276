import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { iconsImg } from '../../assets/imgs/icons';
import './BreadСrumbsStyle.scss';

interface IProps {
  arrayLinks: { name: string; link: string }[];
  isBlack?: boolean;
}

const BreadСrumbs = ({ arrayLinks, isBlack = false }: IProps) => {
  return (
    <div className="bread_crumbs">
      {arrayLinks.map((item, index) => {
        return (
          <Link to={item.link} key={`${index}_bread`} className="links">
            <span>{item.name}</span>{' '}
            <img
              className={index === arrayLinks.length - 1 ? 'dn' : ''}
              src={isBlack ? iconsImg.BreadArrowBlack : iconsImg.BreadArrow}
              alt=""
            />
          </Link>
        );
      })}
    </div>
  );
};
export default BreadСrumbs;
