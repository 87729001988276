import React from 'react';
import './StepComfortStyle.scss';
import { useTranslation } from 'react-i18next';
import { aboutPageImgs } from '../../../assets/imgs/about_page';

const StepComfort = () => {
  const { t } = useTranslation();

  return (
    <div className="step_compfort_container">
      <div className="name">
        <p>
          {t('about_page.step_comfort.title_sub1')}
          <span>{t('about_page.step_comfort.title_sub2')}</span>
        </p>
      </div>

      <div className="box_item top">
        <div className="box_text_right"> {t('about_page.step_comfort.people')}</div>
        <div className="box_img_right">
          <img
            src={window.innerWidth > 768 ? aboutPageImgs.People : aboutPageImgs.PeopleMobile}
            alt="people"
          />
        </div>
      </div>
      <div className="box_item bottom">
        <div className="box_img_left">
          <img
            src={window.innerWidth > 768 ? aboutPageImgs.Home : aboutPageImgs.HomeMobile}
            alt="home"
          />
        </div>
        <div className="box_text_left"> {t('about_page.step_comfort.home')}</div>
      </div>
    </div>
  );
};

export default StepComfort;
