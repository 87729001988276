import { FieldProps } from 'formik';
import React, { useRef } from 'react';
import Select, { InputActionMeta } from 'react-select';
// eslint-disable-next-line import/no-named-as-default-member
import { OptionsType, ValueType } from 'react-select/lib/types';

interface Option {
  value: string;
  title: string;
}

interface CustomSelectProps extends FieldProps {
  options: OptionsType<Option>;
  isMulti?: boolean;
  className?: string;
  placeholder?: string;
  defaultValue?: any;
  isDisabled?: boolean;
  onChangeForm?: any;
  ref?: any;
}

export const CustomSelect = ({
  className,
  placeholder,
  field,
  form,
  options,
  isMulti = false,
  defaultValue,
  isDisabled,
  onChangeForm,
}: CustomSelectProps) => {
  const ref = useRef(null);
  const onChange = (option: ValueType<Option | Option[]>) => {
    debugger;
    form.setFieldValue(
      field.name,
      isMulti ? (option as Option[]).map((item: Option) => item.value) : (option as Option).value
    );
    onChangeForm && onChangeForm(form.setFieldValue, option, form.values, ref);
  };
  const onInputChange = (inputValue: string, { action, prevInputValue }: InputActionMeta) => {
    // debugger;
    if (action === 'input-change') return inputValue;
    return prevInputValue;
  };

  return (
    <Select
      className={className}
      name={field.name}
      onChange={onChange}
      // onInputChange={onInputChange}
      placeholder={placeholder}
      options={options}
      isMulti={isMulti}
      defaultValue={defaultValue}
      isDisabled={isDisabled}
      ref={ref}
      maxMenuHeight={150}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: '#f1f1ee',
          primary: '#b6b6a1',
          primary50: '#f1f1ee',
        },
      })}
    />
  );
};

export default CustomSelect;
