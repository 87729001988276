import React from 'react';
import './ModalStyle.scss';
import { Modal } from '@material-ui/core';

const CustomModal = ({ children, handleModal, isOpen, style }: any) => {
  return (
    <Modal
      style={style}
      open={isOpen}
      onClose={handleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
