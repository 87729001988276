import React, { useState, useEffect } from 'react';
import CustomModal from '../customModal/CustomModal';
import './GazdaModalStyle.scss';
import { useTranslation } from 'react-i18next';
import { iconsImg } from '../../../assets/imgs/icons';
import InputCustom from '../../customs/inputCustom/InputCustom';
import { useAction } from '../../../hooks/useAction';
import CallMaster from './callMaster/CallMaster';
import SubscriberService from './subscriberService/SubscriberService';
import { IOrderGazdaLast } from '../../../types';
import { getCategory } from '../../../api';
import SelectPrice from './selectPrice/SelectPrice';
import Select from 'react-select';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import CallMasterEdite from './callMasterEdite/CallMasterEdite';
interface IProps {
  handleModal: any;
  isOpen: boolean;
  price: number;
  setOrder: any;
  isEdite?: boolean;
}

const defaultDataOrder: IOrderGazdaLast = {
  category: '',
  service: '',
  section: '',
};

const GazdaModal = ({ handleModal, isOpen, price, setOrder, isEdite = false }: IProps) => {
  const [selectServiceItem, setServiceSelectItem] = useState('');
  const [allCategoies, setCategoies] = useState();
  const [orderGazda, setOrderGazda] = useState<IOrderGazdaLast>();
  const [allPrice, setAllPrice] = useState(price);
  const [choiceRegistration, setChoiceRegistration] = useState('master');
  const [isFirstStep, handleStep] = useState(true);
  const [titleSecondStep, handleTitleSecondStep] = useState('');
  const { prevOrder } = useTypedSelector((state) => state.user.order);
  const { setGazdaPrevOrder } = useAction();

  const { t } = useTranslation();
  const handlePrice = (item: any) => {
    // setSelectItem(item);
    setAllPrice(item * price);
  };
  function getSelectRegistration() {
    return (
      <div className="select">
        <div
          className="box_select"
          onClick={() => {
            setChoiceRegistration('master');
          }}
        >
          <img
            src={choiceRegistration === 'master' ? iconsImg.RadioSelect : iconsImg.Radio}
            alt="Radio"
          />
          <span>Виклик майстра</span>
        </div>

        <div
          className="box_select"
          onClick={() => {
            setChoiceRegistration('service');
          }}
        >
          <img
            src={choiceRegistration === 'service' ? iconsImg.RadioSelect : iconsImg.Radio}
            alt="Radio"
          />
          <span>Абонентське обслуговування</span>
        </div>
      </div>
    );
  }
  useEffect(() => {
    getCategory().then(({ data }) => {
      setCategoies(
        data.map((item: any) => {
          return { value: item.id, label: item.title, ...item };
        })
      );
      setOrderGazda({
        category: prevOrder.order.category,
        section: prevOrder.order.section,
        service: prevOrder.order.service,
      });
    });
  }, []);

  return (
    <CustomModal handleModal={handleModal} isOpen={isOpen}>
      <div className="modal_gazda">
        <div className="box_gazda_form">
          <div className="box_close">
            {isFirstStep ? (
              <p>{t('gazda')}</p>
            ) : (
              <div
                className="back"
                onClick={() => {
                  handleStep(!isFirstStep);
                }}
              >
                <img src={iconsImg.ArrowLeftLong} alt="ArrowLeftLong" />
                <span className="none_up">Назад</span>
              </div>
            )}
            <img className="close" src={iconsImg.Close} alt="close" onClick={() => handleModal()} />
          </div>
          {isFirstStep ? (
            <>
              <div className="info">
                <span>{t('order_price')}</span>
                <span className="price">
                  {price} {t('gazda_page.main_component.price.sub_2')}
                </span>
              </div>
              {getSelectRegistration()}
            </>
          ) : (
            <p>{titleSecondStep}</p>
          )}
          {choiceRegistration === 'master' && isFirstStep && !isEdite && orderGazda && (
            <CallMaster
              orderGazda={orderGazda}
              allCategoies={allCategoies}
              setOrderGazda={setOrderGazda}
              handleStep={() => {
                handleStep(!isFirstStep);
              }}
              handleTitleSecondStep={handleTitleSecondStep}
            />
          )}
          {choiceRegistration === 'master' && !isFirstStep && orderGazda && (
            <SelectPrice
              idCatgory={orderGazda.section}
              orderGazda={orderGazda}
              setOrder={setOrder}
              handleModal={handleModal}
            />
          )}

          {choiceRegistration === 'master' && isFirstStep && isEdite && orderGazda && (
            <CallMasterEdite
              orderGazda={orderGazda}
              allCategoies={allCategoies}
              setOrderGazda={setOrderGazda}
              handleStep={() => {
                handleStep(!isFirstStep);
              }}
              handleTitleSecondStep={handleTitleSecondStep}
            />
          )}

          {choiceRegistration !== 'master' && (
            <div className="service">
              <Select
                className="custom-select"
                onChange={(e: any) => {
                  setServiceSelectItem(e.value);
                  return;
                }}
                options={allCategoies}
                placeholder={'Послуга'}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#f1f1ee',
                    primary: '#b6b6a1',
                    primary50: '#f1f1ee',
                  },
                })}
                maxMenuHeight={150}
              />
              <div className="links">
                <button
                  disabled={!selectServiceItem}
                  onClick={() => {
                    setGazdaPrevOrder({ isMaster: false, order: { category: selectServiceItem } });
                    setOrder();
                    handleModal();
                    // setOrderGazda()
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    });
                  }}
                >
                  {t('modals.select_city.btn')}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </CustomModal>
  );
};

export default GazdaModal;
