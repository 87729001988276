import Main from './main.jpg';
import Electricity from './electricity.jpg';
import PlumbingEquipment from './plumbing_equipment.jpg';
import InstallationDoors from './installation_doors.jpg';
import InstallationFurniture from './installation_furniture.jpg';
import InstallationCornices from './installation_cornices.jpg';
import ConnectingAppliances from './connecting_appliances.jpg';
import CosmeticPremises from './cosmetic_premises.jpg';

export const gazdaImgs = {
  Main,
  Electricity,
  PlumbingEquipment,
  InstallationCornices,
  InstallationDoors,
  InstallationFurniture,
  ConnectingAppliances,
  CosmeticPremises,
};
