import { Field, Formik, Form } from 'formik';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { iconsImg } from '../../../../assets/imgs/icons';
import { isEmpty, toBase64 } from '../../../../helpers';
import ButtomCustom from '../../../customs/buttomCustom/ButtonCustom';
import CustomSelect from '../../../customs/customSelectLib/CustomSelectLib';
import DragDrop from '../../../customs/dragDrop/DragDrop';
import './InfoBlockStyle.scss';
import { getListJobsApi, updateUserClientInfo, updateUserInfo } from '../../../../api';

interface IProps {
  infoUser: any;
  handleLoading: any;
  isLoading: boolean;
}
const InfoBlockClient = ({ infoUser }: IProps) => {
  const { t } = useTranslation();
  const [isShowPassword, handlShowPassword] = useState(false);
  const [fullName, setFullName] = useState('');
  const [fileAvatar, setFileAvatar] = useState<any>();
  const [fileTypeAvatar, setFileTypeAvatar] = useState<any>();
  const [languageOptions, setLanguageOptions] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);
  useEffect(() => {
    setFullName(`${infoUser.firstName} ${infoUser.lastName} ${infoUser.surname}`);
    setIsDataFetched(true);
    getListJobsApi().then(({ data }) => {
      setLanguageOptions(data);
    });
  }, [isDataFetched]);

  function getInput({ values, errors, touched, name, text, type = 'text', disable = false }: any) {
    // if (!values) {
    //   return;
    // }
    // if (name = 'email') {
    //   // debugger;
    //   console.log('values', values);
    // }
    return (
      <div className={!values[name] ? 'box_input' : 'box_input pd'}>
        <Field
          className={errors[name] && touched[name] ? 'error_input' : ''}
          name={name}
          type={type}
          placeholder={text}
          disabled={disable}
        />
        {values[name] && <span className="placeholder">{text}</span>}
        {errors[name] && touched[name] ? (
          <div className="error">
            <img src={iconsImg.Error} alt="error" />
            {errors[name]}
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div className="info_block">
      <h3>{t('registration_worker.title')}</h3>
      <div className="form_box">
        {isDataFetched && (
          <Formik
            initialValues={infoUser}
            // validationSchema={UpdateSchema}
            onSubmit={async (values: any) => {
              const apiObj: any = {
                firstName: values.firstName,
                lastName: values.lastName,
                secondName: values.surname,
              };
              if (values.password) apiObj.plainPassword = values.password;
              updateUserClientInfo(apiObj);
            }}
          >
            {({ errors, touched, isValid, dirty, values, setFieldValue }) => (
              <Form>
                <div className="full_name">
                  {/* last_name  */}
                  {getInput({
                    values,
                    errors,
                    touched,
                    name: 'lastName',
                    text: t('placeholder.last_name'),
                  })}
                  {/* firs_name  */}
                  {getInput({
                    values,
                    errors,
                    touched,
                    name: 'firstName',
                    text: t('placeholder.firs_name'),
                  })}
                  {/* last_name  */}
                  {getInput({
                    values,
                    errors,
                    touched,
                    name: 'surname',
                    text: t('placeholder.surname'),
                  })}
                </div>
                {/* email  */}
                {getInput({
                  values,
                  errors,
                  touched,
                  name: 'email',
                  text: t('placeholder.email'),
                  disable: true,
                })}
                {/* password */}
                <div className={!values.password ? 'box_input' : 'box_input pd'}>
                  <Field
                    name="password"
                    type={isShowPassword ? 'text' : 'password'}
                    className={errors.password && touched.password ? 'error_input' : ''}
                    placeholder={t('placeholder.password')}
                  />
                  <img
                    src={!isShowPassword ? iconsImg.ShowPassword : iconsImg.NotShowPassword}
                    alt="show_password"
                    onClick={() => handlShowPassword(!isShowPassword)}
                  />
                  {values.password && (
                    <span className="placeholder"> {t('placeholder.password')}</span>
                  )}
                  {errors.password && touched.password ? (
                    <div className="error">
                      {' '}
                      <img src={iconsImg.Error} alt="error" />
                      {!errors.password}
                    </div>
                  ) : null}
                </div>
                <div className="links">
                  <ButtomCustom
                    isValid={isValid}
                    dirty={dirty}
                    text={t('personal_account_page.info_block.save')}
                  />
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default InfoBlockClient;
