import React from 'react';
import { Link } from 'react-router-dom';
import BreadСrumbs from '../../components/breadСrumbs/BreadСrumbs';
import { useTranslation } from 'react-i18next';
import { Ilinks } from '../../types';
import { iconsImg } from '../../assets/imgs/icons';
import { housesImgs } from '../../assets/imgs/houses';
import { landscapingImgs } from '../../assets/imgs/landscaping';

const Landscaping = () => {
  const { t } = useTranslation();

  const arrayLinks = [
    { name: t('main'), link: '/' },
    { name: t('landscaping'), link: '/landscaping' },
  ];

  const links: Ilinks[] = [
    {
      url: 'landscaping',
      img: landscapingImgs.Landscaping,
      text: t('header.landscaping'),
    },
    {
      url: 'lawn',
      img: landscapingImgs.Lawn,
      text: t('header.lawn'),
    },
    {
      url: 'flowers',
      img: landscapingImgs.Flowers,
      text: t('header.flowers'),
    },
    {
      url: 'facade',
      img: landscapingImgs.Facade,
      text: t('header.facade'),
    },
  ];

  function getLink({ img, text, url }: Ilinks, index: number) {
    return (
      <Link key={`${url}_${index}`} to={url} className="wrapper_link">
        <img className="main_img" src={img} alt={url} />
        <img id="arrowLink" src={iconsImg.Link} alt="link" />
        <p>{text}</p>
      </Link>
    );
  }
  return (
    <div className="engineering_container pd_l_r">
      <BreadСrumbs arrayLinks={arrayLinks} isBlack={true} />
      <p className="title">{t('landscaping')}</p>
      <div className="box_links">
        {links.map((item, index) => {
          return getLink(item, index);
        })}
      </div>
    </div>
  );
};

export default Landscaping;
