import React, { useState } from 'react';
import './SecondStepStyle.scss';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import { iconsImg } from '../../../assets/imgs/icons';
import ButtomCustom from '../../customs/buttomCustom/ButtonCustom';
import DragDrop from '../../customs/dragDrop/DragDrop';
import { RegistrationWorkerSchemaSecondStep } from '../../../assets/Schemes';
import { IRegistartion, IWorker } from '../../../types';
import { preloadFilesToBackEnd, registationWorkerApi } from '../../../api';
import { resizeFile, toBase64 } from '../../../helpers';
import axios from 'axios';
import Load from '../../modals/load/Load';

interface IProps {
  files: any;
  setRegistration: any;
  setStep: any;
  registratin: IRegistartion;
}

const SecondStep = ({ files, registratin, setStep, setRegistration }: IProps) => {
  const { t } = useTranslation();
  const [errorApi, handleError] = useState('');
  const [isLoading, handleLoading] = useState<boolean>(false);
  const sendApi = async (apiObj: any) => {
    try {
      await registationWorkerApi(apiObj).then(() => {
        handleLoading(!isLoading);
        setStep('3');
      });
    } catch (error: any) {
      handleLoading(!isLoading);
      handleError(error.response.data.error);
    }
  };
  return (
    <div className="second_step">
      <p>{t('registration_worker.second_step.title')}</p>
      <span>{t('registration_worker.second_step.sub_title')}</span>
      <ul>
        <li>{t('registration_worker.second_step.li.item_1')}</li>
        <li>{t('registration_worker.second_step.li.item_2')}</li>
        <li>{t('registration_worker.second_step.li.item_3')}</li>
      </ul>
      <div className="form_box">
        <Formik
          initialValues={{
            files,
          }}
          validationSchema={RegistrationWorkerSchemaSecondStep}
          onSubmit={async (values: any) => {
            if (values.files.length > 5) {
              return handleError(`Максисмум 5 фалiв, у вас:${values.files.length}`);
            }

            const apiObj: IWorker = {
              email: registratin.email,
              firstName: registratin.firstName,
              lastName: registratin.lastName,
              secondName: registratin.surname,
              password: registratin.password,
              phone: registratin.phone,
              jobAreas: registratin.fieldActivity,
              docs: [],
              city: registratin.city!,
              avatarData: '',
            };
            handleLoading(!isLoading);
            // avatar
            try {
              apiObj.avatarData = await resizeFile(registratin.avatar[0]);
            } catch (err) {
              console.log(err);
            }
            const getFilesPromise = values.files.map((itemFilse: File, index: number) => {
              const formData = new FormData();
              formData.append(`files[${index}]`, itemFilse);
              return new Promise((resolve, reject) => {
                preloadFilesToBackEnd(formData)
                  .then(({ data }: any) => {
                    apiObj.docs.push({ name: data[0].name, path: data[0].path });
                    resolve(data);
                  })
                  .catch((error) => {
                    // Reject the promise with the error
                    reject(error);
                  });
              });
            });

            Promise.all(getFilesPromise).then((data) => {
              sendApi(apiObj);
            });
            // .catch((error) => {
            //   // Handle the error if any of the promises rejected
            //   debugger;
            //   console.error(error);
            // });
            // function readFileAndEncodeToBase64(file: File) {
            //   return new Promise(function (resolve, reject) {
            //     const reader = new FileReader();

            //     reader.onload = function (event: any) {
            //       if (event.target.result) {
            //         const base64Data = toBase64(file); // Кодирование в Base64
            //         resolve(base64Data);
            //       } else {
            //         reject(new Error('Ошибка чтения файла или кодирования в Base64'));
            //       }
            //     };

            //     reader.onerror = function () {
            //       reject(new Error('Ошибка чтения файла'));
            //     };

            //     reader.readAsBinaryString(file); // Чтение файла в бинарном формате
            //   });
            // }
            // try {
            //   const promises = registratin.avatar.map(readFileAndEncodeToBase64);
            //   const results = await Promise.all(promises);
            //   apiObj.avatarData = results[0];
            // } catch (error) {
            //   console.error('Произошла ошибка:', error);
            // }
            // try {
            //   const promises = values.files.map(readFileAndEncodeToBase64);
            //   Promise.all(promises)
            //     .then((data) => {
            //       console.log('datadatadata', data);
            //       data.forEach((item: any, index: number) => {
            //         values.files.forEach((items: any, ind: number) => {
            //           if (index === ind) {
            //             apiObj.docs.push({ originalName: items.name, file: item });
            //           }
            //         });
            //       });
            //     })
            //     .then(async () => {
            //       // debugger;
            //       try {
            //         await registationWorkerApi(apiObj).then(() => {
            //           // setStep('3');
            //         });
            //       } catch (error: any) {
            //         // debugger;
            //         // handleError('error');
            //         handleError(error.response.data.error);
            //       }
            //       // sendApi(apiObj);
            //     });

            // results.forEach((item: any, index: number) => {
            //   values.files.forEach((items: any, ind: number) => {
            //     if (index === ind) {
            //       apiObj.docs.push({ originalName: items.name, file: item });
            //       if (values.files.length - 1 === index) {
            //         console.log(apiObj);
            //         sendApi(apiObj);
            //       }
            //     }
            //   });
            // });
            // } catch (error) {
            //   console.error('Произошла ошибка:', error);
            // }
            // new Promise(function (resolve, reject) {
            //   toBase64(registratin.avatar[0]).then((resolveAvatar: any) => {
            //     apiObj.avatarData = resolveAvatar;
            //     values.files.map((item: File, index: number) => {
            //       toBase64(item).then((resolveDoc: any) => {
            //         apiObj.docs.push({ originalName: item.name, file: resolveDoc });
            //         if (values.files.length - 1 === index) {
            //           sendApi(apiObj);
            //         }
            //       });
            //     });
            //   });
            // });
          }}
        >
          {({ isValid, dirty, values }) => (
            <Form>
              {/* upload Files  */}
              <div className={values.files.length > 0 ? 'drop mb' : 'drop'}>
                <span className="name_input">{t('placeholder.documents')}</span>
                <Field
                  name="files"
                  component={DragDrop}
                  placeholder={t('placeholder.our_activity')}
                  isMulti={true}
                />
              </div>
              <div className="links">
                <div className="box_back" onClick={() => setStep('1')}>
                  <img src={iconsImg.ArrowLeftBlack} alt="arrow" /> {t('back')}
                </div>
                <ButtomCustom
                  isValid={isValid}
                  dirty={dirty}
                  text={t('authorization.registration')}
                />
              </div>
              {errorApi && <span className="error">{errorApi}</span>}
            </Form>
          )}
        </Formik>
      </div>
      <Load isLoading={isLoading} />
    </div>
  );
};
export default SecondStep;
